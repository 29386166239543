import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';

import close from "../../../../resources/admin/icon/close.svg";
import SafetyTestAddModal from "../../../../modals/admin/SafetyTestAddModal";
import {BusinessType} from "../../../../models/BusinessType";
import {api} from "../../../../api/api";
import {Link} from "react-router-dom";
import {TYPE_EMPLOYEES, TYPE_SALES} from "../../../../constants/ticket";

class Screen extends React.PureComponent {

	isLockedFetch = false;

	state = {
		isFetching: true,

		business_type: null,
		name: '',
		ticket_type: null,

		items: [],
		is_visible_add_modal: null,
		selected_item2: null,
	};

	componentDidMount() {
		this.fetch();
	}

	get uuid() {
		return this.props.match?.params?.uuid;
	}

	fetch() {
		BusinessType.fetch(this.uuid, {join_safety_tests: true}).then((business_type) => {
			console.log(business_type);
			this.setState({
				business_type,

				name: business_type?.name,
				ticket_type: business_type?.ticket_type
			});
		});
	};

	showAddModal = () => {
		this.setState({is_visible_add_modal: true});
	};

	onClickEdit = (item) => {
		this.setState({selected_item2: item});
	};

	onCloseModal = () => {
		this.setState({is_visible_add_modal: null});
		this.setState({selected_item2: null});
	};

	onClickSave = () => {
		if (this.isLockedSave) return;
		this.isLockedSave = true;

		const release = (state, callback) => {
			this.setState({...state}, () => {
				this.isLockedSave = false;
			});
		};

		const {business_type, name, ticket_type} = this.state;
		if (!business_type) return;
		if (!name) return release(null, () => alert('업종명을 입력하세요'));
		if (!ticket_type) return release(null, () => alert('이용권 산정기준을 선택하세요'));

		api.put(`/v1/admin/business-type/${business_type.uuid}`, {name, ticket_type}).then((r) => {
			if (!r.ok) return release(null, () => alert('저장실패'));
			this.setState((p) => ({
				business_type: new BusinessType({ ...p.business_type, name, ticket_type }),
			}), () => {
				this.isLockedSave = false;
				alert('저장완료');
			});
		});
	};

	onChangeTicketType = (e) => {
		this.setState({ticket_type: e.target.value});
	};

	onClickDelete = () => {
		if (window.confirm('정말 삭제하시겠습니까?')) {
			api.delete(`/v1/admin/business-type/${this.uuid}`).then((r) => {
				if (!r.ok) return alert('삭제실패');
				alert('삭제성공');
				this.props.history.push('/admin/business-type');
			}).catch(e => {
				alert('삭제실패');
			});
		}
	};

	renderTicketType = () => {
		const ticket_types = [TYPE_EMPLOYEES, TYPE_SALES];
		const business_type = this.state.business_type;
		return ticket_types.map((type, i) => {
			const selected = this.state.ticket_type ? (this.state.ticket_type === type) : business_type?.ticket_type === type;
			return (
				<option selected={selected} value={type}>{BusinessType.ticketTypeAlias(type)}</option>
			);
		})
	};

	render() {
		const business_type = this.state.business_type;
		const visible_submit_button = (business_type?.ticket_type !== this.state.ticket_type) || (business_type?.name !== this.state.name);

		return (
			<ContainerComponent>
				<div id="ad-contents" className="ad-detail">
					<h1 className="contents-title"><span>{business_type?.name}</span></h1>
					<div className="contents-in">

						<div className="ad-com-info">
							<div className="ad-infoTit">
								<h2 style={{width:"90%;", float:"left", lineHeight:"2"}}>기본정보</h2>
							</div>
							<ul>
								<li>
									<div style={{width:'140px'}}>업종명</div>
									<input type="text" id="business-name" placeholder="기본 정보 노출" style={{width:'calc(100% - 160px)'}} value={this.state.name} onChange={e => this.setState({name: e.currentTarget.value})}/>
								</li>
							</ul>
							<div className="ad-com-set" style={{marginTop:'10px'}}>
								<div>
									<div style={{width:'140px'}}>이용권 산정기준</div>
									<select className="select" onChange={this.onChangeTicketType}>
										{this.renderTicketType()}
									</select>
									{visible_submit_button &&
									<button className="d-search" onClick={this.onClickSave}>저장</button>}
								</div>
							</div>
						</div>

						<span className="e-line"></span>
						{/*<button className="view-btn" onClick={this.onClickDelete}>삭제</button>*/}
						<div className="btn-wrap">
							<button className="view-btn second" onClick={this.onClickDelete}>삭제</button>
							<Link to={'/admin/business-type'}><button className="right view-btn qna-btn second">목록</button></Link>
						</div>


						{/* 회원권 등록 모달 */}
						<div id="basic-modal" style={{display: 'none'}}>
							<div className="modal info-modal">
								<div id="modal-close"><img src={close} width="16px;" alt="닫기" /></div>
								<h1>회원권 등록</h1>

								<div className="modal-in">
									<ul>
										<li>
											<div>회원권명</div>
											<input type="text" id="ad-ID" placeholder="회원권 이름을 입력해주세요" />
										</li>
										<li>
											<div>회원권 금액</div>
											<input type="text" id="ad-ID" placeholder="회원권 금액을 입력해주세요" />
										</li>
									</ul>
								</div>

								<div className="check">
									<input type="checkbox" id="a1" name="전체동의"/>
									<label htmlFor="a1"><span style={{top:"-2px"}}>회원가입 시 기본지급 여부 체크박스</span></label>
								</div>

								<div className="btn-wrap">
									<button id="modal-btn-cancel" className="modal-btn btn-cancel">취소</button>
									<button id="modal-btn-ok" className="modal-btn btn-edit">등록</button>
								</div>
							</div>
						</div>


						{/* 안전검사 대상품 기능 자체를 제외하면서 주석 처리 */}
						{/*{this.state.business_type &&*/}
						{/*<SafetyTestsComponent businessType={this.state.business_type} onClickAdd={this.showAddModal}/>}*/}

						{/* 안전검사 대상품 목록 추가 modal 노출  */}
						{this.state.is_visible_add_modal &&
						<SafetyTestAddModal businessType={this.state.business_type} onClose={this.onCloseModal}/>}

						{/* 안전검사 대상품 목록 수정 modal 노출  */}
						{/*{this.state.selected_item2 &&*/}
						{/*<BusinessDetailEditModal company={this.state.selected_item2} onClose={this.onCloseModal}/>}*/}
					</div>
				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;
