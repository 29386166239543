import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import { HashLink as Link } from 'react-router-hash-link';

/** img */
import home from "../../resources/homepage/Home.svg";
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {
	render() {
		return (
			<div id="wrap" className="sv-long-in">

					{/* header */}
					<HeaderComponent/>

					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title"><strong>안전관리계획서</strong></h1>
							<div className="location sv-location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<Link to={'/service'}><strong>제공서비스</strong></Link>
								<Link to={'/service?tab=disaster'}><strong>건설재해예방 전문기관</strong></Link>
								<strong>안전관리계획서</strong>
							</div>
						</div>
					</div>
					<div id="sub-container">
						<div className="section-in sv-in">
							<div className="sv-nav">
							</div>
							<div className="sv-contents" style={{width:'100%'}}>
								{/* 안전관리계획서 */}
								<div id="sv1">
									<h6>1. 안전관리계획서</h6>
									<p>
										건설기술진흥법 제 62조 및 시행령 제98조, 동법 시행규칙 제58조에 의한 안전관리계획서 작성은 토목 및 건축을 포함한 건설공사의 시공시 체계적이고 효율적인 건설안전관리를 정착시키고 부실공사를 방지하여 공사 목적물의 품직 확보가 이루어질 수 있도록 하기 위한 것으로서, 공사 착공전에 계획서를 작성하여 안전관리업무를 원활하게 수행토록 함을 목적으로 하고 있다.
									</p>
									<p>
										<strong>대상공사</strong>
										• 시설물 안전관리에 관한 특별법에 의한 1,2종 시설물의 건설공사<br />
										• 지하 10미터 이상을 굴착하는 건설공사. 이 경우 굴착 깊이 산정시 집수정. 엘리베이터 피트 및 정화조 등의 굴착 부분은 제외하고 토지에 고저차가 있는 경우 깊이 산정은 「건축법 시행령」제 119조 제 2항의 규정에 의한다.<br />
										• 폭발물을 사용하는 건설공사로서 20미터 안에 시설물이 있거나 100미터 안에 양육하는 가축이 있어서 당해 건설공사로 인한 영향을 받을 것이 예상되는 건설공사<br />
										• 10층 이상 16층 미만인 건축물의 건설공사 또는 10층 이상인 건축물의 리모델링 또는 해체공사<br />
										• 「건설기계관리법」제 3조에 따라 등록된 건설기계 중 항타 및 항발기가 사용되는 건설공사<br />
										• 제 1호 내지 제 4호 외의 건설공사로서 발주자가 특히 안전관리가 필요하다고 인정하는 건설공사
									</p>
									<p>
										<strong>주요내용</strong>
										안전관리계획서는 총괄안전관리계획서와 공정별 안전관리계획서를 분리, 작성하며, 건설기술관리법 제 2조 제 1항 제 8호에서 규정하는 토목 및 건축분야의 고급 기술자 이상 또는 동등한 자가 작성한다. 내용에 포함 항목은 총괄(①공사개요 ~ ⑧비상 시 긴급조치계획), 공종별(해당공종에 한해서 작성)
									</p>
									<p>
										<strong>확인신청</strong>
										안전관리계획서를 작성하여 공사감독자 또는 감리원에게 제출, 확인을 신청한다. 단, 민간 건설공사의 경우에는 민간건설업자가 당해 건설공사를 인·허가 또는 승인한 행정기관장에게 확인을 신청한다. 확인 신청 기한은 확인 검토 기간을 고려하여 실착공 전일까지 제출부수는 2부를 원칙으로 한다.
									</p>
									<p>
										<strong>확인절차</strong>
										• 안전관리계획서를 접수하여 접수 후 15일 이내 확인업무를 수행한다.<br />
										• 단, 민간건설공사의 경우 당해 공사를 인,허가 또는 승인한 행정기관의 장이 확인 업무를 수행한다.
									</p>
									<p>
										<strong>비용산출</strong>
										• 발주자는 건설공사의 계약을 체결하는 때에는 안전관리에 필요한 비용을 건설교통부령이 정하는 바에 따라 공사금액에 계상하여야 한다.<br />
										• 안전관리계획 작성비용은 작성대상과 공사 난이도 등을 감안하여 엔지니어링 기술진흥법 제 10조의 규정에 의한 안전점검의 대가기준을 적용(작성자 및 검토자 인건비에 의해 책정)
									</p>
								</div>

								<div className="sv-subBox">
									현업에서의 풍부한 경험 및 노하우를 바탕으로 산업재해로 인한 근로자의 귀중한 생명 보호와
									공공기관 안전담당자, 기업 내 사업주, 안전관리자의 안전관리 근로생애까지 관리해드리겠습니다.
								</div>

								<div className="sv-goback">
									<Link to={'/service?tab=disaster'}>
										<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
									</Link>
								</div>
							</div>

						</div>
					</div>

					{/* footer */}
					<FooterComponent/>

				</div>
		)
	}
}

export default Screen;
