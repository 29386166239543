import React from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import {AnimatedSwitch} from 'react-router-transition';
import AdminSwitch from "./AdminSwitch";
import PermissionRoute from "./PermissionRoute";
import IndexScreen from "../screens/IndexScreen";
import CompanyIntroduceScreen from "../screens/CompanyIntroduceScreen";
import NotFoundScreen from '../screens/NotFoundScreen';
import ServiceIntroduceSmartSafetyScreen from "../screens/ServiceIntroduceSmartSafetyScreen";
import CustomerScreen from "../screens/CustomerScreen";
import NoticeListScreen from "../screens/NoticeListScreen";
import QnAScreen from "../screens/QnAScreen";
import HistoryManagementSystemScreen from "../screens/HistoryManagementSystemScreen";
import ServiceSwitch from "./ServiceSwitch";
import ScrollToTop from "../components/ScrollToTop";
import NoticeScreen from "../screens/NoticeScreen";
import PolicyScreen from "../screens/policy/PolicyScreen";
import Analytics from 'react-router-ga';

class Router extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<ScrollToTop/>

				<Analytics id={'G-1WFD3FZRBV'}>
					<AnimatedSwitch
						atEnter={{ opacity: 0 }}
						atLeave={{ opacity: 0 }}
						atActive={{ opacity: 1 }}
						className="switch-wrapper">
						<PermissionRoute title={'스마트안전'} path={'/'} exact component={IndexScreen} authorized unauthorized/>
						<PermissionRoute title={'스마트안전'} path={'/company'} component={CompanyIntroduceScreen} authorized unauthorized/>
						<PermissionRoute title={'스마트안전'} path={'/service/smart-safety'} component={ServiceIntroduceSmartSafetyScreen} authorized unauthorized/>
						<PermissionRoute title={'스마트안전'} path={'/customers'} component={CustomerScreen} authorized unauthorized/>

						<PermissionRoute title={'스마트안전'} path={'/notice/:uuid'} component={NoticeScreen} authorized unauthorized/>
						<PermissionRoute title={'스마트안전'} path={'/notice'} component={NoticeListScreen} authorized unauthorized/>

						<PermissionRoute title={'스마트안전'} path={'/qna'} component={QnAScreen} authorized unauthorized/>
						<PermissionRoute title={'스마트안전'} path={'/history-mgmt-system'} component={HistoryManagementSystemScreen} authorized unauthorized/>
						<PermissionRoute title={'스마트안전'} path={'/policy/:doc'} component={PolicyScreen} authorized unauthorized/>

						<Route path={'/admin'} extact component={AdminSwitch}/>
						<Route path={'/service'} component={ServiceSwitch}/>

						<Route path={'/404'} component={NotFoundScreen}/>
						<Route component={NotFoundScreen}/>
					</AnimatedSwitch>
				</Analytics>
			</BrowserRouter>
		);
	}
}

export default Router;
