import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
import clock from '../../../../resources/admin/icon/clock.svg';
import phone from '../../../../resources/admin/icon/phone.svg';
import answer from '../../../../resources/admin/icon/answer-arrow.svg';
/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';
import {Link} from "react-router-dom";
import {Report} from "../../../../models/Report";
import moment from "moment";
import {ReportContentComponent} from "../../../../components/admin/report/content/ReportContentComponent";
import {api} from "../../../../api/api";

class Screen extends React.Component {

	state = {
		report: new Report({}),
	};

	get uuid() {
		return this.props.match?.params?.uuid;
	}

	componentDidMount() {
		Report.fetch(this.uuid, {join_user: true, join_report_contents: true, join_response: true}).then((report) => {
			this.setState({report});
		});
	}

	onClickDelete = () => {
		if (window.confirm('정말 삭제하시겠습니까?')) {
			api.delete(`/v1/admin/report/${this.uuid}`).then((r) => {
				if (!r.ok) return alert('삭제실패');
				alert('삭제성공');
				this.props.history.push('/admin/report');
			}).catch(e => {
				alert('삭제실패');
			});
		}
	};

	render() {
		return (
			<ContainerComponent>
				<div>

					<div id="ad-contents" className="write">
						<h1 className="contents-title">{this.state.report?.user?.company?.business_type?.name || '-'}</h1>
						<div className="write-info">
							<span>{this.state.report?.user?.company?.name || '-'}</span>
							<span><img src={clock} width="14px" alt="날짜" /> {this.state.report?.created_at ? moment.unix(this.state.report?.created_at).format('YYYY-MM-DD') : '-'}</span>
							<span><img src={phone} width="15px" alt="연락처" /> {this.state.report?.user?.company?.contact_number || '-'}</span>
						</div>

						<p className="user-write">{this.state.report?.text}</p>

						<div className="ad qna-comment">
							{this.state.report?.responses?.map(response => (
								<>
									<strong>답변</strong>
									<div style={{paddingTop: 8, paddingBottom: 8, marginLeft: 18, display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
										<img src={answer} width="18px" alt="화살표" style={{}}/>
										<div style={{paddingLeft: 0}}>
											{response?.text?.split('\n').map((line) => (
												<span className='user-write'>{line}<br/></span>
											))}
										</div>
									</div>
								</>
							))}
						</div>

						{/* 단 */}
						{this.state.report?.contents?.map((e, index) => (
							<ReportContentComponent key={e.uuid} content={e}/>
						))}
						{/* 단 */}

						<span className="e-line"></span>

						<div className="viewbtn-wrap">
							<div className="left">
								<Link to={`/admin/report/${this.uuid}/response`}><button className="view-btn">수정</button></Link>
								<button className="view-btn" onClick={this.onClickDelete}>삭제</button>
							</div>
							<Link to={'/admin/report'}><button className="right view-btn">목록</button></Link>
						</div>
					</div>

				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;
