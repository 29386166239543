import {User} from "../models/User";
import {action, observable, runInAction} from "mobx";
import BaseStore from "./BaseStore";
import {api} from "../api/api";
import jwt from 'jsonwebtoken';
import {StorageUtils} from "../utils/StorageUtils";

export default class SessionStore extends BaseStore {
  token: string;
  @observable.deep user: User;

  constructor(rootStore) {
    super(rootStore);

    // Initialize
    if (!this.user) {
      const authorization = StorageUtils.getAuthorization();
      if (authorization) {
        try {
          const payload = jwt.decode(authorization, {});
          this.setUser(new User({...payload}));
        } catch (e) { console.error(e) }
      }
    }
  }

  @action
  reset() {
    this.user = null;
    this.token = null;
  }

  @action
  setToken(token: string) {
    runInAction(() => {
      this.token = token;
    });
  }

  @action
  setUser(user: User) {
    this.user = user;
  }

  @action
  signIn(username: string, password: string, keepSession: boolean): Promise {
    return new Promise((resolve, reject) => {
      api.post('/v1/auth/sign-in', {username, password}).then((r) => {
        if (!r.ok) {
          console.warn(r);
          reject(r);
          return;
        }

        const token = r.data.token;
        if (!token) {
          console.error('Token is not exists', r);
          reject('TOKEN_IS_NOT_EXISTS');
          return;
        }

        const payload = jwt.decode(token, {});
        console.log('Decoded JWT', payload);

        // Save JWT to LocalStorage
        StorageUtils.setAuthorization(token, keepSession);

        // 저장된 JWT 체크
        if (token === StorageUtils.getAuthorization()) {
          console.log('Sign-in success', token);

          runInAction(() => {
            this.setUser(new User({...payload}))
          });

          api.setHeader('Authorization', token);
          resolve();
        } else {
          console.warn('An error occurred while save token to local');
          reject(r);
        }
      }).catch((e) => {
        console.error(e);
        return reject(e);
      });
    });
  }
}