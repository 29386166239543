import React from 'react';
import {Link} from "react-router-dom";
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import Fade from "react-reveal/Fade";

/** img */
import home from "../../resources/homepage/Home.svg";
/** img */
/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
import {POLICY_PRIVACY, POLICY_TERMS_OF_SERVICE} from "../../constants/policy";
import {termsOfService} from "../../resources/policy/terms_of_service";
import {privacyPolicy} from "../../resources/policy/privacy_policy";

/** CSS */

class Screen extends React.PureComponent {

	get doc() {
		return this.props.match?.params?.doc;
	}

	componentDidMount() {
		if (![POLICY_TERMS_OF_SERVICE, POLICY_PRIVACY].includes(this.doc)) {
			this.props.history.push('/404');
		}
	}

	renderContent = () => {
		switch (this.doc) {
			case POLICY_TERMS_OF_SERVICE: return termsOfService;
			case POLICY_PRIVACY: return privacyPolicy;
			default: return null;
		}
	}

	render() {
		const content = this.renderContent();
		if (!content) {
			return null;
		}

		return (
			<div id="wrap">

				{/* header */}
				<HeaderComponent/>

				<div id="top-menu">
					<div className="section-in">
						<Fade bottom><h1 className="top-title">Service policy<br/><strong>이용약관</strong></h1></Fade>
						<div className="location">
							<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
							<strong>이용약관</strong>
							<strong>서비스 이용약관</strong>
						</div>
					</div>
				</div>

				<div id="sub-container" className="notice-con">
					<div className="section-in">
						{this.renderContent()}
					</div>
				</div>

				{/* footer */}
				<FooterComponent/>

			</div>
		)
	}
}

export default Screen;
