import {User} from "./User";
import {BusinessType} from "./BusinessType";
import {api} from "../api/api";
import {action} from "mobx";
import {CompanyManager} from "./CompanyManager";
import {Agent} from "./Agent";

export class Company {

  user_uuid: string;
  created_at: string;
  updated_at: string;
  business_type_uuid: string;
  agent_uuid: string;
  name: string;
  address: string;
  contact_number: string;
  sales: number;
  employees: number;

  // JOIN
  user: User;
  business_type: BusinessType;
  manager: CompanyManager;
  agent: Agent;

  constructor(company: Company) {
    Object.assign(this, company);
    if (company?.user) this.user = new User(company.user);
    if (company?.business_type) this.business_type = new BusinessType(company.business_type);
    if (company?.agent) this.agent = new Agent(company.agent);
  }

  static fetch = (uuid: string, params: Object = {}): Promise<Company> => new Promise((resolve, reject) => {
    return api.get(`/v1/admin/company/${uuid}`, params).then((response) => {
      if (!response.ok) return reject(response);
      if (!response.data.company) return reject(response);
      return resolve(new Company(response.data.company));
    }).catch((e) => {
      return reject(e);
    });
  });

  static fetchCompanies = (params: Object = {query: undefined, offset: undefined, limit: undefined, join_report_contents: undefined}): Promise<Company[]> => new Promise((resolve, reject) => {
    return api.get('/v1/admin/company', params).then((response) => {
      if (!response.ok) return reject(response);
      const reports: Company[] = (response.data.companies || []).map((e) => new Company(e));
      return resolve(reports);
    }).catch((e) => {
      return reject(e);
    });
  });

  static fetchCount = (): Promise<number> => new Promise((resolve, reject) => {
    return api.get('/v1/admin/company/count', {}).then((response) => {
      if (!response.ok) return reject(response);
      const count = parseInt(response.data.count);
      return resolve(count);
    }).catch((e) => {
      console.log(e);
      return reject(e);
    });
  });

  @action
  fetchManager(): Promise<CompanyManager> {
    return new Promise((resolve, reject) => {
      api.get(`/v1/admin/company/${this.user_uuid}/manager`).then((r) => {
        if (!r.ok) return reject(r);
        if (!r.data.manager) return reject(r);
        const manager = new CompanyManager(r.data.manager);
        this.manager = manager;
        return resolve(manager);
      }).catch((e) => {
        console.error(e);
        reject(e);
      });
    });
  }
}
