import React from 'react';
import ContainerComponent from "../../../components/admin/common/ContainerComponent";

/** img */
import leftarrowOff from "../../../resources/admin/icon/left-arrow-off.svg";
import rightarrowOn from "../../../resources/admin/icon/right-arrow-on.svg";
import close from "../../../resources/admin/icon/close.svg";
/** img */
/** CSS */
import '../../../css/common.css';
import '../../../css/admin/form.css';
import '../../../css/admin/dashboard.css';
import {inject} from "mobx-react";
import {TYPE_SUPERUSER} from "../../../constants/user_type";
import {Redirect} from "react-router-dom";

/** CSS */

class Screen extends React.PureComponent {
	render() {
		if (this.props.sessionStore?.user?.type !== TYPE_SUPERUSER) {
			return <Redirect to={{ pathname: '/admin', state: {  } }}/>;
		}

		return (
			<ContainerComponent>
				<div>

					<div id="ad-contents">
						<h1 className="contents-title">매출통계</h1>
						<div className="contents-in">
							<div className="schfrm">
								<div className="date-pick">
									기간
									<input type="date" id="start" name="start" />
									<span>~</span>
									<input type="date" id="end" name="end" />
									<button className="d-search">조회</button>
								</div>
								<div className="pay-check">
									분야
									<div className="radio">
										<input type="radio" id="p1" name="payment" />
										<label htmlFor="p1">결제실패</label>
									</div>
									<div className="radio">
										<input type="radio" id="p2" name="payment" />
										<label htmlFor="p2">결제완료</label>
									</div>
								</div>
								<div className="left">총 <strong>99</strong>건</div>
								<div className="right">
									<div className="search-box">
										<input type="text" id="ad-search" placeholder="검색어를 입력해주세요" className="search" />
										<div className="search-btn"></div>
									</div>
								</div>
							</div>
							<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
								<colgroup>
									<col />
									<col width="5%;"/>
									<col width="15%;"/>
									<col width="14%;"/>
									<col width="14%;"/>
									<col width="18%;"/>
									<col width="12%;"/>
									<col width="12%;"/>
									<col width="10%;"/>
								</colgroup>
								<thead>
								<tr>
									<th className="list-check check" scope="col">
										<input type="checkbox" id="a1" className="check_all" name="전체선택" />
										<label htmlFor="a1"></label>
									</th>
									<th scope="col">아이디</th>
									<th scope="col">업체명</th>
									<th scope="col">상품명</th>
									<th scope="col">결제 수단</th>
									<th scope="col">결제일</th>
									<th scope="col">결제 상태</th>
									<th scope="col" className="editTit">상세내역</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a2" name="선택" />
										<label htmlFor="a2"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td>결제완료</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a3" name="선택" />
										<label htmlFor="a3"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td className='error'>결제실패</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a4" name="선택" />
										<label htmlFor="a4"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td>결제완료</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a5" name="선택" />
										<label htmlFor="a5"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td>결제완료</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a6" name="선택" />
										<label htmlFor="a6"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td>결제완료</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a7" name="선택" />
										<label htmlFor="a7"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td>결제완료</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a8" name="선택" />
										<label htmlFor="a8"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td>결제완료</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a9" name="선택" />
										<label htmlFor="a9"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td>결제완료</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								<tr>
									<td className="list-check check">
										<input type="checkbox" id="a10" name="선택" />
										<label htmlFor="a10"></label>
									</td>
									<td>ACompany</td>
									<td>제조업A</td>
									<td>12개월 이용권</td>
									<td>신용카드</td>
									<td>21-03-20</td>
									<td>결제완료</td>
									<td className="edit"><button>보기</button></td>
								</tr>
								</tbody>
							</table>

							<button className="ad-delete">선택삭제</button>
							<ul className="ad-pager">
								<li><img src={leftarrowOff} width="9px" alt="왼쪽 화살표" /></li>
								<li className="on">1</li>
								<li>2</li>
								<li><img src={rightarrowOn} width="9px" alt="오른쪽 화살표" /></li>
							</ul>

							{/* 보기 클릭시 modal 노출 */}
							<div id="basic-modal" style={{display:'none'}}>
								<div className="modal info-modal">
									<div id="modal-close"><img src={close} width="16px;" alt="닫기" /></div>
									<h1>결제 상세내역</h1>

									<div className="modal-in">
										<ul className='pay-list'>
											<li><strong>아이디</strong><span>ACompany</span></li>
											<li><strong>업체명</strong><span>제조업A</span></li>
											<li><strong>상품명</strong><span>12개월 이용권</span></li>
											<li><strong>결제 금액</strong><span>540,000원</span></li>
											<li><strong>결제 수단</strong><span>신용카드</span></li>
											<li><strong>결제일</strong><span>21-03-20 11:33:35</span></li>
											<li><strong>다음 결제일</strong><span>22-03-20 11:33:35</span></li>
											<li><strong>결제상태</strong><span>결제완료</span></li>
										</ul>
									</div>

									<div className="btn-wrap">
										<button id="modal-btn-ok" className="modal-btn btn-edit">확인</button>
									</div>
								</div>
							</div>


						</div>
					</div>

				</div>
			</ContainerComponent>
		);
	}
}

export default inject('sessionStore')(Screen);
