import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';

/** img */
import home from "../../resources/homepage/Home.svg";
import process1 from "../../resources/homepage/sv-process01.svg";
import process2 from "../../resources/homepage/sv-process02.svg";
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {

	componentDid() {
		window.onscroll = function() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-nav-wrap").className = "sv-nav-fixed";
			} else {
				document.getElementById("sv-nav-wrap").className = "sv-nav";
			}
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-con-wrap").className = "sv-contents-fixed sv-contents";
			} else {
				document.getElementById("sv-con-wrap").className = "sv-contents";
			}
		};
	}

	render() {
		return (
			<div id="wrap" className="sv-long-in">

					{/* header */}
					<HeaderComponent/>
					
					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title"><strong>안전관리대행수탁</strong></h1>
							<div className="location sv-location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<Link to={'/service'}><strong>제공서비스</strong></Link>
								<Link to={'/service?tab=safety'}><strong>안전관리 전문기관</strong></Link>
								<strong>안전관리대행수탁</strong>
							</div>
						</div>
					</div>
					<div id="sub-container">
						<div className="section-in sv-in">

							<div className="sv-nav" id="sv-nav-wrap">
								<ul>
									<li className="on"><NavHashLink smooth to={'/service/75674628-fcfd-4385-8e68-e08745359215/#sv1'} activeClassName="on">1. 안전관리대행수탁</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/75674628-fcfd-4385-8e68-e08745359215/#sv2'} activeClassName="on">2. 안전관리기술지도</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/75674628-fcfd-4385-8e68-e08745359215/#sv3'} activeClassName="on">3. 안전관리 대행위탁</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/75674628-fcfd-4385-8e68-e08745359215/#sv4'} activeClassName="on">4. 안전관리 민간위탁사업</NavHashLink></li>
								</ul>
							</div>

							<div className="sv-contents" id="sv-con-wrap">
								{/* 안전관리대행수탁 */}
								<div id="sv1">
									<h6>1. 안전관리대행수탁</h6>
									<p>
										<strong>개요</strong>
										상시 근로자수 50명 이상을 사용하는 사업장의 경우 안전관리자를 선임하여 안전에 관한 기술적인 사항에 대해 사업주 또는 관리책임자를 보좌하고 관리감독자에게 조언·지도하도록 하고 있으며, 이 경우 안전관리자의 업무를 안전관리전문기관에 위탁(건설업 제외) 가능합니다.[산업안전보건법 제17조]
									</p>
									<p>
										<strong>안전관리업무 위탁대상</strong>
										상시근로자 50명 이상 사업장(건설업 제외)으로 안전관리자 법적 선임의무가 있는 사업장 및 안전관리업무를 위탁하고자 하는 사업장이 대상에 해당합니다.(산업안전보건법 시행령 별표3)
									</p>
									<p>
										<strong>세부 지원 내용</strong>
										• 월 2회 이상 사업장 안전관리상태 점검 및 법령 위반사항, 불안전한 요소에 대한 구체적인 개선대책 제시<br />
										• 신규계약, 중대재해발생 및 동종업종평균재해율 이상인 사업장에 대한 정밀안전점검 실시<br />
										• 산업재해발생의 원인조사 및 재발방지를 위한 기술적 조언·지도<br />
										• 최신 계측장비를 활용한 점검 및 개선대책 제시<br />
										• 안전기술자료 및 교육자료 등 각종 안전관련 자료제공<br />
										• 산업안전보건법, 산업재해보상보험법 및 근로기준법 등 관련 법률 상담<br />
										• 기타 안전보건개선계획서 작성 등 각종 안전관련 업무지원
									</p>
									<p>
										<strong style={{paddingBottom:'10px'}}>위반 시 법적 제재 사항</strong>
										<strong className="green">산업안전보건법 시행령 별표 35(과태료의 부과기준)</strong>
										<div className="respon-table">
										<table className="sv-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<thead>
												<tr>
													<th rowSpan={2}>위반행위</th>
													<th rowSpan={2}>근거 법조문</th>
													<th rowSpan={2}>세부내용</th>
													<th colSpan={3}>과태료 금액(만원)</th>
												</tr>
											<tr>
												<th>1차</th>
												<th>2차</th>
												<th>3차</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td rowSpan={2}>법 제17조 제 1항을 위반하여 안전관리자를 두지 않거나 업무를 수행하도록 하지 않은 경우</td>
												<td rowSpan={2}>법 제175조 제5항 제1호</td>
												<td>안전관리자를 선임하지 않은 경우</td>
												<td>500</td>
												<td>500</td>
												<td>500</td>
											</tr>
											<tr>
												<td>안전관리자의 업무를 수행하도록 하지 않은 경우</td>
												<td>300</td>
												<td>400</td>
												<td>500</td>
											</tr>
											<tr>
												<td>제17조 제3항에 따른 명령을 위반하여 안전관리자를 늘리지 않거나 교체하지 않은 경우</td>
												<td>법 제175조 제5항 제2호</td>
												<td>-</td>
												<td>500</td>
												<td>500</td>
												<td>500</td>
											</tr>
											</tbody>
										</table>
										</div>
									</p>
								</div>

								{/* 안전관리기술지도 */}
								<div id="sv2">
									<h6>2. 안전관리기술지도</h6>
									<p>
										<strong>개요</strong>
										법적 안전관리자의 선임 유·무와 관계없이 사업주의 자율의사에 따른 요청에 의하여 전문인력과 최신장비를 활용, 안전에 관한 기술업무를 지원하는 사업입니다.
									</p>
									<p>
										<strong>세부지원내용</strong>
										• 월 1회 이상 사업장 안전관리상태 점검 및 법령 위반사항, 불안전한요소에 대한 구체적인 개선대책 제시<br />
										• 산업재해발생의 원인조사 및 재발방지를 위한 기술적 조언·지도<br />
										• 최신 계측장비를 활용한 점검 및 개선대책 제시<br />
										• 안전기술자료 및 교육자료 등 각종 안전관련 자료제공<br />
										• 산업안전보건법, 산업재해보상보험법 및 근로기준법 등 관련법령에 대한 상담
									</p>
								</div>

								{/* 안전관리대행위탁 */}
								<div id="sv3">
									<h6>3. 안전관리대행위탁</h6>
									<p>
										<strong>사업안내</strong>
										부산안전원은 노동부로부터 지정받은 안전관리 전문기관으로서 산업현장에서 다년간 안전관리자로서 재직하면서 쌓은 풍부한 실무경험과 know-how를 보유한 전문기술인력과 최신장비를 바탕으로 기업의 이윤과 직결될 수 있는 효율적이며 능률적인 안전관리 기술 및 교육을 제시·지도하여 산업재해 예방과 초일류 안전관리 업체로 정착시켜 무재해 산업사회 창조의 기틀을 마련하는데 일익을 담당하고자 합니다.
									</p>
									<p>
										<strong>법적 근거</strong>
										산업안전보건법 제17조 [안전관리자]  , 산업안전보건법 제19조 [안전보건관리담당자자]
									</p>
									<p>
										<strong>위탁대상</strong>
										1. 상시근로자 50인 이상 사업장(건설업 제외)으로 안전관리자 법적 선임의무가 있는 사업장 및 안전관리업무를 위탁하고자 하는 사업장<br />
										2. 상시근로자 20인에서 50인 미만 제조업, 임업, 하수폐기물처리, 원료재생 및 환경복원업 중 안전보건관리담당자 선임의무가 있는 사업장<br />
										3. 산업안전보건법상 안전관리자 선임 의무가 없는 사업장(50인 미만) 또는 선임이 되어 있어도 기술적 자문이 필요한 사업장<br />
										※ 상시근로자수의 의미 : 정규직, 일용직등 임금을 목적으로 사용자의 지휘·명령에 근로를 제공하는 자
									</p>
									<p>
										<strong>업무 절차</strong>
										<img src={process1} width="100%" alt="업무 절차" />
									</p>
									<p>
										<strong>지원업무 내역</strong>
										<div className="respon-table">
										<table className="sv-table sv-halftable" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<tr>
												<th>사업장 안전점검</th>
												<td>
													사업장을 정기적으로 때로는 필요에 의하여 수시로 사업장을 방문하여<br />
													▸ 안전관리 현장방문 점검<br />
													▸ 사업장내 안전상의 조치에 대한 기술지원<br />
													▸ 근로자 정기교육교재, 표어, 포스터 등 각종 교재 및 홍보물 제공<br />
													▸ 저비용, 고효율 산재예방기법 보급<br />
													▸ 위험관리 모델 기법 보급<br />
													▸ 무재해 3대 실천행동 등 무재해운동 추진기법 보급<br />
													▸ 우수한 안전장치 및 개인 보호장구에 대한 안내<br />
													▸ 다양한 산재예방 정부지원사업에 관한 안내
												</td>
											</tr>
											<tr>
												<th>사업장 안전교육</th>
												<td>
													사업주가 실시하여야 할 산업안전 및 재해예방에 관한 교육을 실시<br />
													▸ 관리감독자 안전보건교육<br />
													▸ 근로자의 정기안전보건교육<br />
													▸ 작업별 특별안전보건교육(크레인, 지게차, 하역등)<br />
													▸ 신규채용자 및 작업내용 변경자에 대한 안전교육<br />
													▸ 계절별 화재, 감전등 예방 안전교육
												</td>
											</tr>
											<tr>
												<th>유해위험기계․기구 안전검사 안내</th>
												<td>
													유해위험기계․기구에 의한 재해예방을 위하여<br />
													▸ 산업안전보건법 제36조에서 정하는 기계․기구에 대한 안전검사 안내<br />
													▸ 유해위험기계․기구의 취급 및 안전상의 조치, 검사
												</td>
											</tr>
											<tr>
												<th>산업안전보건업무 개선에 관한 지원</th>
												<td>
													재해예방과 안전관리업무 활성화를 위하여<br />
													▸ 안전보건개선계획의 수립 및 추진업무<br />
													▸ 안전보건관리규정 및 산업안전보건위원회 운영 업무<br />
													▸ 명예산업안전감독관 선임 및 운영업무<br />
													▸ 작업환경개선에 관한 업무<br />
													▸ 재해발생시 원인분석 및 대책수립 업무<br />
													▸ 재해발생시 긴급조치 조력
												</td>
											</tr>
											<tr>
												<th>행정업무 상담 및 안내</th>
												<td>
													▸ 현장 실무를 바탕으로 한 기술요원들의 실질적인 노동관계업무 지원<br />
													▸ 산업재해관련 각종 서류 작성 및 유관기관 관계 업무 지원<br />
													▸ 사업 수행에 필요한 관련법에 관한 상담 및 지원
												</td>
											</tr>
										</table>
										</div>
									</p>
								</div>

								{/* 안전관리 민간위탁사업 */}
								<div id="sv4">
									<h6>4. 안전관리 민간위탁사업</h6>
									<p>
										안전관리 대행기관을 통하여 소규모 사업장의 재해예방활동을 지원
									</p>
									<p>
										<strong>사업안내</strong>
										50인 미만 사업장은 안전관리자 선임의무가 면제되고 사업주의 안전의식 부족, 열악한 작업조건 등으로 인하여 재해가 전체 사고성 재해의 75% 차지하여 안전관리 대행기관을 통하여 소규모 사업장의 재해예방활동을 지원하고 있습니다.
									</p>
									<p>
										<strong>위탁대상</strong>
										상시근로자 50인 미만 사업장으로 노동부에서 지원대상 사업장을 선정 사업장에 대해 기술 및 자금지원
									</p>
									<p>
										<strong>업무절차</strong>
										재해분석을 통하여 재발 위험사업장 및 잠재적 위험 사업장을 집중관리 대상으로 Target 선정하여 기술, 재정, 교육, 안전검사등 가용수단을 총 동원한 패키지(Package) 지원방식으로 개선
										<img src={process2} width="100%" alt="업무 절차" />
									</p>
									<p>
										<strong>지원 업무 내역</strong>
										재해분석을 통하여 재발 위험사업장 및 잠재적 위험 사업장을 집중관리 대상으로 Target 선정하여 기술, 재정, 교육, 안전검사등 가용수단을 총 동원한 패키지(Package) 지원방식으로 개선
										<div className="respon-table">
										<table className="sv-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<thead>
											<tr>
												<th>구분</th>
												<th>지원내용</th>
												<th>비고</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>1단계</td>
												<td style={{textAlign:'left', whiteSpace:'nowrap'}}>
													<strong>대상선정 및 사업안내</strong>
													1. 선정기준<br />
													- 50인 미만 제조업의 업종·규모별 과거 5년간 재해발생 추세 분석에 따른 과학적 예측<br />
													2. 사업안내<br />
													- 사업취지 및 방문목적, 사업추진절차, 기술지원 및 재정지원절차 등 안내<br />
													3. 사업주 및 사업장 안전관계자 교육(지방관서 주관)<br />
													- 사업주 교육 대상 : 재해발생 사업장의 사업주 대상<br />
													- 안전관계자 교육 : 기술지원 전월 마지막 주 지원 대상 사업장 안전 관계자 교육
												</td>
												<td>재해분석 관할지방 노동관서</td>
											</tr>
											<tr>
												<td>2단계</td>
												<td style={{textAlign:'left', whiteSpace:'nowrap'}}>
													<strong>사업장 방문 기술지원 및 안전교육</strong>
													방문횟수<br />
													- 연간 총 2 ~ 4회<br />
													기술지원 내용<br />
													- 1차 : 위험성평가를 중심으로 기술 및 교육지원<br />
													- 2차 : 개선조치 이행 확인 및 추가 기술지원<br />
													- 3 ~ 4차(전문기관) : 개선조치 이행 확인
												</td>
												<td>개선계획 일정에 따라 사업주 자체 개선</td>
											</tr>
											<tr>
												<td>3단계</td>
												<td style={{textAlign:'left', whiteSpace:'nowrap'}}>
													<strong>개선확인 절차 및 지방관서(노동부) 조치사항</strong>
													2차 기술지원 : 1차 지원내용에 대한 개선완료 확인<br />
													- 1차 지원내용에 대한 개선 후 증빙서류 제출 시 2차 확인 생략<br />
													- 2차 기술지원 시 미개선 사항(법 위반사항) 행정조치<br />
													- 기술지원 거부 및 급박한 위험요인 보유사업장 지방관서 이첩=
												</td>
												<td>관할지방 노동관서</td>
											</tr>
											</tbody>
										</table>
										</div>
									</p>
								</div>

								<div className="sv-subBox">
									현업에서의 풍부한 경험 및 노하우를 바탕으로 산업재해로 인한 근로자의 귀중한 생명 보호와
									공공기관 안전담당자, 기업 내 사업주, 안전관리자의 안전관리 근로생애까지 관리해드리겠습니다.
								</div>

								<div className="sv-goback">
									<Link to={'/service'}>
										<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
									</Link>
								</div>
							</div>

						</div>
					</div>

					<FooterComponent/>

				</div>
		)
	}
}

export default Screen;
