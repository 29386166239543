import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {BusinessType} from "../../../models/BusinessType";
import moment from "moment";
import AnimatedNumber from "animated-number-react";

export default class BusinessTypeListItemComponent extends React.PureComponent {

    static propTypes = {
        business_type: PropTypes.instanceOf(BusinessType).isRequired,
        onClickEdit: PropTypes.func,
    };

    onClickEdit = () => {
        typeof this.props.onClickEdit === 'function' && this.props.onClickEdit(this.props.company);
    };

    render() {
        const business_type = this.props.business_type;
        if (!business_type) return null;

        const businessTypeLink = (child) => <Link to={`/admin/business-type/${business_type.uuid}`}>{child}</Link>;

        return (
            <tr>
                <td>{businessTypeLink(business_type.name)}</td>
                <td>{businessTypeLink(business_type.ticketTypeAlias)}</td>
                <td>{businessTypeLink(
                    <AnimatedNumber
                        value={business_type.company_count}
                        formatValue={(value) => value.toFixed(0)}/>
                )}</td>
                <td>{business_type?.created_at ? businessTypeLink(moment.unix(business_type.created_at).format('YYYY-MM-DD hh:mm:ss')) : ''}</td>
                <td className="edit"><Link to={`/admin/business-type/${business_type.uuid}`}><button>보기</button></Link></td>
            </tr>
        )
    }
}