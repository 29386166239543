import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Notice} from "../../../models/Notice";

class RecentNoticesComponent extends React.Component {

	static propTypes = {
		history: PropTypes.any.isRequired,
	}

	state = {
		isFetching: true,
		items: [],
	};

	componentDidMount() {
		this.fetch(true);
	}

	fetch = (ignoreFetching: boolean) => {
		if (this.state.isFetching && !ignoreFetching) return;

		this.setState({isFetching: true}, () => {
			Notice.fetchNotices({ob: 'id', od: 'DESC', limit: 7}).then((notices) => {
				this.setState({
					isFetching: false,
					items: notices,
				});
			}).catch((e) => {
				console.error(e);
				this.setState({isFetching: false});
			});
		});
	};

	renderItems = () => this.state.items.map((notice: Notice, index) => {
		return (
			<li key={notice.uuid} onClick={() => this.props.history.push(`/admin/notice/${notice.uuid}`)}>
				{notice.title}
			</li>
		);
	});

	render() {
		return (
			<div className="left">
				<div className="da-subTit">
					<h5>공지사항</h5>
					<Link to={'/admin/notice'}>더보기 +</Link>
				</div>
				<ul>
					{this.renderItems()}
					{/*<ListLoadingComponent visible={this.state.isFetching}/>*/}
				</ul>
			</div>
		);
	}
}


export default RecentNoticesComponent;
