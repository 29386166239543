import React from "react";
import PropTypes from "prop-types";
import {Question} from "../../../models/Question";
import {observer} from "mobx-react";
import moment from "moment";
import new_badge from '../../../resources/admin/icon/new.svg';

class QuestionListItemComponent extends React.Component {

	static propTypes = {
		history: PropTypes.any.isRequired,
		question: PropTypes.instanceOf(Question).isRequired,
	};

	componentDidMount() {
		if (!this.props.question?.answer_count && isNaN(this.props.question?.answer_count)) {
			this.props.question.fetchAnswerCount().then(() => {});
		}
	}

	render() {
		const question: Question = this.props.question;

		return (
			<li
				onClick={() => this.props.history.push(`/admin/question/${question.uuid}`)}
				style={{color: question.statusColor}}>
				{question.text}

				{moment.unix(this.props.question.created_at).diff(moment(), 'days') <= 1 &&
				<img src={new_badge} width="14px" alt="새로운 소식" style={{marginLeft:"8px"}}/>}
			</li>
		)
	}
}

export default observer(QuestionListItemComponent);