import React from 'react';
import PropTypes from "prop-types";
import {Report} from "../../../models/Report";
import {Link} from "react-router-dom";
import moment from "moment";

export class ReportListItemComponent extends React.PureComponent {

	static propTypes = {
		report: PropTypes.instanceOf(Report).isRequired,
	}

	render() {
		const report: Report = this.props.report;
		const reportLink = (child, style) => <Link to={`/admin/report/${report.uuid}`} style={style}>{child}</Link>;
		const companyLink = (child) => <Link to={`/admin/company/${report?.user_uuid}`}>{child}</Link>;
		const agentLink = (child) => <Link to={`/admin/agent/${report?.user?.company?.agent_uuid}`}>{child}</Link>;

		return (
			<tr>
				<td>{report?.user?.company?.name ? companyLink(report?.user?.company?.name) : '-'}</td>
				<td><div className='long-txt'>{reportLink(report.text)}</div></td>
				<td>{report?.user?.company?.agent_uuid ? agentLink(report?.user?.company?.agent?.name) : '-'}</td>
				<td>{reportLink(moment.unix(report.created_at).format('YYYY-MM-DD'))}</td>
				<td>{reportLink(report.statusAlias, {color: report.statusColor})}</td>
			</tr>
		);
	}
}

