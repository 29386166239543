import React from 'react';
import {Link} from "react-router-dom";
import HeaderComponent from "../components/common/HeaderComponent";
import FooterComponent from "../components/common/FooterComponent";
import ListLoadingComponent from "../components/admin/common/ListLoadingComponent";
import Fade from "react-reveal/Fade";

/** img */
import home from "../resources/homepage/Home.svg";
/** img */
/** CSS */
import '../css/common.css';
import '../css/pc-common.css';
import '../css/contents.css';
import {Notice} from "../models/Notice";
import moment from "moment";

/** CSS */


class Screen extends React.PureComponent {

	state = {
		isFetching: true,
		items: [],
	};

	componentDidMount() {
		this.fetchNotices();
	}

	fetchNotices = () => {
		this.setState({isFetching: true}, () => {
			Notice.fetchNotices().then((notices) => {
				this.setState({isFetching: false, items: notices});
			});
		});
	};

	renderItem = (item: Notice) => (
		<tr>
			<td className="title"><Link to={`/notice/${item.uuid}`}>{item.title}</Link></td>
			<td className="middle"><Link to={`/notice/${item.uuid}`}>{item?.created_at && moment.unix(item.created_at).format('YYYY-MM-DD')}</Link></td>
		</tr>
	);

	renderItems = () => {
		if (this.state.isFetching) return null;
		return this.state.items.map((item) => this.renderItem(item));
	};

	renderLoadingEmpty = () => {
		if (this.state.isFetching) return (
			<tbody className="no-contents">
				<tr>
					<td colSpan="3">
						<ListLoadingComponent/>
					</td>
				</tr>
			</tbody>
		);

		if (this.state.items && this.state.items.length > 0) return null;
		return (
			<tbody className="no-contents">
				<tr>
					<td colSpan="3">등록된 글이 없습니다</td>
				</tr>
			</tbody>
		);
	}

	render() {
		return (
			<div id="wrap">

				{/* header */}
				<HeaderComponent/>


				<div id="top-menu">
					<div className="section-in">
						<Fade bottom><h1 className="top-title">전국 어디서나 만날 수 있는<br /><strong>스마트안전 서비스</strong></h1></Fade>
						<div className="location">
							<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
							<strong>고객센터</strong>
							<strong>공지사항</strong>
						</div>
					</div>
				</div>


				<div id="sub-container" className="notice-con">
					<div className="section-in">

						<table className="basic-table sub-table notice-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
							<thead>
							<tr>
								<th style={{width:'75%'}}>제목</th>
								<th className="middle"  style={{width:'15%'}}>작성일</th>
							</tr>
							</thead>

							{/* 게시물이 없는 경우 */}
							{this.renderLoadingEmpty()}

							{/* 게시물이 있는 경우 */}
							<tbody>
							{this.renderItems()}
							</tbody>

						</table>

					</div>
				</div>

				{/* footer */}
				<FooterComponent/>

			</div>
		)
	}
}

export default Screen;
