import {api} from "../api/api";
import {ReportContent} from "./ReportContent";
import {User} from "./User";
import {action} from "mobx";
import {ReportResponse} from "./ReportResponse";
import {STATUS_CONFIRMED, STATUS_DONE, STATUS_NORMAL} from "../constants/report";

export class Report {
	uuid: string;
	id: number;

	created_at: string;
	updated_at: string;
	deleted_at: string;

	user_uuid: string;
	status: string;
	text: string;

	// JOIN
	user: User;
	contents: ReportContent[];
	responses: ReportResponse[];

	constructor(report: Report) {
		Object.assign(this, report);

		if (report.user) this.user = new User(report.user);
		if (report.contents) this.contents = report.contents.map(e => {
			const report_uuid = e.report_uuid || report.uuid;
			return new ReportContent({ ...e, report_uuid });
		});

		if (report.responses) this.responses = report.responses.map(e => new ReportResponse(e));
	}

	static fetch(uuid: string, params: Object): Promise<Report> {
		return new Promise((resolve, reject) => {
			api.get(`/v1/admin/report/${uuid}`, params).then((r) => {
				if (!r.ok) return reject(r);
				if (!r.data.report) return reject(r);
				const report = new Report(r.data.report);
				return resolve(report);
			}).catch((e) => {
				console.error(e);
				reject(e);
			});
		});
	}

	static fetchReports = (params: Object = {query: undefined, offset: undefined, limit: undefined, join_report_contents: undefined}): Promise<Report[]> => new Promise((resolve, reject) => {
		return api.get('/v1/admin/report', params).then((response) => {
			if (!response.ok) return reject(response);
			const reports: Report[] = (response.data.reports || []).map((e) => new Report(e));
			return resolve(reports);
		}).catch((e) => {
			return reject(e);
		});
	});

	static fetchCount = (): Promise<number> => new Promise((resolve, reject) => {
		return api.get('/v1/admin/report/count', {}).then((response) => {
			if (!response.ok) return reject(response);
			const count = parseInt(response.data.count);
			return resolve(count);
		}).catch((e) => {
			console.log(e);
			return reject(e);
		});
	});

	@action
	fetch(): Promise<Report> {
		return new Promise((resolve, reject) => {
			api.get(`/v1/admin/report/${this.uuid}`).then((r) => {
				if (!r.ok) return reject(r);
				if (!r.data.report) return reject(r);
				const report = new Report(r.data.report);
				this.report = report;
				return resolve(report);
			}).catch((e) => {
				console.error(e);
				reject(e);
			});
		});
	}

	@action
	fetchUser(): Promise<User> {
		return new Promise((resolve, reject) => {
			api.get(`/v1/admin/user/${this.user_uuid}`).then((r) => {
				if (!r.ok) return reject(r);
				if (!r.data.user) return reject(r);
				const user = new User(r.data.user);
				this.user = user;
				return resolve(user);
			}).catch((e) => {
				console.error(e);
				reject(e);
			});
		});
	}

	get statusAlias() {
		switch (this.status) {
			case STATUS_NORMAL: return '작성완료';
			case STATUS_CONFIRMED: return '검토완료';
			case STATUS_DONE: return '완료';
			default: return 'UNKNOWN';
		}
	}

	get statusColor() {
		switch (this.status) {
			case STATUS_NORMAL: return '#DA2128';
			case STATUS_CONFIRMED: return '#00934B';
			case STATUS_DONE: return '#C8C8C8';
			default: return '#DDD';
		}
	}
}
