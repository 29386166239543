import React from 'react';
import PropTypes from 'prop-types';
import close from "../../resources/admin/icon/close.svg";

export default class CompanyEditModal extends React.PureComponent {

	onClickClose = () => {
		typeof this.props.onClose === 'function' && this.props.onClose();
	}

	render() {
		return (
			<div id="basic-modal">
				<div className="modal info-modal">
					<div id="modal-close" onClick={this.onClickClose}><img src={close} width="16px;" alt="닫기" /></div>
					<h1>안전 담당자 설정</h1>

					<div className="modal-in">
						<ul>
							<li>
								<div>아이디</div>
								<input type="text" id="user-ID" placeholder="기본 정보 노출/수정불가" disabled value={this.props.company.username}/>
							</li>
							<li>
								<div>업체명</div>
								<input type="text" id="user-name" disabled value={this.props.company.name}/>
							</li>
							<li>
								<div>주소</div>
								<input type="text" id="user-address" placeholder="기본 정보 노출/수정불가" disabled />
							</li>

							<li>
								<div>안전담당자</div>
								<select className="select">
									<option selected>선택</option>
									<option>김제로</option>
									<option>권제로</option>
									<option>이제로</option>
								</select>
							</li>
						</ul>
					</div>

					<div className="btn-wrap">
						<button id="modal-btn-cancel" className="modal-btn btn-cancel" onClick={this.onClickClose}>취소</button>
						<button id="modal-btn-ok" className="modal-btn btn-edit" onClick={this.onClickClose}>수정</button>
					</div>
				</div>
			</div>
		)
	}
}

CompanyEditModal.propTypes = {
	onClose: PropTypes.func,
}
