import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';

import CompanyListItemComponent from "../../../../components/admin/company/CompanyListItemComponent";
import {Company} from "../../../../models/Company";
import {Agent} from "../../../../models/Agent";
import {api} from "../../../../api/api";
import moment from "moment";
import {TYPE_EMPLOYEES} from "../../../../constants/ticket";
import StringUtils from "../../../../utils/StringUtils";
import TicketListComponent from "./components/TicketListComponent";

class Screen extends React.Component {

	isLockedFetch = false;

	state = {
		company: new Company(),
		isFetching: false,
		agents: [],

		agent_uuid: null,
	};

	componentDidMount() {
		this.fetch();
		this.fetchAgents();
	}

	get uuid() {
		return this.props.match?.params?.uuid;
	}

	fetch = () => {
		if (this.isLockedFetch) return;

		this.isLockedFetch = true;
		try {
			Company.fetch(this.uuid, {join_user: true, join_business_type: true, join_manager: true}).then((company) => {
				this.setState({isFetching: true}, () => {
					this.setState({isFetching: false, company, agent_uuid: company.agent_uuid}, () => {
						this.isLockedFetch = false;
					});
				});
			})

		} catch (e) {
			this.isLockedFetch = false;
		}
	}

	fetchAgents = () => {
		Agent.fetchAgents({ join_user: true }).then((agents) => {
			this.setState({agents});
		});
	};

	onClickEdit = (item) => {
		this.setState({selected_item: item});
	};

	onCloseModal = () => {
		this.setState({selected_item: null});
	};

	onChangeAgent = (e) => {
		this.setState({agent_uuid: e.currentTarget.value});
	};

	onClickSaveAgent = () => {
		const agent_uuid = this.state.agent_uuid;

		api.put(`/v1/admin/company/${this.uuid}/agent`, {agent_uuid}).then((r) => {
			if (!r.ok) return alert('저장 실패');
			this.setState((p) => ({company: new Company({ ...p.company, agent_uuid })}));
			alert('저장 성공');
		}).catch(e => {
			console.error(e);
			alert('저장 오류');
		});
	};

	renderItems = () => {
		const items = this.state.items;

		return items.map((item, index) => (
			<CompanyListItemComponent key={index} company={item} onClickEdit={this.onClickEdit}/>
		));
	}

	renderManager = () => {
		const manager = this.state?.company?.manager;

		return (
			<div style={{marginTop: 28}} className="ad-com-info">
				<h2 className="ad-infoTit">담당자 정보</h2>
				<ul>
					<li>
						<div>이름</div>
						<input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled value={manager?.name}/>
					</li>
					<li>
						<div>직위</div>
						<input type="text" id="ad-name" placeholder="기본 정보 노출" disabled value={manager?.position}/>
					</li>
					<li>
						<div>연락처</div>
						<input type="text" id="ad-address" placeholder="기본 정보 노출" disabled value={manager?.contact_number}/>
					</li>
					<li>
						<div>이메일</div>
						<input type="text" id="ad-phone" placeholder="기본 정보 노출" disabled value={manager?.email}/>
					</li>
				</ul>
			</div>
		)
	}

	renderBusinessType = () => {
		const businessType = this.state?.company?.business_type;
		if (!businessType) return null;

		return (
			<div style={{marginTop: 28}} className="ad-com-info">
				<h2 className="ad-infoTit">업종 정보</h2>
				<ul>
					<li>
						<div>고유번호</div>
						<input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled value={businessType?.uuid}/>
					</li>
					<li>
						<div>생성일</div>
						<input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled value={moment.unix(businessType?.created_at).format('YYYY.MM.DD HH:mm:ss')}/>
					</li>
					<li>
						<div>이름</div>
						<input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled value={businessType?.name}/>
					</li>
					<li>
						<div>과금형식</div>
						<input type="text" id="ad-name" placeholder="기본 정보 노출" disabled value={businessType?.ticketTypeAlias}/>
					</li>
				</ul>
			</div>
		);
	}

	render() {
		return (
			<ContainerComponent>
					<div id="ad-contents" className="ad-detail">
						<h1 className="contents-title">업체 상세 정보</h1>
						<div className="contents-in">

							<div className="ad-com-info">
								<h2 className="ad-infoTit">기본정보</h2>
								<ul>
									<li>
										<div>고유번호</div>
										<input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled value={this.state?.company?.user?.uuid}/>
									</li>
									<li>
										<div>생성일</div>
										<input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled value={moment.unix(this.state?.company?.user?.created_at).format('YYYY.MM.DD HH:mm:ss')}/>
									</li>
									<li>
										<div>아이디</div>
										<input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled value={this.state?.company?.user?.username}/>
									</li>
									<li>
										<div>업체명</div>
										<input type="text" id="ad-name" placeholder="기본 정보 노출" disabled value={this.state?.company?.name}/>
									</li>
									<li>
										<div>주소</div>
										<input type="text" id="ad-address" placeholder="기본 정보 노출" disabled value={this.state?.company?.address}/>
									</li>
									<li>
										<div>연락처</div>
										<input type="text" id="ad-phone" placeholder="기본 정보 노출" disabled value={this.state?.company?.contact_number}/>
									</li>
									{/*<li>
										<div>이름</div>
										<input type="text" id="ad-name" placeholder="기본 정보 노출" disabled value={this.state?.company?.manager?.name}/>
									</li>
									<li>
										<div>직위</div>
										<input type="text" id="ad-spot" placeholder="기본 정보 노출" disabled value={this.state?.company?.manager?.position}/>
									</li>*/}
									<li>
										<div>업종</div>
										<input type="text" id="ad-occupation" placeholder="기본 정보 노출" disabled value={this.state?.company?.business_type?.name}/>
									</li>
									{this.state?.company?.business_type?.ticket_type === TYPE_EMPLOYEES ?
										<li>
											<div>근로자수</div>
											<input type="text" id="ad-workers" placeholder="-" disabled value={this.state?.company?.employees && StringUtils.numberWithCommas(this.state?.company?.employees)}/>
										</li>
									:
										<li>
											<div>매출액</div>
											<input type="text" id="ad-workers" placeholder="-" disabled value={this.state?.company?.sales && StringUtils.numberWithCommas(this.state?.company?.sales)}/>
										</li>}
								</ul>
							</div>

							{this.renderManager()}

							{this.renderBusinessType()}

							<div className="ad-com-set">
								<h2 className="ad-infoTit">안전 담당자 설정</h2>
								<div>
									<div>안전 담당자</div>
									<select className="select" onChange={this.onChangeAgent}>
										<option>선택</option>
										{this.state.agents.map((agent: Agent, i) => (
											<option selected={this.state.agent_uuid === agent.user_uuid} value={agent.user_uuid}>{agent.name}({agent?.user?.username})</option>
										))}
									</select>

									{this.state.agent_uuid !== this.state?.company?.agent_uuid &&
									<button className="d-search" onClick={this.onClickSaveAgent}>저장</button>}
								</div>
							</div>

							<div className="ad-com-table" style={{display: 'none'}}>
								<h2 className="ad-infoTit">화학물질 취급현황</h2>
								<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
									<colgroup>
										<col width="20%;"/>
										<col width="17%;"/>
										<col width="17%;"/>
										<col width="17%;"/>
										<col width="600px;"/>
									</colgroup>
									<thead>
									<tr>
										<th scope="col">취급 화학물질</th>
										<th scope="col">최대 저장량</th>
										<th scope="col">1일 최대 취급량</th>
										<th scope="col">단위</th>
										<th scope="col">해당공정</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td>절삭유A</td>
										<td>100</td>
										<td>10</td>
										<td>kg</td>
										<td><span>해당 공정입니다.해당 공정입니다.해당 공정입니다.</span></td>
									</tr>
									<tr>
										<td>절삭유B</td>
										<td>500</td>
										<td>5</td>
										<td>ton</td>
										<td><span>해당 공정입니다.해당 공정입니다.해당 공정입니다.</span></td>
									</tr>
									<tr>
										<td>절삭유C</td>
										<td>1,000</td>
										<td>10</td>
										<td>L</td>
										<td><span>해당 공정입니다.해당 공정입니다.해당 공정입니다.</span></td>
									</tr>
									</tbody>
								</table>
							</div>

							<div className="ad-com-table" style={{display: 'none'}}>
								<h2 className="ad-infoTit">안전검사 대상품 조사표</h2>
								<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
									<colgroup>
										<col width="35%;"/>
										<col width="15%;"/>
										<col width="15%;"/>
										<col width="15%;"/>
										<col width="15%;"/>
									</colgroup>
									<thead>
									<tr>
										<th scope="col">대상품명</th>
										<th scope="col">계</th>
										<th scope="col">검사 실시</th>
										<th scope="col">검사 미실시</th>
										<th scope="col">검사 비대상</th>
									</tr>
									</thead>
									<tbody>
									<tr>
										<td>식품 제조용 설비</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>분쇄 파쇄기</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>혼합기</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>지게차</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>산업용 로봇</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>컨베이어</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>고소 작업대</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>사출 성형기</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>롤러기</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>원심기</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>국소배기장치</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>곤돌라</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>압력용기</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>리프트</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>크레인</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>전단기</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									<tr>
										<td>프레스</td>
										<td>100</td>
										<td>10</td>
										<td>5</td>
										<td>0</td>
									</tr>
									</tbody>
								</table>
							</div>

						</div>

						{/* 21.08.19 리스트 추가 */}
						<TicketListComponent user_uuid={this.uuid}/>

					</div>
			</ContainerComponent>
		);
	}
}

export default Screen;