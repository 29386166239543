import React from 'react';
import {SUBJECT_HAZARD_RISK, SUBJECT_REDUCTION_DISASTER} from "../../../../constants/report_content_reply";
import PropTypes from "prop-types";
import {api} from "../../../../api/api";
import {ReportContent} from "../../../../models/ReportContent";
import {ReportContentResponse} from "../../../../models/ReportContentResponse";

export class ContentResponseComponent extends React.PureComponent {

	static propTypes = {
		subject: PropTypes.oneOf([SUBJECT_REDUCTION_DISASTER, SUBJECT_HAZARD_RISK]).isRequired,
		content: PropTypes.instanceOf(ReportContent).isRequired,
	};

	state = {
		text: '',
	};

	onClickSubmit = () => {
		if (this.isLockedSubmit) return;
		this.isLockedSubmit = true;

		const release = (state, callback) => {
			this.setState({...state}, () => {
				this.isLockedSubmit = false;
				typeof callback === 'function' && callback();
			});
		};

		const text = this.state.text;
		if (!text || !text.trim()) {
			alert('내용을 입력해주세요');
			return release();
		}

		api.post(`/v1/admin/report/content/${this.props.content.uuid}/response`, {subject: this.props.subject, text}).then((r) => {
			if (!r.ok) {
				alert('작성할 수 없습니다');
				return release();
			}

			alert('작성 완료');
			return release();
		}).catch((e) => {
			console.error(e);
			return release();
		});

		release();
	};

	onChange = (e) => {
		this.setState({text: e.target.value});
	};

	render() {
		return (
			<div>
				<h6>{ReportContentResponse.subjectAlias(this.props.subject)}</h6>
				<div className="write-comment">
					<textarea className="textbox" placeholder="내용을 입력해주세요" onChange={this.onChange} value={this.state.text}/>
					<button onClick={this.onClickSubmit}>등록</button>
				</div>
			</div>
		);
	}
}