import React from 'react';
import moment from "moment";
import {DateUtils} from "../../../utils/DateUtils";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Company} from "../../../models/Company";

export default class CompaniesComponent extends React.Component {

	static propTypes = {
		history: PropTypes.any.isRequired,
	}

	state = {
		isFetching: true,
		items: [],
	};

	componentDidMount() {
		this.fetch(true);
	}

	fetch = (ignoreFetching: boolean) => {
		if (this.state.isFetching && !ignoreFetching) return;

		this.setState({isFetching: true}, () => {
			Company.fetchCompanies({
				ob: 'created_at', od: 'DESC', limit: 4,
				join_manager: true,
				join_business_type: true,
			}).then((companies) => {
				this.setState({
					isFetching: false,
					items: companies,
				});
			}).catch((e) => {
				this.setState({isFetching: false});
			});
		});
	};

	renderItems = () => this.state.items.map((company: Company, index) => {
		const companyLink = (child) => <Link to={`/admin/company/${company?.user_uuid}`}>{child}</Link>;

		return (
			<tr key={company.user_uuid}>
				<td><Link to={`/admin/business-type/${company?.business_type_uuid}`}>{company?.business_type?.name}</Link></td>
				<td>{companyLink(company?.name)}</td>
				<td>{companyLink(company?.manager?.contact_number)}</td>
				<td>{companyLink(company?.created_at ? DateUtils.prettyDate(moment.unix(company?.created_at)) : '-')}</td>
			</tr>
		);
	});

	render() {
		return (
			<div className="dr-top">
				<div className="da-subTit">
					<h5>신규 업체</h5>
					<Link to={'/admin/company'}>더보기 +</Link>
				</div>

				<table>
					<thead>
					<tr>
						<th>업종</th>
						<th>업체명</th>
						<th>담당자 연락처</th>
						<th>생성시점</th>
					</tr>
					</thead>

					<tbody>
					{this.renderItems()}
					</tbody>
				</table>
			</div>
		);
	}
}
