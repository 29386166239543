import React from 'react';
import {Link} from "react-router-dom";
import HeaderComponent from "../components/common/HeaderComponent";
import FooterComponent from "../components/common/FooterComponent";
import PropTypes from 'prop-types';
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

/** img */
import home from "../resources/homepage/Home.svg";
import hicon1 from "../resources/homepage/h-01.svg";
import hicon2 from "../resources/homepage/h-02-02.svg";
import hicon4 from "../resources/homepage/h-04-02.svg";
import hicon6 from "../resources/homepage/h-06.svg";
import close from "../resources/admin/icon/close.svg";
/** img */
/** CSS */
import '../css/common.css';
import '../css/pc-common.css';
import '../css/contents.css';

/** CSS */

class Screen extends React.PureComponent {

	// noinspection NonAsciiCharacters
	items = {
		공공기관: {
			category: '공공기관', title: '공공기관', subtitle: '기관장, 학교장', image: hicon1,
			content: <>
				<p>
					지난 2021.1.26. 중대재해처벌 등에 관한 법률안이 공포되었습니다.<br />
					동법은 중대산업재해 및 중대시민재해가 발생 되었을 시 사업주와 경 영책임자 등에 대한 처벌, 행정적 제재, 징벌적 손해배상책임 등에 관 한 내용을 규정함으로써, 중대재해를 예방하고 근로자 등의 생명 보호 를 목적으로 제정되었으며 그 처벌이 엄정 합니다.<br />
					(*사망자 발생 중대재해 시 1년 이상의 징역 또는 10억원 이하의 벌금, 사망자 발생 이외의 중대재해 시 7년 이하의 징역 또는 1억원 이하의 벌금, 5년 이내에 다시 같은 죄를 범한 경우 2분의 1까지 가중, 법인에 대한 벌금형과 징벌적 손해배상 별도)
				</p>

				<p>
					재해예방에 필요한 인력 및 예산으로 안전보건관리체계를 구축하고 그 이행에 관한 조치와 안전·보건 관계 법령에 따른 의무 관리 조치 역 시 취하였음에도 발생된 중대재해에 대하여는 그 처벌의 정도가 달라 질 수밖에 없다는 사실에 착안하여,
				</p>

				<p>
					1. 원격 자문, 안전 경영활동 촉진<br />
					2. 활동내용 수집·가공·편집<br />
					3. 재직기간 동안 저장<br />
					4. 유사시 노무사, 변호사 초동단계 공동 지원, 거증자료 활용<br />
					5. 궁극적 무사고 경영<br />
				</p>

				<p>
					위 다섯 가지를 가능토록 하는 방안을 마련하였습니다.
				</p>

				<p>
					노동부 근로감독관 재직당시(前 서기관) 관할 사업장에서 중대재해가 발생하면 사업장 전체 정상업무가 정지되고, 책임자는 대책방안도 없이 노동부와 경찰서로 동분서주하다가 제대로 된 거증자료를 제출하지 못한 채 처벌 받는 모습을 목격해 왔습니다.<br />
					그저 입만으로 “저는 잘못이 없습니다.”라고 항변하지만 증거 없는 안전활동은 인정해 주지 않습니다.
				</p>

				<p>
					반면 지속적으로 스마트안전의 포인트체크식 안전관리를 받아 둔 것은 예산과 인력을 투입하여 안전관리체계를 구축하고 법정 의무를 충실히 이행하였다는 명백한 거증자료가 되어, 부득이 안전사고가 발생 할 경 우 개인적으로 불명예스러운 징계 또는 민사소송 등에서의 억울한 처 벌을 최소화 할 수 있을 것입니다.
				</p>

				<p>
					공공시설의 병원장 및 시설장의 관리 책임하의 직원과 입원시민, 출입 시민 모두의 안전을 누군가 관리를 해 줘야 할 것이나, 일반 기업과 달리 전문 안전관리자가 없는 병원 등 시설에서는 실현이 불가능한 상 태임을 감안하여 스마트안전이 안전경영이력관리시스템(30년 이상 경력자가 전담)을 통하여 안전담당자가 되고 안전지도자가 되어 적극 컨설팅 지원해 드리겠습니다.
				</p>
			</>,
		},
		제조업: {
			category: '제조업', title: '제조업', subtitle: '기업체 사업주', image: hicon2,
			content: <>
				<p>
					중대재해처벌 등에 관한 법률(이하 “중재법”으로 표기)이 공포되었고 2022.1.27.부터는 동 법안이 발효되어지면서 과거 산업안전보건법 하의 벌금형 등 부드러운 처벌은 아득한 희망사항이 되어 버렸습니다.<br />
					또한 최근(2021.1.12.자) 대법원 양형위원회에서는 산안법 체계 하의 양형 권고내용을 기존 10개월~3년 6개월 수준에서 2년~5년(가중인 경 우)으로 상향 조정한 바 있습니다.
				</p>

				<p>
					안전환경이 이렇게 변화되고 있음에도 불구하고 제조등서비스분야 현 장에서는 80%이상 기업체가 경제적 이유 등을 들어 대책 없이 정부의 시혜적 조정만을 바라고 있는 실정입니다.<br />
					실제 현장에서 중대재해가 발생되면 회사는 사건 정리와 공장 재개를 위해 동분서주하다가 제대 로 된 소명이나 거증자료도 제출하지 못하고 얼떨결에 법의 판단을 받 게 되는 경우가 많습니다.
				</p>

				<p>이젠 회사대표 자신을 위하여 안전관리를 하셔야 합니다. 피치 못할 경우를 대비하여 안전활동 자료도 수집-편집-저장 및 관리하고 적극적 으로 제출하여 소명을 하여야만 살아남아 회사를 장기적으로 경영해 나갈 수 있습니다.</p>

				<p>이젠 자신을 위하여 안전관리를 하셔야 합니다. 피치 못할 경우를 대비하여 안전활동 자료도 수집-편집-저장 및 관리하고 적극적으로 제출하여 소명을 하여야만 살아남을 수 있습니다.</p>

				<p>
					그리고 최근 유행하는 팬데믹에도 흔들리지 않는 안전시스템을 정착시 킬 필요가 있습니다.<br />
					난제들이 전진을 가로막는 이때, 대표님은 새로운 경영모멘텀을 찾고 그러한 경영을 지속시킬 시스템을 정착시켜야 할 것인데, 그중 하나가 스마트안전의 안전경영이력관리시스템(30년 이상 경력자가 전담)입니다.<br />
					안전경영이력관리시스템을 활용하여, 평소엔 체 크포인트 방식의 안전관리를 받으며 그 자료를 시스템에 영구 저장하 여 경영생애동안 관리하고, 만약 불가피한 재해가 발생하면 중재법상 “재해예방에 필요한 인력 및 예산 등으로 안전보건관리체계를 구축하 고, 그 이행에 관한 조치를 취하였으며 안전·보건 관계 법령에 따른 의무이행에 필요한 관리상의 조치도 행하였음”에도 발생된 중대재해 라는 사실을 스마트안전 클라우드에 보관된 자료로 증명하여 불이익을 최소화하게 됩니다.
				</p>

				<p>회사의 안전에 관한 내용은 저희 안전멘토이자 총무인 스마트안전에 맡기고 오로지 경영에 매진하시기 바랍니다.</p>
			</>,
		},
		건설업: {
			category: '건설업', title: '건설업', subtitle: '전문건설, 종합건설대표, 현장소장', image: hicon4,
			content: <>
				<p>
					중대재해처벌 등에 관한 법률(이하 “중재법”으로 표기)이 공포되었고 2022.1.27.부터는 동 법안이 발효되어지면서 과거 산업안전보건법 하의 벌금형 등 부드러운 처벌은 아득한 희망사항이 되어 버렸습니다.<br />
					또한 최근(2021.1.12.자) 대법원 양형위원회에서는 산안법 체계 하의 양형 권고내용을 기존 10개월~3년 6개월 수준에서 2년~5년(가중인 경 우)으로 상향 조정한 바 있습니다
				</p>

				<p>
					안전환경이 이렇게 변화되고 있음에도 불구하고 제조등서비스분야 현 장에서는 80%이상 기업체가 경제적 이유 등을 들어 대책 없이 정부의 시혜적 조정만을 바라고 있는 실정입니다.<br />
					실제 현장에서 중대재해가 발생되면 회사는 사건 정리와 공장 재개를 위해 동분서주하다가 제대 로 된 소명이나 거증자료도 제출하지 못하고 얼떨결에 법의 판단을 받 게 되는 경우가 많습니다.
				</p>

				<p>이젠 회사대표 자신을 위하여 안전관리를 하셔야 합니다. 피치 못할 경우를 대비하여 안전활동 자료도 수집-편집-저장 및 관리하고 적극적 으로 제출하여 소명을 하여야만 살아남아 회사를 장기적으로 경영해 나갈 수 있습니다.</p>

				<p>이젠 자신을 위하여 안전관리를 하셔야 합니다. 피치 못할 경우를 대비하여 안전활동 자료도 수집-편집-저장 및 관리하고 적극적으로 제출하여 소명을 하여야만 살아남을 수 있습니다.</p>

				<p>그리고 최근 유행하는 팬데믹에도 흔들리지 않는 안전시스템을 정착시 킬 필요가 있습니다. 난제들이 전진을 가로막는 이때, 대표님은 새로운 경영모멘텀을 찾고 그러한 경영을 지속시킬 시스템을 정착시켜야 할 것인데, 그중 하나가 스마트안전의 안전경영이력관리시스템(30년 이상 경력자가 전담)입니다.</p>

				<p>안전경영이력관리시스템을 활용하여, 평소엔 체 크포인트 방식의 안전관리를 받으며 그 자료를 시스템에 영구 저장하 여 경영생애동안 관리하고, 만약 불가피한 재해가 발생하면 중재법상 “재해예방에 필요한 인력 및 예산 등으로 안전보건관리체계를 구축하 고, 그 이행에 관한 조치를 취하였으며 안전·보건 관계 법령에 따른 의무이행에 필요한 관리상의 조치도 행하였음”에도 발생된 중대재해 라는 사실을 스마트안전 클라우드에 보관된 자료로 증명하여 불이익을 최소화하게 됩니다.</p>

				<p>회사의 안전에 관한 내용은 저희 안전멘토이자 총무인 스마트안전에 맡기고 오로지 경영에 매진하시기 바랍니다.</p>
			</>,
		},
		기타: {
			category: '기타', title: '기타', subtitle: '병원 등 공공시설 사업주', image: hicon6,
			content: <>
				<p>
					지난 2021.1.26. 중대재해처벌 등에 관한 법률안이 공포되었습니다.<br />
					동법은 중대산업재해 및 중대시민재해가 발생 되었을 시 사업주와 경 영책임자 등에 대한 처벌, 행정적 제재, 징벌적 손해배상책임 등에 관 한 내용을 규정함으로써, 중대재해를 예방하고 근로자 등의 생명 보호 를 목적으로 제정되었으며 그 처벌이 엄정 합니다.<br />
					(*사망자 발생 중대재해 시 1년 이상의 징역 또는 10억원 이하의 벌금, 사망자 발생 이외의 중대재해 시 7년 이하의 징역 또는 1억원 이하의 벌금, 5년 이내에 다시 같은 죄를 범한 경우 2분의 1까지 가중, 법인에 대한 벌금형과 징벌적 손해배상 별도)
				</p>

				<p>
					재해예방에 필요한 인력 및 예산으로 안전보건관리체계를 구축하고 그 이행에 관한 조치와 안전·보건 관계 법령에 따른 의무 관리 조치 역 시 취하였음에도 발생된 중대재해에 대하여는 그 처벌의 정도가 달라 질 수밖에 없다는 사실에 착안하여,
				</p>

				<p>
					1. 원격 자문, 안전 경영활동 촉진<br />
					2. 활동내용 수집·가공·편집<br />
					3. 재직기간 동안 저장<br />
					4. 유사시 노무사, 변호사 초동단계 공동 지원, 거증자료 활용<br />
					5. 궁극적 무사고 경영<br />
				</p>

				<p>
					위 다섯 가지를 가능토록 하는 방안을 마련하였습니다.
				</p>

				<p>
					노동부 근로감독관 재직당시(前 서기관) 관할 사업장에서 중대재해가 발생하면 사업장 전체 정상업무가 정지되고, 책임자는 대책방안도 없이 노동부와 경찰서로 동분서주하다가 제대로 된 거증자료를 제출하지 못한 채 처벌 받는 모습을 목격해 왔습니다.<br />
					그저 입만으로 “저는 잘못이 없습니다.”라고 항변하지만 증거 없는 안전활동은 인정해 주지 않습니다.
				</p>

				<p>
					반면 지속적으로 스마트안전의 포인트체크식 안전관리를 받아 둔 것은 예산과 인력을 투입하여 안전관리체계를 구축하고 법정 의무를 충실히 이행하였다는 명백한 거증자료가 되어, 부득이 안전사고가 발생 할 경 우 개인적으로 불명예스러운 징계 또는 민사소송 등에서의 억울한 처 벌을 최소화 할 수 있을 것입니다.
				</p>

				<p>
					공공시설의 병원장 및 시설장의 관리 책임하의 직원과 입원시민, 출입 시민 모두의 안전을 누군가 관리를 해 줘야 할 것이나, 일반 기업과 달리 전문 안전관리자가 없는 병원 등 시설에서는 실현이 불가능한 상 태임을 감안하여 스마트안전이 안전경영이력관리시스템(30년 이상 경력자가 전담)을 통하여 안전담당자가 되고 안전지도자가 되어 적극 컨설팅 지원해 드리겠습니다.
				</p>
			</>,
		},
	};

	state = {
		selected_item: null,
	};

	onClickItem = (item) => {
		this.setState({selected_item: item});
	};

	renderItem = (item) => (
		<li onClick={() => this.onClickItem(item)}>
			<img src={item.image} alt={item.title} />
			<div>
				<strong>{item.title}</strong>
				<p>{item.subtitle}</p>
			</div>
			<a>+</a>
		</li>
	);

	render() {
		return (
			<div id="wrap">
				<HeaderComponent/>


				<div id="top-menu">
					<div className="section-in history-in pc-long">
						<Fade bottom><h1 className="top-title">법률에 따른 안전경영관리<br /><strong>이력관리시스템</strong></h1></Fade>
						<div className="c-txt">
							<p>현업에서의 풍부한 경험과 노하우 및 사례를 바탕으로 산업재해로 인한 근로자의 귀중한 생명보호와
								공공기관 안전담당자 및 기업 내 사업주, 안전관리자의 안전관리 근로생애를 관리해 드리겠습니다.</p>
						</div>
						<div className="clear"></div>
						<div className="location">
							<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
							<strong>이력관리시스템</strong>
						</div>
					</div>
				</div>


				<div id="sub-container" className="other">
					<div className="section-in history-in">

						<div className="history-kinds">
							<ul>
								{this.renderItem(this.items.공공기관)}
								{this.renderItem(this.items.제조업)}
							</ul>
						</div>

						<div className="history-kinds">
							<ul>
								{this.renderItem(this.items.건설업)}
								{this.renderItem(this.items.기타)}
							</ul>
						</div>

					</div>
				</div>

				{/* 공공기관 modal */}
				<Modal item={this.state.selected_item} onClose={() => this.setState({selected_item: null})}/>

				<FooterComponent/>
			</div>
		);
	}
}

class Modal extends React.PureComponent {

	onClickClose = () => {
		typeof this.props.onClose === 'function' && this.props.onClose(this.props.item);
	};

	render() {
		const item = this.props.item;
		if (!item) return null;

		return (
			<div id="basic-modal">
				<div>
					<Zoom clear delay={600} duration={1000}>
						<div className="modal pc-modal">

							<div id="modal-close" onClick={this.onClickClose}>
								<img src={close} width="16px;" alt="닫기" />
							</div>

							<div className="pc-m-title"><img src={item.image} alt={item.title} /> <h1>{item.title}</h1></div>

							<div className="pc-modal-in">
								{item.content}
							</div>

							<div className="btn-wrap">
								<button id="modal-btn-ok" className="modal-btn btn-cancel" onClick={this.onClickClose}>닫기</button>
							</div>

						</div>
					</Zoom>
				</div>
			</div>

		);
	}
}

Modal.propTypes = {
	item: PropTypes.object.isRequired,
	onClose: PropTypes.func,
};

export default Screen;
