import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';

import CompanyListItemComponent from "../../../../components/admin/company/CompanyListItemComponent";
import AnimatedNumber from "animated-number-react";
import CompanyEditModal from "../../../../modals/admin/CompanyEditModal";
import ListLoadingComponent from "../../../../components/admin/common/ListLoadingComponent";
import {Company} from "../../../../models/Company";


class Screen extends React.PureComponent {

	isLockedFetch = false;

	state = {
		isFetching: true,
		query: '',
		companies: [],
		selected_item: null,
		company_count: 0,
	};

	componentDidMount() {
		this.fetch();

		Company.fetchCount().then((count) => this.setState({count}));
	}

	fetch = () => {
		if (this.isLockedFetch) return;

		this.isLockedFetch = true;
		this.setState({isFetching: true}, () => {
			Company.fetchCompanies({ob: 'created_at', od: 'DESC', query: this.state.query, join_user: true, join_manager: true, join_business_type: true, join_agent: true}).then((companies) => {
				this.setState({companies, isFetching: false}, () => {
					this.isLockedFetch = false;
				});
			});
		});
	};

	onChangeQuery = (e) => {
		this.setState({query: e.currentTarget.value}, () => {
			this.fetch();
		});
	};

	onClickEdit = (item) => {
		this.setState({selected_item: item});
	};

	onCloseModal = () => {
		this.setState({selected_item: null});
	};

	renderCompanies = () => {
		const companies = this.state.companies;

		return companies.map((company, index) => (
			<CompanyListItemComponent key={index} company={company} onClickEdit={this.onClickEdit}/>
		));
	}

	render() {
		return (
			<ContainerComponent>
					<div id="ad-contents">
						<h1 className="contents-title">업체 목록</h1>
						<div className="contents-in">
							<div className="schfrm">
								<div className="left">
									총 <strong><AnimatedNumber
									value={this.state.count}
									formatValue={(value) => value.toFixed(0)}/>건</strong>
								</div>
								<div className="right">
									<div className="search-box">
										<input type="text" id="ad-search" placeholder="검색어를 입력해주세요" className="search" value={this.state.query} onChange={this.onChangeQuery}/>
										<div className="search-btn"/>
									</div>
								</div>
							</div>
							<div className="table-wrap">
								<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
									{/*<colgroup>
										<col width="5%;"/>
										<col width="5%;"/>
										<col width="15%;"/>
										<col width="14%;"/>
										<col width="14%;"/>
										<col width="18%;"/>
										<col width="12%;"/>
										<col width="12%;"/>
										<col width="10%;"/>
									</colgroup>*/}
									<thead>
									<tr>
										<th scope="col">고유번호</th>
										<th scope="col">아이디</th>
										<th scope="col">업체명</th>
										<th scope="col">담당자 연락처</th>
										<th scope="col">업종</th>
										<th scope="col">생성일</th>
										<th scope="col">안전 담당자</th>
										{/*<th scope="col">결제 관리</th>*/}
										<th scope="col" className="editTit">상세 정보</th>
									</tr>
									</thead>

									<tbody>
									 	{this.renderCompanies()}
									 </tbody>

									<ListLoadingComponent visible={this.state.isFetching}/>
								</table>
							</div>

							{/* 안전담당자 설정 클릭시 modal 노출  */}
							{this.state.selected_item &&
							<CompanyEditModal company={this.state.selected_item} onClose={this.onCloseModal}/>}
						</div>
					</div>
			</ContainerComponent>
		);
	}
}

export default Screen;
