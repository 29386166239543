import React from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "../components/common/HeaderComponent";
import MainFooterComponent from "../components/common/MainFooterComponent";
import "aos/dist/aos.css";
import AOS from "aos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

/** CSS */
import "../css/common.css";
import "../css/pc-common.css";
import "../css/index.css";

/*img*/
import phcover from "../resources/homepage/ph-cover.png";
import sd1 from "../resources/homepage/sd1.png";
import sd2 from "../resources/homepage/sd2.png";
import sd3 from "../resources/homepage/sd3.png";
import shadow from "../resources/homepage/ph-shadow.png";
import back from "../resources/homepage/ph-back.png";
import google from "../resources/homepage/google.svg";
import apple from "../resources/homepage/apple.svg";
import sicon1 from "../resources/homepage/sicon1.svg";
import sicon2 from "../resources/homepage/sicon2.svg";
import sicon3 from "../resources/homepage/sicon3.svg";
import bubble1 from "../resources/homepage/bubble1.png";
import bubble2 from "../resources/homepage/bubble2_210526.png";
import bubble3 from "../resources/homepage/bubble3.png";
import greenarrow from "../resources/homepage/right-green.svg";
import s4p1 from "../resources/homepage/s4-phone1.png";
import s4p2 from "../resources/homepage/s4-phone2.png";
import blog from "../resources/homepage/blog.svg";

class Screen extends React.PureComponent {
  render() {
    AOS.init({
      duration: 1000,
    });

    const settings = {
      dots: false,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1400,
      cssEase: "ease-in-out",
      pauseOnHover: true,
    };

    return (
      <div id="wrap">
        {/* header */}
        <HeaderComponent />

        <div id="section-1">
          <div className="main-bg"></div>
          <div className="section-in">
            <h1 className="m-title" data-aos="fade-up" data-aos-offset="200">
              <strong>
                코로나와 중대재해기업처벌법 시대에 부응한
                <br />
                비대면 선제 안전 컨설팅
              </strong>
            </h1>
            <h6 className="s-title" data-aos="fade-up" data-aos-delay="500">
              온라인 안전 컨설팅
              <br />
              믿고 맡길 수 있는 안전 전문가에게 받아보세요
            </h6>
            <div className="download" data-aos="fade-up" data-aos-delay="1000">
              <a
                href={
                  "https://play.google.com/store/apps/details?id=kr.smart_safety.app"
                }
                target={"_blank"}
              >
                <img src={google} width="100%" alt="구글 다운로드" />
              </a>
              {/*<Link to={''}><img src={apple} width="100%" alt="애플 다운로드" /></Link>*/}
            </div>
          </div>
          <div
            className="big-circle"
            data-aos="zoom-in"
            data-aos-delay="1500"
          ></div>
          <div className="m-phone" data-aos="fade-up" data-aos-delay="2000">
            {/*<img src={phone} width="100%" alt="스마트안전 앱 메인화면" />*/}
            <img
              src={phcover}
              width="100%"
              alt="스마트안전 앱 메인화면"
              style={{ position: "relative", zIndex: "9" }}
            />
            <div className="slide-inner">
              <Slider {...settings} className="about">
                <div className="slide__one">
                  <img src={sd1} width="100%" alt="스마트안전 앱 메인화면" />
                </div>
                <div className="slide__two">
                  <img src={sd2} width="100%" alt="스마트안전 앱 메인화면" />
                </div>
                <div className="slide__three">
                  <img src={sd3} width="100%" alt="스마트안전 앱 메인화면" />
                </div>
              </Slider>
            </div>
            <img
              src={back}
              width="100%"
              alt="스마트폰 그림자"
              className="ph-back"
            />
            <img
              src={shadow}
              className="shadow"
              width="100%"
              alt="스마트폰 그림자"
            />
          </div>
        </div>

        <div id="section-2">
          <div className="section-in">
            <h1
              className="m-title"
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-delay="500"
            >
              {/*보다 간편하고 스마트하게<br />*/}
              양방향, 즉답식 안전관리가 가능한
              <br />
              <strong>스마트안전</strong>
            </h1>
            <h6 className="s-title" data-aos="fade-up" data-aos-delay="800">
              ‘스마트안전’은 사업장 안전관리 솔루션으로써 스마트폰을 이용하여
              <br />
              손쉽게 사업장의 안전을 관리하는 모바일 서비스입니다
            </h6>
            <h6
              className="s-title mobile"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              ‘스마트안전’은 사업장 안전관리 솔루션으로써 스마트폰을 이용하여
              손쉽게 사업장의 안전을 관리하는 모바일 서비스입니다
            </h6>
            <ul className="icon-list">
              <li data-aos="fade-up" data-aos-delay="1000">
                <img src={sicon1} width="58px" alt="안전관리현황" />
                <span>안전관리현황</span>
              </li>
              <li data-aos="fade-up" data-aos-delay="1100">
                <img src={sicon2} width="45px" alt="안전관리현황" />
                <span>모니터링</span>
              </li>
              <li data-aos="fade-up" data-aos-delay="1200">
                <img src={sicon3} width="45px" alt="안전관리현황" />
                <span>이력관리</span>
              </li>
            </ul>
          </div>
        </div>

        <div id="section-3">
          <div className="section-in">
            <div className="left">
              <h1 className="m-title" data-aos="fade-up" data-aos-offset="200">
                법정안전경영관리
                <br />
                <strong>이력관리시스템</strong>
              </h1>
              <h6 className="s-title" data-aos="fade-up" data-aos-delay="500">
                풍부한 경험과 노하우를 바탕으로 산업재해로 인한
                <br />
                근로자의 귀중한 생명보호는 물론 공공기관 안전담당자,
                <br />
                기업 내 사업주 및 안전관리자의 ‘안전관리 근로생애’를
                관리해드립니다
              </h6>
              <h6
                className="s-title mobile"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                현업에서의 풍부한 경험과 노하우 및 사례를 바탕으로 산업재해로
                인한 근로자의 귀중한 생명보호와 공공기관 안전담당자 및 기업 내
                사업주, 안전관리자의 안전관리 근로생애를 관리해 드립니다
              </h6>
              <Link
                to={"/history-mgmt-system"}
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos-offset="200"
              >
                이력관리시스템 자세히보기{" "}
                <img src={greenarrow} width="8px" alt="바로가기" />
              </Link>
            </div>
            <ul className="right">
              <li data-aos="fade-up" data-aos-delay="1200">
                <img
                  src={bubble1}
                  width="474px"
                  alt="중대재해처벌 등에 관한 법률이 공포되었던데 어떻게 대비할 수 있을까요?"
                />
                <span>
                  중대재해처벌 등에 관한 법률이
                  <br />
                  공포되었던데 어떻게 대비할 수 있을까요?
                </span>
                <span className="mobile">
                  중대재해처벌 등에 관한 법률이 공포되었던데 어떻게 대비할 수
                  있을까요?
                </span>
              </li>
              <li data-aos="fade-up" data-aos-delay="1300">
                <img
                  src={bubble2}
                  width="523px"
                  alt="스마트안전의 안전활동이력관리시스템을 활용해  안전활동 자료를 수집˙편집˙저장 및 관리하여 피치 못할 경우를 대비하실 수 있습니다 :) "
                />
                <span>
                  스마트안전의 안전활동이력관리시스템을
                  <br />
                  활용해 평상의 안전활동 자료를 수집·편집·저장
                  <br />및 관리한 것을 불시의 안전사고 발생 시<br />
                  거증자료로 활용하실 수 있습니다 :){" "}
                </span>
                <span className="mobile">
                  스마트안전의 안전 활동 이력관리시스템을 활용해 안전활동 자료를
                  수집˙편집˙저장 및 관리하여 피치 못할 경우를 대비하실 수
                  있습니다 :){" "}
                </span>
              </li>
              <li data-aos="fade-up" data-aos-delay="1400">
                <img
                  src={bubble3}
                  width="406px"
                  alt="그런 간편하고 좋은 서비스가 있는 줄 몰랐어요! 감사합니다!!"
                />
                <span>
                  그런 간편하고 좋은 서비스가
                  <br />
                  있는 줄 몰랐어요! 감사합니다!!
                </span>
                <span className="mobile">
                  그런 간편하고 좋은 서비스가있는 줄 몰랐어요! 감사합니다!!
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div id="section-4">
          <div className="section-in">
            <h1 className="m-title" data-aos="fade-up" data-aos-offset="200">
              믿고 맡길 수 있는
              <br />
              <strong>안전관리 전문가</strong>로 구성
            </h1>
            <div className="pro-wrap">
              <div data-aos="fade-up" data-aos-delay="500">
                <img src={s4p1} width="100%" alt="" />
              </div>
              <div data-aos="fade-up" data-aos-delay="1000">
                <img src={s4p2} width="100%" alt="" />
              </div>
            </div>
            <div className="m-btn" data-aos="fade-up" data-aos-delay="1100">
              <Link to={"/service?tab=safety"}>
                안전관리 전문기관{" "}
                <img src={greenarrow} width="9px" alt="바로가기" />
              </Link>
              <Link to={"/service?tab=disaster"}>
                건설재해예방 전문기관{" "}
                <img src={greenarrow} width="9px" alt="바로가기" />
              </Link>
            </div>
          </div>
        </div>

        <div id="section-5">
          <div className="section-in">
            <div className="hp-left">
              <h1 className="m-title" data-aos="fade-up">
                특히 회원 가입이 필요한 경우
              </h1>
              <ul data-aos="fade-up" data-aos-offset="200" className="hp-list">
                <li>
                  <span>1</span>{" "}
                  <div>
                    최근 5년 이내 중대재해발생 사업장· 건설현장의 대표자나 소장
                    등 관련자
                  </div>
                </li>
                <li>
                  <span>2</span>{" "}
                  <div>
                    최근 5년 이내 재해다발사업장·건설현장의 대표자나 소장 등
                    관련자
                  </div>
                </li>
                <li>
                  <span>3</span>{" "}
                  <div>안전관련 조직이 미비한 전문건설회사 대표 및 관련자</div>
                </li>
                <li>
                  <span>4</span>{" "}
                  <div>
                    안전의 사각지대인 50인 미만 제조업체(서비스업 포함) 대표자
                    및 관련자
                  </div>
                </li>
                <li>
                  <span>5</span>{" "}
                  <div>
                    관할 인원과 지역이 넓고 신분이 공무원이라 불안한 학교장 및
                    기관장
                  </div>
                </li>
                <li>
                  <span>6</span>{" "}
                  <div>
                    1월에 2회의 안전관리위탁으론 불안한 300인 미만 사업장 대표
                    및 책임자
                  </div>
                </li>
                <li>
                  <span>7</span>{" "}
                  <div>
                    안전 담당자(관리자)로 선임되어 자문 받을 대상이 없어 힘든
                    경우
                  </div>
                </li>
                <li>
                  <span>8</span>{" "}
                  <div>
                    기타 개인적으로 평생안전관리이력이 필요하거나 보완 조치가
                    필요한 경우
                  </div>
                </li>
              </ul>
            </div>
            <div className="hp-right">
              <h1 className="m-title" data-aos="fade-up">
                스마트안전 시스템의 특장점
              </h1>
              <ul data-aos="fade-up" data-aos-offset="200" className="hp-list">
                <li>
                  <span>1</span>{" "}
                  <div>중대재해 처벌 등에 관한 법률에 선제적인 대응책</div>
                </li>
                <li>
                  <span>2</span>{" "}
                  <div>
                    코로나 시대의 비대면, 양방향, 족집게, 즉답식 안전 관리
                  </div>
                </li>
                <li>
                  <span>3</span>{" "}
                  <div>
                    경영책임자 등 개인 위주의 법적 처벌에 대한 풍부한 거증
                    자료로 활용
                  </div>
                </li>
                <li>
                  <span>4</span>{" "}
                  <div>
                    기관장이나 학교장, 현장소장 등은 억울한 징계를 면하는 자료로
                    활용
                  </div>
                </li>
                <li>
                  <span>5</span> <div>개인 위주의 자료 평생관리</div>
                </li>
                <li>
                  <span>6</span>{" "}
                  <div>
                    비전문가 출신 안전담당자의 담당업무를 항시 지원 가능
                  </div>
                </li>
                <li>
                  <span>7</span>{" "}
                  <div>노동부 등 각종 감독, 점검에 대한 사전 대안</div>
                </li>
                <li>
                  <span>8</span>{" "}
                  <div>
                    담당자나 경영책임자의 안전마인드 형성부터 완벽한 제도
                    형성까지 안내
                  </div>
                </li>
                <li>
                  <span>9</span> <div>안전과 간단한 노무를 한 곳에서 정리</div>
                </li>
                <li>
                  <span>10</span>{" "}
                  <div>
                    긴급 상황이나 재해 발생 시 초등단계에서 보관 중인 자료를
                    이용, 요청 시 노무사나 변호사 개입
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/*<div id="section-5">
					<div className="section-in">
						<div className="left" data-aos="fade-up">
							<h1 className="m-title">
								스마트안전을 믿고 맡겨주신<br />
								<strong>다양한 고객사</strong>
							</h1>
							<h6 className="s-title" data-aos="fade-up" data-aos-delay="500">
								전문적인 지식을 바탕으로 간편하고 스마트한<br />
								체계적인 관리를 실시간으로 받아보실 수 있습니다
							</h6>
							<h6 className="s-title mobile" data-aos="fade-up" data-aos-delay="500">
								전문적인 지식을 바탕으로 간편하고 스마트한 체계적인 관리를 실시간으로 받아보실 수 있습니다
							</h6>
						</div>
						<ul className="right">
							<li data-aos="flip-left" data-aos-delay="1000" data-aos-easing="ease-in-out">
								<img src={cs1} className="cs-img" width="61px" alt="르노삼성자동차" />
								<img src={cs1on} className="cs-imgon" width="61px" alt="르노삼성자동차" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1100" data-aos-easing="ease-in-out">
								<img src={cs2} className="cs-img" width="179px" alt="코오롱글로벌(주)" />
								<img src={cs2on} className="cs-imgon" width="179px" alt="코오롱글로벌(주)" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1200" data-aos-easing="ease-in-out">
								<img src={cs3} className="cs-img" width="82px" alt="포스코건설" />
								<img src={cs3on} className="cs-imgon" width="82px" alt="포스코건설" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1300" data-aos-easing="ease-in-out">
								<img src={cs4} className="cs-img" width="130px" alt="SNT AMT" />
								<img src={cs4on} className="cs-imgon" width="130px" alt="SNT AMT" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1400" data-aos-easing="ease-in-out">
								<img src={cs5} className="cs-img" width="120px" alt="한마음병원" />
								<img src={cs5on} className="cs-imgon" width="120px" alt="한마음병원" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1500" data-aos-easing="ease-in-out">
								<img src={cs6} className="cs-img" width="76px" alt="밀양" />
								<img src={cs6on} className="cs-imgon" width="76px" alt="밀양" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1600" data-aos-easing="ease-in-out">
								<img src={cs7} className="cs-img" width="94px" alt="강서" />
								<img src={cs7on} className="cs-imgon" width="94px" alt="강서" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1700" data-aos-easing="ease-in-out">
								<img src={cs8} className="cs-img" width="102px" alt="온종합병원" />
								<img src={cs8on} className="cs-imgon" width="102px" alt="온종합병원" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1800" data-aos-easing="ease-in-out">
								<img src={cs9} className="cs-img" width="64px" alt="수영" />
								<img src={cs9on} className="cs-imgon" width="64px" alt="수영" />
							</li>
						</ul>
						<ul className="right mobile">
							<li data-aos="flip-left" data-aos-delay="1000" data-aos-easing="ease-in-out">
								<img src={cs1} className="cs-img" width="41px" alt="르노삼성자동차" />
								<img src={cs1on} className="cs-imgon" width="41px" alt="르노삼성자동차" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1100" data-aos-easing="ease-in-out">
								<img src={cs2} className="cs-img" width="159px" alt="코오롱글로벌(주)" />
								<img src={cs2on} className="cs-imgon" width="159px" alt="코오롱글로벌(주)" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1200" data-aos-easing="ease-in-out">
								<img src={cs3} className="cs-img" width="62px" alt="포스코건설" />
								<img src={cs3on} className="cs-imgon" width="62px" alt="포스코건설" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1300" data-aos-easing="ease-in-out">
								<img src={cs4} className="cs-img" width="110px" alt="SNT AMT" />
								<img src={cs4on} className="cs-imgon" width="110px" alt="SNT AMT" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1400" data-aos-easing="ease-in-out">
								<img src={cs5} className="cs-img" width="100px" alt="한마음병원" />
								<img src={cs5on} className="cs-imgon" width="100px" alt="한마음병원" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1500" data-aos-easing="ease-in-out">
								<img src={cs6} className="cs-img" width="56px" alt="밀양" />
								<img src={cs6on} className="cs-imgon" width="56px" alt="밀양" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1600" data-aos-easing="ease-in-out">
								<img src={cs7} className="cs-img" width="74px" alt="강서" />
								<img src={cs7on} className="cs-imgon" width="74px" alt="강서" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1700" data-aos-easing="ease-in-out">
								<img src={cs8} className="cs-img" width="82px" alt="온종합병원" />
								<img src={cs8on} className="cs-imgon" width="82px" alt="온종합병원" />
							</li>
							<li data-aos="flip-left" data-aos-delay="1800" data-aos-easing="ease-in-out">
								<img src={cs9} className="cs-img" width="44px" alt="수영" />
								<img src={cs9on} className="cs-imgon" width="44px" alt="수영" />
							</li>
						</ul>
					</div>
				</div>*/}

        <MainFooterComponent />
      </div>
    );
  }
}

export default Screen;
