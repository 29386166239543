import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {Company} from "../../../models/Company";
import moment from "moment";

export default class CompanyListItemComponent extends React.PureComponent {

	static propTypes = {
		company: PropTypes.instanceOf(Company).isRequired,
	};

	onClickEdit = () => {
		typeof this.props.onClickEdit === 'function' && this.props.onClickEdit(this.props.company);
	};

	render() {
		const business_type_url = this.props?.company?.business_type_uuid ? `/admin/business-type/${this.props?.company?.business_type_uuid}` : '#';
		const agent_url = this.props?.company?.agent_uuid ? `/admin/agent/${this.props.company.agent_uuid}` : '#';

		return (
			<tr>
				<td>{this.props?.company?.user?.id}</td>
				<td>{this.props?.company?.user?.username}</td>
				<td>{this.props?.company?.name}</td>
				<td>{this.props?.company?.manager?.contact_number}</td>
				<td><Link to={business_type_url}>{this.props?.company?.business_type?.name || '-'}</Link></td>
				<td>{this.props?.company?.created_at && moment.unix(this.props.company.created_at).format('YYYY-MM-DD')}</td>
				<td><Link to={agent_url}>{this.props?.company?.agent?.name}</Link></td>
				{/*<td><Link to={`/admin/company/${this.props?.company?.user_uuid}/payment-history`} className={'payment'}>결제 내역</Link></td>*/}
				<td className="edit"><Link to={`/admin/company/${this.props.company.user_uuid}`}><button>보기</button></Link></td>
			</tr>
		);
	}
}

CompanyListItemComponent.propTypes = {
	company: PropTypes.object.isRequired,
	onClickEdit: PropTypes.func,
};