import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import { HashLink as Link } from 'react-router-hash-link';

/** img */
import home from "../../resources/homepage/Home.svg";
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {
	render() {
		return (
			<div id="wrap" className="sv-long-in">

					{/* header */}
					<HeaderComponent/>

					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title"><strong>건설업 유해위험방지계획서 작성 컨설팅</strong></h1>
							<div className="location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<Link to={'/service'}><strong>제공서비스</strong></Link>
								<Link to={'/service?tab=disaster'}><strong>건설재해예방 전문기관</strong></Link>
								<strong>건설업 유해위험방지계획서 작성 컨설팅</strong>
							</div>
						</div>
					</div>
					<div id="sub-container">
						<div className="section-in sv-in">
							<div className="sv-nav">
							</div>
							<div className="sv-contents" style={{width:'100%'}}>
								{/* 건설업 유해위험방지계획서 작성 컨설팅 */}
								<div id="sv1">
									<h6>1. 건설업 유해위험방지계획서 작성 컨설팅</h6>
									<p>
										<strong>법적 근거</strong>
										• 산업안전보건법 제 42조에 의거 건설공사의 사전 안전성 심사를 통해 근원적인 안전성을 확보하고 근로자의 안전·보건을 확보하기 위한 제도<br />
										• 산업안전보건법 제 42조 제 1항 근거하여 건설업 중 노동부령이 정하는 규모의 사업을 착공하려고 하는 사업주는 고용부령이 정하는 자격을 갖춘 자의 의견을 들은 후 이 법 또는 이 법에 의한 명령에서 정하는 유해•위험 방지 계획서를 작성하여 노동부령이 정하는 바에 의하여 고용부 장관에게 제출하여야 함
									</p>
									<p>
										<strong>제출대상</strong>
										• 지상높이가 31미터 이상인 건축물 또는 공작물, 연면적 3만 제곱미터 이상인 건축물 또는 연면적 5천 제곱미터 이상의 문화 및 집회시설(전시장 및 동물원·식물원은 제외한다.)<br />
										• 판매시설, 운수시설(고속철도의 역사 및 집배송 시설은 제외한다.), 종교시설, 의료 시설 중 종합병원, 숙박시설 중 관광숙박시설 또는 지하도상가의 건설·개조 또는 해체(이하 “건설등”이라 한다.)<br />
										• 최대지간길이가 50미터 이상인 교량건설 등 공사<br />
										• 터널건설 등의 공사<br />
										• 다목적댐·발전용댐 및 저수용량 2천만톤 이사으이 용수전용댐•지방상수도 전용댐 건설 등의 공사<br />
										• 깊이 10미터 이상인 굴착 공사
									</p>
									<p>
										<strong>작성 및 기간</strong>
										• 유해·위험방지 계획서 작성대상공사를 착공하려고 하는 사업주는 [일정한 자격을 갖춘 자]의 의견을 들은 후 동 계획서를 작성하여 공사 착공 전일까지 공단에 2부를 제출<br />
										• 작성 기간은 7일~10일 정도 소요
									</p>
									<p>
										<strong>기대효과</strong>
										• 심사시 부적격 요소 감소<br />
										• 공사 착공 전 설계도, 안전조치계획 등 검토로 사전 안전성 확보
									</p>
								</div>

								<div className="sv-subBox">
									현업에서의 풍부한 경험 및 노하우를 바탕으로 산업재해로 인한 근로자의 귀중한 생명 보호와
									공공기관 안전담당자, 기업 내 사업주, 안전관리자의 안전관리 근로생애까지 관리해드리겠습니다.
								</div>

								<div className="sv-goback">
									<Link to={'/service?tab=disaster'}>
										<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
									</Link>
								</div>
							</div>

						</div>
					</div>

					{/* footer */}
					<FooterComponent/>

				</div>
		)
	}
}

export default Screen;
