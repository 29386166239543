import DashboardScreen from "../screens/admin/authorized/DashboardScreen";
import SignInScreen from "../screens/admin/unauthorized/SignInScreen";
import CompanyListScreen from "../screens/admin/authorized/company/CompanyListScreen";
import CompanyPaymentHistoryScreen from "../screens/admin/authorized/company/CompanyPaymentHistoryScreen";
import ReportResponseScreen from "../screens/admin/authorized/report/ReportResponseScreen";
import ReportScreen from "../screens/admin/authorized/report/ReportScreen";
import ReportListScreen from "../screens/admin/authorized/report/ReportListScreen";
import AgentListScreen from "../screens/admin/authorized/AgentListScreen";
import NoticeWriteScreen from "../screens/admin/authorized/notice/NoticeWriteScreen";
import NoticeScreen from "../screens/admin/authorized/notice/NoticeScreen";
import NoticeListScreen from "../screens/admin/authorized/notice/NoticeListScreen";
import SalesStatisticsScreen from "../screens/admin/authorized/SalesStatisticsScreen";
import QnAScreen from "../screens/admin/authorized/qna/QnAScreen";
import QuestionListScreen from "../screens/admin/authorized/qna/QuestionListScreen";

import CommunityWriteScreen from "../screens/admin/authorized/community/CommunityWriteScreen";
import CommunityScreen from "../screens/admin/authorized/community/CommunityScreen";
import CommunityListScreen from "../screens/admin/authorized/community/CommunityListScreen";

import React from "react";
import PermissionRoute from "./PermissionRoute";
import CompanyScreen from "../screens/admin/authorized/company/CompanyScreen";
import BusinessTypeScreen from "../screens/admin/authorized/business_type/BusinessTypeScreen";
import BusinessTypeListScreen from "../screens/admin/authorized/business_type/BusinessTypeListScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import {Route, Switch} from "react-router-dom";
import SignOutScreen from "../screens/admin/authorized/SignOutScreen";
import {TYPE_AGENT, TYPE_SUPERUSER} from "../constants/user_type";

const REDIRECT_SIGN_IN = '/admin/sign-in';
const REDIRECT_ADMIN = '/admin';

const authorizedOnly = {
	authorized: true,
	redirect: REDIRECT_SIGN_IN,
	allowType: [TYPE_SUPERUSER, TYPE_AGENT],
};

export default ({match}) => (
	<Switch>
		<PermissionRoute title={'스마트안전 | 대시보드'} exact path={match.url} component={DashboardScreen} {...authorizedOnly}/>

		<PermissionRoute title={'로그인'} path={`${match.url}/sign-in`} component={SignInScreen} unauthorized redirect={REDIRECT_ADMIN}/>
		<PermissionRoute title={'로그아웃'} path={`${match.url}/sign-out`} component={SignOutScreen} authorized redirect={REDIRECT_ADMIN}/>

		<PermissionRoute title={'업체 결제내역'} path={`${match.url}/company/:uuid/payment-history`} component={CompanyPaymentHistoryScreen} {...authorizedOnly}/>
		<PermissionRoute title={'업체 상세'} path={`${match.url}/company/:uuid`} component={CompanyScreen} {...authorizedOnly}/>
		<PermissionRoute title={'업체 목록'} path={`${match.url}/company`} component={CompanyListScreen} {...authorizedOnly}/>

		<PermissionRoute title={'업종'} path={`${match.url}/business-type/:uuid`} component={BusinessTypeScreen} {...authorizedOnly}/>
		<PermissionRoute title={'업종 목록'} path={`${match.url}/business-type`} component={BusinessTypeListScreen} {...authorizedOnly}/>

		<PermissionRoute title={'보고서 검수'} path={`${match.url}/report/:uuid/response`} component={ReportResponseScreen} {...authorizedOnly}/>
		<PermissionRoute title={'보고서 상세'} path={`${match.url}/report/:uuid`} component={ReportScreen} {...authorizedOnly}/>
		<PermissionRoute title={'보고서 목록'} path={`${match.url}/report`} component={ReportListScreen} {...authorizedOnly}/>

		<PermissionRoute title={'안전 담당자 목록'} path={`${match.url}/agent`} component={AgentListScreen} {...authorizedOnly}/>

		<PermissionRoute title={'공지사항 작성하기'} path={`${match.url}/notice/write`} component={NoticeWriteScreen} {...authorizedOnly}/>
		<PermissionRoute title={'공지사항'} path={`${match.url}/notice/:uuid`} component={NoticeScreen} {...authorizedOnly}/>
		<PermissionRoute title={'공지사항 목록'} path={`${match.url}/notice`} component={NoticeListScreen} {...authorizedOnly}/>

		<PermissionRoute title={'매출통계'} path={`${match.url}/sales/statistics`} component={SalesStatisticsScreen} {...authorizedOnly}/>

		<PermissionRoute title={'1:1 문의 상세'} path={`${match.url}/question/:uuid`} component={QnAScreen} {...authorizedOnly}/>
		<PermissionRoute title={'1:1 문의 목록'} path={`${match.url}/question`} component={QuestionListScreen} {...authorizedOnly}/>

		<PermissionRoute title={'안전하는사람들(사랑방) 작성하기'} path={`${match.url}/community/write`} component={CommunityWriteScreen} {...authorizedOnly}/>
		<PermissionRoute title={'안전하는사람들(사랑방)'} path={`${match.url}/community/:uuid`} component={CommunityScreen} {...authorizedOnly}/>
		<PermissionRoute title={'안전하는사람들(사랑방) 목록'} path={`${match.url}/community`} component={CommunityListScreen} {...authorizedOnly}/>


		<Route component={NotFoundScreen}/>
	</Switch>
);