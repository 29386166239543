import React from 'react';
import PropTypes from 'prop-types';
import {TabKey} from "../../screens/service/ServiceScreen";
import Sticky from 'react-sticky-el';
import {Link} from "react-router-dom";

/** img */
import logo from "../../resources/homepage/logo.svg";
/** img */
/** CSS */
import '../../css/common.css';
import '../../css/header.css';
import {JWTUtils} from "../../utils/JWTUtils";

/** CSS */

class HeaderComponent extends React.PureComponent {

	constructor(props){
		super(props);
		this.state={
			menuOpen:false,
		}
	}

	handleMenuClick() {
		this.setState({menuOpen:!this.state.menuOpen});
	}

	handleLinkClick() {
		this.setState({menuOpen: false});
	}

	render(){

		return(
			<Sticky className="sticky-wrap">
					<div id="header">
						<div className="header-in">
							<div className="logo"><Link to={'/'}><img src={logo} width="188px" alt="스마트안전 로고"/></Link></div>
							<ul className="gnb">
								<li>
									<Link to={'/service/smart-safety'}>스마트안전소개</Link>
									{/*<ul className="depth">
										<li><Link to={'/service/smart-safety'}>스마트안전 소개</Link></li>
										<li><Link to={'/customers'}>고객사</Link></li>
									</ul>*/}
								</li>
								<li>
									<Link to={'/history-mgmt-system'}>이력관리시스템</Link>
								</li>
								<li>
									<Link to={'/service'}>제공서비스</Link>
									<ul className="depth">
										<li><Link to={`/service?tab=${TabKey.Safety}`}>안전관리 전문기관</Link></li>
										<li><Link to={`/service?tab=${TabKey.Disaster}`}>건설재해예방 전문기관</Link></li>
									</ul>
								</li>
								<li>
									<Link to={'/notice'}>고객센터</Link>
									<ul className="depth">
										<li><Link to={'/notice'}>공지사항</Link></li>
										<li><a href={'https://static.smart-safety.kr/files/app-guide.pdf'}>이용가이드</a></li>
                                        <li><a href={'https://static.smart-safety.kr/files/app-guide-install.pdf'}>앱설치방법</a></li>
									</ul>
								</li>
								<li></li>
							</ul>
							<div className="menu-wrap"><MenuButton open={this.state.menuOpen} onClick={()=>this.handleMenuClick()} color='black'/></div>
						</div>
					</div>

					<Menu open={this.state.menuOpen}>
					</Menu>
			</Sticky>
		)
	}
}

/* Menu.jsx */
class Menu extends React.Component {
	constructor(props){
		super(props);
		this.state={
			open: this.props.open? this.props.open:false,
		}
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.open !== this.state.open){
			this.setState({open:nextProps.open});
		}
	}

	render(){
		return(
			<div>
				{
					this.state.open?
						<div className="m-overlay">
						<div className="all-gnb">
							<div className="all-navi-inner">
								<div className="all-in">
									<ul>
										<li>
											<strong className="dep1">스마트안전소개</strong>
											{/*<ul className="gnb-inner">
												<li><Link to={'/service/smart-safety'}>스마트안전소개</Link></li>
												<li><Link to={'/customers'}>고객사</Link></li>
											</ul>*/}
											<ul className="gnb-inner">
												<li><Link to={'/history-mgmt-system'}>스마트안전소개</Link></li>
											</ul>
										</li>
										<li>
											<strong className="dep1">이력관리시스템</strong>
											<ul className="gnb-inner">
												<li><Link to={'/history-mgmt-system'}>이력관리시스템</Link></li>
											</ul>
										</li>
										<li className="long-gnb">
											<strong className="dep1">제공서비스</strong>
											<ul className="gnb-inner">
												<li><Link to={`/service?tab=${TabKey.Safety}`} onClick={this.onClickCloseMenuOverlay}>안전관리 전문기관</Link></li>
												<ul className="sub-gnb">
													<li><Link to={'/service/75674628-fcfd-4385-8e68-e08745359215'}>안전관리대행수탁</Link></li>
													<li><Link to={'/service/8ab7a5d8-7c62-4567-b33d-04ffa630dcb6'}>위험성평가</Link></li>
													<li><Link to={'/service/291fc464-38a9-4dce-9e41-d5917ad15eb6'}>근골격계 부담작업 유해원인 조사</Link></li>
													<li><Link to={'/service/0de13afb-c7e0-4098-ad02-c380f1129726'}>제조업 유해위험방지 계획서</Link></li>
												</ul>
												<li><Link to={`/service?tab=${TabKey.Disaster}`} onClick={this.onClickCloseMenuOverlay}>건설재해예방 전문기관</Link></li>
												<ul className="sub-gnb">
													<li><Link to={'/service/f6086a8f-0ef4-4968-a32e-9faa4c9e5266'}>건설업 자율안전보건 컨설팅</Link></li>
													<li><Link to={'/service/d96470a6-1bfa-4041-8e2f-e567cdcb3ca4'}>건설업 유해위험방지계획서 작성 컨설팅</Link></li>
													<li><Link to={'/service/ff9b8080-12f7-4762-a740-46bffe288011'}>안전관리계획서</Link></li>
													<li><Link to={'/service/051bb2b2-c531-450c-a7fb-fd0a03d793dd'}>재해예방기술지도</Link></li>
												</ul>
											</ul>
										</li>
										<li>
											<strong className="dep1">고객센터</strong>
											<ul className="gnb-inner">
												<li><Link to={'/notice'}>공지사항</Link></li>
												<li><a href={'https://static.smart-safety.kr/files/app-guide.pdf'}>이용가이드</a></li>
												<li><a href={'https://static.smart-safety.kr/files/app-guide-install.pdf'}>앱설치방법</a></li>
											</ul>
										</li>
										{JWTUtils.isAuthenticated() &&
										<li>
											<strong className="dep1"><Link to={'/admin'}>Console</Link></strong>
											<ul className="gnb-inner">
												<li><Link to={'/admin/sign-out'}>로그아웃</Link></li>
											</ul>
										</li>
										}
									</ul>
								</div>
							</div>
						</div>
						</div>:null
				}
			</div>
		)
	}
}

/* MenuButton.jsx */
class MenuButton extends React.Component {
	constructor(props){
		super(props);
		this.state={
			open: this.props.open? this.props.open:false,
			color: this.props.color? this.props.color:'black',
		}
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.open !== this.state.open){
			this.setState({open:nextProps.open});
		}
	}

	handleClick(){
		this.setState({open:!this.state.open});
	}

	render(){
		const styles = {
			line: {
				height: '2px',
				width: '27px',
				background: this.state.color,
				transition: 'all 0.2s ease',
			},
			lineTop: {
				transform: this.state.open ? 'translateX(-1px) rotate(45deg)':'none',
				transformOrigin: 'top left',
				marginBottom: '7px',
			},
			lineMiddle: {
				opacity: this.state.open ? 0: 1,
				transform: this.state.open ? 'translateX(-16px)':'none',
			},
			lineBottom: {
				transform: this.state.open ? 'translateX(-2px) rotate(-45deg)':'none',
				transformOrigin: 'top left',
				marginTop: '7px',
			},
		}
		return(
			<div className="menu-icon"
				 onClick={this.props.onClick ? this.props.onClick:
					 ()=> {this.handleClick();}}>
				<div style={{...styles.line,...styles.lineTop}}/>
				<div style={{...styles.line,...styles.lineMiddle}}/>
				<div style={{...styles.line,...styles.lineBottom}}/>
			</div>
		)
	}
}


HeaderComponent.propTypes = {
	containerStyle: PropTypes.object,
};

export default HeaderComponent;