import React from "react";
import { Link } from "react-router-dom";
import HeaderComponent from "../components/common/HeaderComponent";
import FooterComponent from "../components/common/FooterComponent";
import Fade from "react-reveal/Fade";

/** img */
import home from "../resources/homepage/Home.svg";
import phoneex01 from "../resources/homepage/sv-overview.png";
import google from "../resources/homepage/google.svg";
import apple from "../resources/homepage/apple.svg";
/** img */

/** CSS */
import "../css/common.css";
import "../css/pc-common.css";
import "../css/contents.css";
/** CSS */

class Screen extends React.PureComponent {
  render() {
    return (
      <div id="wrap">
        <HeaderComponent />

        <div id="top-menu">
          <div className="section-in">
            {/*<Fade bottom><h1 className="top-title">여러분의 사업장 안전을<br /><strong>보다 간편하고 스마트하게 관리합니다</strong></h1></Fade>*/}
            <Fade bottom>
              <h1 className="top-title">
                산업현장의 모든 근로자가
                <br />
                <strong>가족의 품으로 무사히 돌아갈 수 있도록!</strong>
              </h1>
            </Fade>
            <div className="location">
              <Link to={"/"}>
                <img src={home} width="20px" alt="홈" />
              </Link>
              <strong>스마트안전소개</strong>
            </div>
          </div>
        </div>

        <div id="sub-container">
          <div className="section-in smart-sv">
            <div className="phone-ex">
              <img src={phoneex01} width="100%" alt="스마트안전 보고서 목록" />
            </div>
            <div className="c-txt">
              <p>
                <strong>
                  사업장 안전관리 솔루션 ‘<span className="red">스마트</span>
                  <span className="green">안전</span>’은
                </strong>
              </p>

              <p>
                안전관리현황, 모니터링, 이력관리 등 사업장의 안전을 위한 전반
                관리를 애플리케이션을 활용하여 손쉽게 할 수 있는 부산안전원이
                제공하는 모바일 서비스로써
              </p>

              <p>
                클라우드 기술을 기반으로 실시간 안전일지 작성, 사업장 현장 사진
                및 동영상 등을 업로드하고 안전전문가로부터 즉각적인 안전
                컨설팅까지 받을 수 있는 국내 유일 모바일 안전관리 솔루션입니다.
              </p>
              <div className="download">
                <a
                  href={
                    "https://play.google.com/store/apps/details?id=kr.smart_safety.app"
                  }
                  target={"_blank"}
                >
                  <img src={google} width="100%" alt="구글 다운로드" />
                </a>
                {/*<Link to={''}><img src={apple} width="100%" alt="애플 다운로드" /></Link>*/}
              </div>
            </div>
          </div>
        </div>

        <FooterComponent />
      </div>
    );
  }
}

export default Screen;
