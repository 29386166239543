import {SUBJECT_HAZARD_RISK, SUBJECT_REDUCTION_DISASTER} from "../constants/report_content_reply";

export class ReportContentResponse {
  uuid: string;
  id: number;
  created_at: string;
  report_content_uuid: string;
  subject: string;
  text: string;

  constructor(response: ReportContentResponse) {
    Object.assign(this, response);
  }

  static subjectAlias = (subject) => {
    switch (subject) {
      case SUBJECT_HAZARD_RISK: return '유해·위험요인';
      case SUBJECT_REDUCTION_DISASTER: return '재해예방 감소대책';
      default: return null;
    }
  }

  subjectAlias = () => {
    return ReportContentResponse.subjectAlias(this.subject);
  };
}
