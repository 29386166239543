import React from 'react';
import Router from './routes';
import {Provider} from 'mobx-react';
import stores from './stores';
import {api} from "./api/api";
import {StorageUtils} from "./utils/StorageUtils";
import firebase from 'firebase';
import * as moment from 'moment-timezone';

moment.tz.setDefault('Asia/Seoul');

// Load Authorization token
api.setHeader('Authorization', StorageUtils.getAuthorization());

// Firebase
firebase.initializeApp({
	apiKey: "AIzaSyCzfy3qme6bJ6BmS23WCSmsiUfzKqxn9lU",
	authDomain: "smart-safety-202106.firebaseapp.com",
	projectId: "smart-safety-202106",
	storageBucket: "smart-safety-202106.appspot.com",
	messagingSenderId: "209138512433",
	appId: "1:209138512433:web:3bce485133b53015295b10",
	measurementId: "G-G487M01TQN"
});
firebase.analytics();

class App extends React.Component {
	render() {
		return (
			<Provider {...stores}>
				<Router/>
			</Provider>
		);
	}
}

export default App;
