import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';

/** img */
import home from "../../resources/homepage/Home.svg";
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {

	componentDid() {
		window.onscroll = function() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-nav-wrap").className = "sv-nav-fixed";
			} else {
				document.getElementById("sv-nav-wrap").className = "sv-nav";
			}
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-con-wrap").className = "sv-contents-fixed sv-contents";
			} else {
				document.getElementById("sv-con-wrap").className = "sv-contents";
			}
		};
	}

	render() {
		return (
			<div id="wrap" className="sv-long-in">

					{/* header */}
					<HeaderComponent/>

					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title"><strong>건설업 자율안전보건 컨설팅</strong></h1>
							<div className="location sv-location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<Link to={'/service'}><strong>제공서비스</strong></Link>
								<Link to={'/service?tab=disaster'}><strong>건설재해예방 전문기관</strong></Link>
								<strong>건설업 자율안전보건 컨설팅</strong>
							</div>
						</div>
					</div>
					<div id="sub-container">
						<div className="section-in sv-in">
							<div className="sv-nav" id="sv-nav-wrap">
								<ul>
									<li className="on"><NavHashLink smooth to={'/service/f6086a8f-0ef4-4968-a32e-9faa4c9e5266/#sv1'} activeClassName="on">1. 건설업 자율안전보건 컨설팅</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/f6086a8f-0ef4-4968-a32e-9faa4c9e5266/#sv2'} activeClassName="on">2. 컨설팅 방법</NavHashLink></li>
								</ul>
							</div>

							<div className="sv-contents" id="sv-con-wrap">
								{/* 건설업 자율안전보건 컨설팅 */}
								<div id="sv1">
									<h6>1. 건설업 자율안전보건 컨설팅</h6>
									<p>
										<strong>개요</strong>
										건설현장에서 외부 전문가를 활용하여 안전점검 등 자체적으로 안전관리를 실시토록 함으로써 건설현장의 자율안전보건관리 능력을 향상시키고, 고용노동부의 산업안전부문 행정력을 안전보건관리가 취약한 중·소규모 건설현장에 집중하기 위한 제도입니다.
									</p>
									<p>
										<strong>사업대상</strong>
										공사금액 120억원(토목공사 150억원) 이상 건설현장<br />
										※ 자율안전보건컨설팅(이하 자율안전컨설팅) 기간 중 조사대상 중대재해 발생 및 자율안전컨설팅 취소 현장은 제외
									</p>
									<p>
										<strong>신청서 제출 및 승인</strong>
										• 1년 2회 신청 (상반기,하반기)
										• 상반기 (1월 중), 하반기 (6월 중) 건설현장에 사업 안내 공포 (고용노동부)<br />
										• 건설현장에서 관할 지방노동관서에 건설현장 자율안전컨설팅 신청서 및 점검자 자격증 사본 제출<br />
										• 지방 관서는 신청 서류를 검토하여 특별한 사유가 있는 경우를 제외하고는 즉시 승인
									</p>
								</div>

								{/* 컨설팅 방법 */}
								<div id="sv2">
									<h6>2. 컨설팅 방법</h6>
									<p>
										<strong style={{paddingBottom:'10px'}}>컨설팅 방법</strong>
										• 안전관리자 선임 건설현장에서 외부전문가를 통한 안전보건점검을 주기적으로 실시하고 점검내용 및 개선결과를 제출
										• 1인당 컨설팅 물량 제한, 모니터링 강화 등을 통해 자율안전컨설팅 내실화 도모
										• 소요되는 비용은 산업안전보건관리비에서 사용 가능
									</p>
									<p>
										<strong>컨설팅 혜택</strong>
										• 승인 이후 당해 현장 재해율이 건설업 평균 재해율 미만일 경우에는 프로그램 이행기간 동안 안전분야 감독 유예
										<div className='consulting'>
											• 건설현장 평균재해율 산정기준 =
											<ul>
												<li style={{borderBottom:'1px solid #000'}}>승인이후 재해자수</li>
												<li>산재보험근로자수(공동도급의 경우 합산)</li>
											</ul>
											× 100
										</div>
									</p>
								</div>

								<div className="sv-subBox">
									현업에서의 풍부한 경험 및 노하우를 바탕으로 산업재해로 인한 근로자의 귀중한 생명 보호와
									공공기관 안전담당자, 기업 내 사업주, 안전관리자의 안전관리 근로생애까지 관리해드리겠습니다.
								</div>

								<div className="sv-goback">
									<Link to={'/service?tab=disaster'}>
										<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
									</Link>
								</div>
							</div>

						</div>
					</div>

					{/* footer */}
					<FooterComponent/>

				</div>
		)
	}
}

export default Screen;
