import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';

/** img */
import home from "../../resources/homepage/Home.svg";
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {

	componentDid() {
		window.onscroll = function() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-nav-wrap").className = "sv-nav-fixed";
			} else {
				document.getElementById("sv-nav-wrap").className = "sv-nav";
			}
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-con-wrap").className = "sv-contents-fixed sv-contents";
			} else {
				document.getElementById("sv-con-wrap").className = "sv-contents";
			}
		};
	}

	render() {
		return (
			<div id="wrap" className="sv-long-in">

					{/* header */}
					<HeaderComponent/>

					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title"><strong>건설재해예방 기술지도</strong></h1>
							<div className="location sv-location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<Link to={'/service'}><strong>제공서비스</strong></Link>
								<Link to={'/service?tab=disaster'}><strong>건설재해예방 전문기관</strong></Link>
								<strong>건설재해예방 기술지도</strong>
							</div>
						</div>
					</div>
					<div id="sub-container">
						<div className="section-in sv-in">
							<div className="sv-nav" id="sv-nav-wrap">
								<ul>
									<li className="on"><NavHashLink smooth to={'/service/051bb2b2-c531-450c-a7fb-fd0a03d793dd/#sv1'} activeClassName="on">1. 건설재해예방 기술지도</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/051bb2b2-c531-450c-a7fb-fd0a03d793dd/#sv2'} activeClassName="on">2. 위반 시 법적 제재 사항</NavHashLink></li>
								</ul>
							</div>

							<div className="sv-contents" id="sv-con-wrap">
								{/* 건설재해예방 기술지도 */}
								<div id="sv1">
									<h6>1. 건설재해예방 기술지도</h6>
									<p>
										<strong>개요</strong>
										수급인 또는 자체사업을 하는 자 중 공사금액 1억원 이상 120억원(「건설산업기본법 시행령」별표1의 토목공사업에 속하는 공사는 150억원) 미만인 공사를 하는 자와 「건축법」제 11조에 따른 건축허가의 대상이 되는 공사를 하는 자는 산업재해 예방을 위한 [재해예방 전문지도기관]의 지도를 받도록 하고 있음. [산업안전보건법 제 73조, 시행규칙 제 59조]
									</p>
									<p>
										<strong>대상 및 내용</strong>
										• 대상 : 공사금액 1억 이상 120억원(토목 : 150억) 미만의 건설공사<br />
										• 기술지도내용<br />
										&nbsp;&nbsp;- 현장여건에 적합한 안전활동 기법지도<br />
										&nbsp;&nbsp;- 안전·보건교육 자료 등 제공<br />
										&nbsp;&nbsp;- 산업안전보건관리비 사용계획 등 지도<br />
										&nbsp;&nbsp;- 안전 관련 양식 등 제공<br />
										&nbsp;&nbsp;- 기타 표준 안전작업지침에 관한 사항 지도, 점검<br />
									</p>
									<p>
										<strong>기술지도 제외 공사</strong>
										• 공사기간 1개월 미만인 공사<br />
										• 육지와 연결되지 아니한 도서지역(제주도 제외)에서 이루어지는 공사<br />
										• 안전관리자를 선임하여 안전관리자 업무만을 전담하도록 하는 공사<br />
										• 유해·위험방지계획서 제출대상공사
									</p>
									<p>
										<strong>기술지도 계약 및 횟수</strong>
										• 공사 착공 전 기술지도 계약을 체결하고, 발주자에게 기술지도계약서 제출<br />
										• 기술지도 횟수 : 공사기간 중 월 2회
									</p>
								</div>

								{/* 위반 시 법적 제재 사항 */}
								<div id="sv2">
									<h6>2. 위반 시 법적 제재 사항</h6>
									<p>
										산업안전보건법 시행령 별표 35 (과태료의 부과 기준)
										<div className="respon-table">
										<table className="sv-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<thead>
											<tr>
												<th rowSpan={2}>위반행위</th>
												<th rowSpan={2}>세부내용</th>
												<th colSpan={3}>과태료 금액 (만원)</th>
											</tr>
											<tr>
												<th>1차</th>
												<th>2차</th>
												<th>3차</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>
													법 제 73조 제 1항을 위반하여 재해예방 전문지도기관의 지도를 받지 않은 경우
												</td>
												<td>법 제 175조 제 6항 제 6호</td>
												<td>200</td>
												<td>250</td>
												<td>300</td>
											</tr>
											</tbody>
										</table>
										</div>
									</p>
								</div>

								<div className="sv-subBox">
									현업에서의 풍부한 경험 및 노하우를 바탕으로 산업재해로 인한 근로자의 귀중한 생명 보호와
									공공기관 안전담당자, 기업 내 사업주, 안전관리자의 안전관리 근로생애까지 관리해드리겠습니다.
								</div>

								<div className="sv-goback">
									<Link to={'/service?tab=disaster'}>
										<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
									</Link>
								</div>
							</div>

						</div>
					</div>

					{/* footer */}
					<FooterComponent/>

				</div>
		)
	}
}

export default Screen;
