import {Company} from "./Company";
import {api} from "../api/api";
import {SafetyTest} from "./SafetyTest";
import {TYPE_EMPLOYEES, TYPE_SALES} from "../constants/ticket";

export class BusinessType {

  uuid: string;
  id: number;
  created_at: string;
  deleted_at: string;
  name: string;
  ticket_type: string;

  // JOIN
  companies: Company[];
  safety_tests: SafetyTest[];

  // Optional
  company_count: number;

  constructor(businessType: BusinessType) {
    Object.assign(this, businessType);
    if (businessType.companies) this.companies = businessType.companies.map((e) => new Company(e));
    if (businessType.safety_tests) this.safety_tests = businessType.safety_tests.map((e) => new SafetyTest(e));
  }

  static ticketTypeAlias(ticket_type: string) {
    switch (ticket_type) {
      case TYPE_EMPLOYEES: return '직원수';
      case TYPE_SALES: return '매출액';
      default: return null;
    }
  }

  get ticketTypeAlias() {
    return BusinessType.ticketTypeAlias(this.ticket_type);
  }

  static fetch = (uuid: string, params: Object = {}): Promise<BusinessType> => new Promise((resolve, reject) => {
    return api.get(`/v1/admin/business-type/${uuid}`, params).then((response) => {
      if (!response.ok) return reject(response);
      if (!response.data.business_type) return reject(response);
      return resolve(new BusinessType(response.data.business_type));
    }).catch((e) => {
      return reject(e);
    });
  });

  static fetchBusinessTypes = (params: Object = {query: undefined, offset: undefined, limit: undefined, join_report_contents: undefined}): Promise<BusinessType[]> => new Promise((resolve, reject) => {
    return api.get('/v1/admin/business-type', params).then((response) => {
      if (!response.ok) return reject(response);
      const business_types: BusinessType[] = (response.data.business_types || []).map((e) => new BusinessType(e));
      return resolve(business_types);
    }).catch((e) => {
      return reject(e);
    });
  });

  static fetchCount = (): Promise<number> => new Promise((resolve, reject) => {
    return api.get('/v1/admin/business-type/count', {}).then((response) => {
      if (!response.ok) return reject(response);
      const count = parseInt(response.data.count);
      return resolve(count);
    }).catch((e) => {
      console.log(e);
      return reject(e);
    });
  });
}