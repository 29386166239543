import {Ticket} from "./Ticket";
import {User} from "./User";
import moment from "moment";
import {makeObservable, observable} from "mobx";

export class UserTicket {
  @observable uuid: string;
  @observable id: number;
  @observable created_at: number;
  @observable updated_at: number;
  @observable expired_at: number;
  @observable next_purchase_at: number;
  @observable user_uuid: string;
  @observable ticket_uuid: string;
  @observable merchant_uid: string;      // 정기결제를 위한 빌링키(아임포트에서 발급)
  @observable customer_uid: string;      // 정기결제를 위한 빌링키(아임포트에서 발급)
  @observable is_basic: boolean;

  user: User;
  ticket: Ticket;

  constructor(userTicket: UserTicket) {
    Object.assign(this, userTicket);
    if (userTicket?.user) this.user = new User(userTicket.user);
    if (userTicket?.ticket) this.ticket = new Ticket(userTicket.ticket);
    makeObservable(this);
  }

  get isExpired(): boolean {
    if (!this.expired_at) return false;     // 만료일이 지정되지 않은 경우 무제한
    return moment.unix(this.expired_at).isSameOrBefore(moment());
  }

  get canTerminate(): boolean {
    if (this.is_basic) return false;
    if (!this.next_purchase_at) return false;
    if (this.isExpired) return false;
    return true;
  }
}