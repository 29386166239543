import { BusinessType } from "./BusinessType";
import { api } from "../api/api";

export class Ticket {
  uuid: string;
  business_type_uuid: string;
  id: number;
  created_at: number;
  duration_days: number;
  name: string;
  price: number;

  business_type: BusinessType;

  constructor(ticket: Ticket) {
    Object.assign(this, ticket);

    if (ticket?.business_type) this.business_type = new BusinessType(ticket.business_type);
  }

  // static fetchTickets = (params: Object = {}): Promise<Ticket[]> => new Promise((resolve, reject) => {
  //   return api.get('/v1/ticket', params).then((response) => {
  //     if (!response.ok) return reject(response);
  //     const tickets: Ticket[] = (response.data.tickets || []).map((e) => new Ticket(e));
  //     return resolve(tickets);
  //   }).catch((e) => {
  //     return reject(e);
  //   });
  // });
}