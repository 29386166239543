import {Company} from "./Company";

export class CompanyManager {

  company_uuid: string;
  created_at: string;
  updated_at: string;
  name: string;
  position: string;
  contact_number: string;
  email: string;

  // JOIN
  company: Company;

  constructor(manager: CompanyManager) {
    Object.assign(this, manager);
    if (manager.company) this.company = new Company(manager.company);
  }
}
