import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";
import '../../../../css/common.css';
import {Question} from "../../../../models/Question";
import AnimatedNumber from "animated-number-react";
import QuestionComponent from "../../../../components/admin/qna/QuestionListItemComponent";

/** CSS */

class Screen extends React.PureComponent {

	state = {
		isFetching: true,
		query: '',
		items: [],
		count: 0,
	};

	componentDidMount() {
		this.fetch();
		Question.fetchCount().then((count) => this.setState({count}));
	}

	fetch = () => {
		if (this.isLockedFetch) return;

		this.isLockedFetch = true;
		try {
			this.setState({isFetching: true}, () => {
				Question.fetchQuestions({query: this.state.query, ob: 'id', od: 'DESC', join_user: true}).then((questions) => {
					this.setState({items: questions, isFetching: false}, () => {
						this.isLockedFetch = false;
					});
				});
			});
		} catch (e) {
			this.isLockedFetch = false;
		}
	};

	onChangeQuery = (e) => {
		this.setState({query: e.currentTarget.value}, () => {
			this.fetch();
		});
	};

	renderItems = () => {
		const items: Question[] = this.state.items;

		return items.map((item, index) => (
			<QuestionComponent question={item}/>
		));
	};

	render() {
		return (
			<ContainerComponent>
				<div>
					<div id="ad-contents">
						<h1 className="contents-title">1:1 문의목록</h1>
						<div className="contents-in">
							<div className="schfrm">
								<div className="left">총 <strong><AnimatedNumber
									value={this.state.count}
									formatValue={(value) => value.toFixed(0)}/></strong>건</div>
								<div className="right">
									<div className="search-box">
										<input type="text" id="ad-search" placeholder="검색어를 입력해주세요" className="search" value={this.state.query} onChange={this.onChangeQuery}/>
										<div className="search-btn"></div>
									</div>
								</div>
							</div>
							<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
								<colgroup>
									<col width="20%;"/>
									<col width="35%;"/>
									<col width="15%;"/>
									<col width="15%;"/>
									<col width="15%;"/>
								</colgroup>
								<thead>
								<tr>
									<th scope="col">업체명</th>
									<th scope="col">제목</th>
									<th scope="col">작성일</th>
									<th scope="col">상태</th>
									<th scope="col" className="editTit">답변</th>
								</tr>
								</thead>
								<tbody>
								{this.renderItems()}
								</tbody>
							</table>
						</div>
					</div>

				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;
