import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
import close from "../../../../resources/admin/icon/close.svg";
/** img */
/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';
import {ReportListItemComponent} from "../../../../components/admin/report/ReportListItemComponent";
import {Report} from "../../../../models/Report";

/** CSS */

class Screen extends React.PureComponent {

	state = {
		reports: [],
		query: '',
		report_count: 0,
	};

	componentDidMount() {
		this.fetch();
	}

	fetch = () => {
		Report.fetchCount().then((count) => {
			this.setState({ report_count: count });
		});

		Report.fetchReports({query: this.state.query, join_user: true, ob: 'id', od: 'DESC'}).then((reports) => {
			this.setState({reports});
		});
	};

	onChangeQuery = (e) => {
		this.setState({query: e.currentTarget.value}, () => {
			this.fetch();
		});
	};

	render() {
		return (
			<ContainerComponent>
				<div>
					<div id="ad-contents">
						<h1 className="contents-title">보고서 목록</h1>
						<div className="contents-in">
							<div className="schfrm">
								<div className="left">총 <strong>{this.state.report_count}</strong>건</div>
								<div className="right">
									<div className="search-box">
										<input type="text" id="ad-search" placeholder="검색어를 입력해주세요" className="search" value={this.state.query} onChange={this.onChangeQuery}/>
										<div className="search-btn"></div>
									</div>
								</div>
							</div>
							<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
								{/*<colgroup>
									<col />
									<col width="5%;"/>
									<col width="15%;"/>
									<col width="34%;"/>
									<col width="12%;"/>
									<col width="12%;"/>
									<col width="12%;"/>
									<col width="10%;"/>
								</colgroup>*/}
								<thead>
								<tr>
									<th scope="col">업체명</th>
									<th scope="col">내용</th>
									<th scope="col">안전 담당자</th>
									<th scope="col">검토일자</th>
									<th scope="col">상태</th>
								</tr>
								</thead>
								<tbody>
								{this.state.reports.map((report: Report, index) => (
									<ReportListItemComponent key={report.uuid} report={report}/>
								))}
								</tbody>
							</table>

							{/* 안전담당자 설정 클릭시 modal 노출  */}
							<div id="basic-modal" style={{display:'none'}}>
								<div className="modal info-modal">
									<div id="modal-close"><img src={close} width="16px;" alt="닫기" /></div>
									<h1>안전 담당자 설정</h1>

									<div className="modal-in">
										<ul>
											<li>
												<div>아이디</div>
												<input type="text" id="user-ID" placeholder="기본 정보 노출/수정불가" disabled />
											</li>
											<li>
												<div>업체명</div>
												<input type="text" id="user-name" placeholder="기본 정보 노출/수정불가" disabled />
											</li>
											<li>
												<div>주소</div>
												<input type="text" id="user-address" placeholder="기본 정보 노출/수정불가" disabled />
											</li>

											<li>
												<div>안전담당자</div>
												<select className="select">
													<option selected>선택</option>
													<option>김제로</option>
													<option>권제로</option>
													<option>이제로</option>
												</select>
											</li>
										</ul>
									</div>

									<div className="btn-wrap">
										<button id="modal-btn-cancel" className="modal-btn btn-cancel">취소
										</button>
										<button id="modal-btn-ok" className="modal-btn btn-edit">수정</button>
									</div>
								</div>
							</div>


						</div>
					</div>

				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;
