import React from 'react';
import HeaderComponent from "../components/common/HeaderComponent";
import FooterComponent from "../components/common/FooterComponent";
import parse from 'html-react-parser';

/** CSS */
import '../css/common.css';
import {Link} from "react-router-dom";
import goback from "../resources/homepage/leftarrow.svg";
import home from "../resources/homepage/Home.svg";
import Fade from "react-reveal/Fade";
import {Notice} from "../models/Notice";
import moment from "moment";

class Screen extends React.PureComponent {

	state = {
		notice: new Notice({}),
	};

	get uuid() {
		return this.props.match?.params?.uuid;
	}

	componentDidMount() {
		this.fetch();
	}

	fetch = () => {
		if (this.isLockedFetch) return;

		this.isLockedFetch = true;
		try {
			Notice.fetch(this.uuid, {join_attachments: true, join_user: true}).then((notice) => {
				this.setState({isFetching: true}, () => {
					this.setState({isFetching: false, notice}, () => {
						this.isLockedFetch = false;
					});
				});
			});

		} catch (e) {
			this.isLockedFetch = false;
		}
	}

	render() {
		const notice: Notice = this.state.notice;
		return (
			<div id="wrap">

				{/* header */}
				<HeaderComponent/>

				<Fade bottom style={{animationDelay:'.2s'}}>
					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title">스마트안전 서비스<br /><strong>공지사항</strong></h1>
							<div className="location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<strong>고객센터</strong>
								<strong>공지사항</strong>
							</div>
						</div>
					</div>
				</Fade>

				<Fade bottom delay={600} duration={1000}>
				<div id="sub-container" className="notice-con">
					<div className="section-in">
						<div className="notice-container">
							<div className="notice-top">
								<div className="notice-tit">{notice?.title}</div>
								<div className="notice-date">{notice?.created_at ? moment.unix(notice.created_at).format('YYYY-MM-DD hh:mm:ss') : ''}</div>
							</div>
							<div className="notice-txt">
								<p>{notice?.content && parse(notice.content)}</p>
							</div>
						</div>
						<div className="sv-goback">
							<Link to={'/notice'}>
								<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
							</Link>
						</div>
					</div>
				</div>
				</Fade>


				{/* footer */}
				<FooterComponent/>

			</div>
		);
	}
}

export default Screen;
