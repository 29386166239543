import React from 'react';
import PropTypes from "prop-types";
import {ReportContent} from "../../../../models/ReportContent";
import {ContentResponseComponent} from "./ContentResponseComponent";
import {SUBJECT_HAZARD_RISK, SUBJECT_REDUCTION_DISASTER} from "../../../../constants/report_content_reply";
import {ReportContentResponse} from "../../../../models/ReportContentResponse";
import answer from "../../../../resources/admin/icon/answer-arrow.svg";
import close12px from "../../../../resources/admin/icon/close_12px.svg";
import {api} from "../../../../api/api";

export class ReportContentComponent extends React.Component {

	static propTypes = {
		content: PropTypes.instanceOf(ReportContent).isRequired,
		enableInput: PropTypes.bool,
		allowDeleteResponse: PropTypes.bool,
	};

	deleteResponse = (response: ReportContentResponse) => {
		if (window.confirm('삭제하시겠습니까?')) {
			api.delete(`/v1/admin/report/content/response/${response.uuid}`).then((r) => {
				if (!r.ok) return alert('삭제실패');
				alert('삭제성공');

				this.props.content.responses = this.props.content.responses.filter(e => e.uuid !== response.uuid);
				this.setState({});
			}).catch(e => {
				console.error(e);
				alert('삭제오류');
			});
		}
	};

	renderMedia = () => {
		const content = this.props.content;
		const attachment_mime = content?.attachment_mime;

		if (attachment_mime?.startsWith('image/')) {
			return <img src={this.props.content?.attachment_url} width="100%" alt="첨부이미지" />;
		}

		if (attachment_mime?.startsWith('video/')) {
			return(
				<video width={'100%'} height={'100%'} autoPlay muted controls loop src={content?.attachment_url} allowfullscreen/>
			);
		}

		return null;
	}

	render() {
		return (
			<div style={{padding:"0px 16px 50px"}}>
				<div className="write-list">
					<div className="img-wrap">
						{this.renderMedia()}
					</div>
					<div className="text-wrap">
						<p className="user-write">{this.props.content?.text}</p>
					</div>
				</div>

				<h2 style={{display:"block", marginTop:"30px", marginBottom:"10px", fontSize:"16px", color:"#00934B"}}>답변</h2>
				{this.props.content?.responses?.map((e, i) => (
					<p className="user-write" style={{padding:"12px 16px"}}><img src={answer} width="18px" alt="화살표" style={{marginTop:"-7px"}}/>  {ReportContentResponse.subjectAlias(e.subject)}:  {e.text} {this.props.allowDeleteResponse && <img src={close12px} alt={'삭제'} onClick={() => this.deleteResponse(e)}/>}</p>
				))}

				{this.props.enableInput &&
				<div className="text-wrap-bottom">
					<ul>
						<li>
							<ContentResponseComponent content={this.props.content} subject={SUBJECT_HAZARD_RISK}/>
						</li>
						<li>
							<ContentResponseComponent content={this.props.content} subject={SUBJECT_REDUCTION_DISASTER}/>
						</li>
					</ul>
				</div>}
			</div>
		);
	}

}
