import React from 'react';
import {Link} from "react-router-dom";
import HeaderComponent from "../components/common/HeaderComponent";
import FooterComponent from "../components/common/FooterComponent";
import Fade from "react-reveal/Fade";

import home from "../resources/homepage/Home.svg";
import '../css/common.css';
import '../css/pc-common.css';
import '../css/contents.css';

class Screen extends React.PureComponent {

	state = {
		selected_tab: null,
	};

	constructor(props) {
		super(props);
		this.state.selected_tab = this.tab1;
	}

	onClickTab = (tab) => {
		this.setState({selected_tab: tab});
	};

	tab1 = (
		<div className="respon-table">
			<table className="basic-table sub-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
				<colgroup>
					<col width="25%;"/>
					<col width="30%;"/>
					<col width="45%;"/>
				</colgroup>
				<thead>
				<tr>
					<th scope="col">사업자명</th>
					<th scope="col">산재업종명</th>
					<th scope="col">본사소재지</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>(주)에이치케이조선</td>
					<td>강선건조 또는 수리업</td>
					<td>경남 사천시 모례골길 73 (사등동)</td>
				</tr>
				<tr>
					<td>삼녹ENG</td>
					<td>각종 금속의 용접 또는 용단</td>
					<td>경남 거제시 거제면 읍내리 154-29</td>
				</tr>
				<tr>
					<td>(주)낙원티엔에이</td>
					<td>수송용기계기구 제조업</td>
					<td>경남 밀양시 부북면 사포산단1길 53</td>
				</tr>
				<tr>
					<td>(주)일해진례공장</td>
					<td>강기타 기계장비 조립용 플라스틱 제품 제조</td>
					<td>경남 김해시 진례면 서부로 396번길 15</td>
				</tr>
				<tr>
					<td>(주)플루엔</td>
					<td>일반 산업용 기계장치 제조</td>
					<td>경남 김해시 유하로 161</td>
				</tr>
				<tr>
					<td>(주)케스템프카테크</td>
					<td>자동차 부품 제조</td>
					<td>경남 김해시 진례면 고모로 614</td>
				</tr>
				<tr>
					<td>(주)제이앤비맨파워</td>
					<td>일반 창고업</td>
					<td>경남 김해시 칠산로 128-1 (롯데물류센터 내)</td>
				</tr>
				<tr>
					<td>세원정공(주)</td>
					<td>플라스틱 가공 제품 제조</td>
					<td>경남 김해시 한림면 김해대로 916번길 154-36</td>
				</tr>
				<tr>
					<td>S&T AMT(주) 부산</td>
					<td>자동차 부품 제조</td>
					<td>부산시 기장군 철마면 여락송정로 363</td>
				</tr>
				<tr>
					<td>(주)현대포리텍 김해</td>
					<td>의약품 도매업</td>
					<td>부산시 강서구 화전산단6로 110</td>
				</tr>
				<tr>
					<td>백제약품(주) 영남본부</td>
					<td>수산 식료품 제조</td>
					<td>경남 김해시 진례면 김해테크노밸리 33-12</td>
				</tr>
				<tr>
					<td>삼양씨푸드(주)</td>
					<td>보건 및 사회복지사업</td>
					<td>부산시 서구 원양로1 (수산가공선진화단지306호)</td>
				</tr>
				<tr>
					<td>한마음병원</td>
					<td>보건 및 사회복지사업</td>
					<td>경남 사천시 향촌동 441-5</td>
				</tr>
				<tr>
					<td>한마음 노인요양병원</td>
					<td>수산 식료품 제조</td>
					<td>경남 사천시 향촌4길 127</td>
				</tr>
				<tr>
					<td>(주)그린푸드</td>
					<td>자동차 부품 제조</td>
					<td>부산시 강서구 녹산산단 407로 65</td>
				</tr>
				<tr>
					<td>영민인더스트리</td>
					<td>일상생활용 전기기계기구제조업</td>
					<td>경남 양산시 어곡공단로 116</td>
				</tr>
				<tr>
					<td>(주)일해></td>
					<td>-</td>
					<td>경남 김해시 진영읍 본사나로 49-16</td>
				</tr>
				<tr>
					<td>(주)한창제지</td>
					<td>일상생활용 전기기계기구제조업</td>
					<td>경남 양산시 웅상대로 1564</td>
				</tr>
				<tr>
					<td>(주)인성티엔피</td>
					<td>자동차 부품 제조</td>
					<td>경남 김해시 진영읍 본산로 153-26</td>
				</tr>
				<tr>
					<td>S&T AMT(주) 양산</td>
					<td>원동기 제조업</td>
					<td>-</td>
				</tr>
				<tr>
					<td>(주)케이프</td>
					<td>원동기 제조업</td>
					<td>경남 양산시 상북명 양산대로 1303</td>
				</tr>
				<tr>
					<td>(주)케이프 양산공장</td>
					<td>자동차 부품 제조</td>
					<td>경남 양산시 상북명 양산대로 1303</td>
				</tr>
				<tr>
					<td>(주)유선기어</td>
					<td>자동차 부품 제조</td>
					<td>경남 양산시 산막공단북10길 120</td>
				</tr>
				<tr>
					<td>(주)코렌스글로벌</td>
					<td>건물 등의 종합관리</td>
					<td>경남 양산시 어곡로 164</td>
				</tr>
				<tr>
					<td>(주)디즈텍 화명대림타운</td>
					<td>건물 등의 종합관리</td>
					<td>부산시 동래구 충렬대로 237번길 66</td>
				</tr>
				<tr>
					<td>(주)디즈텍 사직쌍용예가아파트</td>
					<td>건물 등의 종합관리</td>
					<td>부산시 동래구 충렬대로 237번길 66</td>
				</tr>
				<tr>
					<td>(주)케이비시스템 주례럭키아파트</td>
					<td>국가 및 지방자치단체의 사업</td>
					<td>부산시 동래구 충렬대로 237번길 66</td>
				</tr>
				<tr>
					<td>밀양시청</td>
					<td>기타 과학기술서비스업</td>
					<td>경남 밀양시 밀양대로 2017</td>
				</tr>
				<tr>
					<td>(주)건설기술원</td>
					<td>각종 기계 또는 동 부속품 제조</td>
					<td>경남 김해시 수가로 249</td>
				</tr>
				<tr>
					<td>(주)베스텍</td>
					<td>-</td>
					<td>경남 밀양시 부북면 사포산단1길 77</td>
				</tr>
				<tr>
					<td>진영코리아</td>
					<td>국가 및 지방자치단체의 사업</td>
					<td>부산시 강서구 범방2로 33</td>
				</tr>
				<tr>
					<td>강서구청</td>
					<td>전기기계기구제조업</td>
					<td>부산시 강서구 낙동북로 477</td>
				</tr>
				<tr>
					<td>동진모타공업(주)</td>
					<td>-</td>
					<td>부산시 연제구 해맞이로 73</td>
				</tr>
				<tr>
					<td>(주)흙</td>
					<td>보건 및 사회복지사업</td>
					<td>부산시 사상구 학감대로 192번길 60-30</td>
				</tr>
				<tr>
					<td>온종합병원</td>
					<td>보건 및 사회복지사업</td>
					<td>부산시 부산진구 가야대로 721</td>
				</tr>
				<tr>
					<td>온종합병원</td>
					<td>국가 및 지방자치단체의 사업</td>
					<td>부산시 부산진구 가야대로 721</td>
				</tr>
				<tr>
					<td>수영구청</td>
					<td>기타 식료품 제조업</td>
					<td>부산시 수영구 남천동로 100</td>
				</tr>
				<tr>
					<td>성민물류(주)</td>
					<td>-</td>
					<td>부산시 사상구 강변대로 570 A동 207호</td>
				</tr>
				<tr>
					<td>(주)립스</td>
					<td>-</td>
					<td>경남 양산시 어곡로 164</td>
				</tr>
				<tr>
					<td>일어나걸어라온병원</td>
					<td>-</td>
					<td>부산시 부산진구 가야대로 721</td>
				</tr>
				<tr>
					<td>해성전자(주)</td>
					<td>-</td>
					<td>경남 창원시 마산회원구 자유무역3길 180, 6층</td>
				</tr>
				<tr>
					<td>(주)태양금속</td>
					<td>-</td>
					<td>부산시 사상구 학감대로 192번길 21</td>
				</tr>
				<tr>
					<td>태양산업(주)</td>
					<td>-</td>
					<td>경남 양산시 상북명 석계산단4길 23</td>
				</tr>
				<tr>
					<td>(주)미디어윌</td>
					<td>-</td>
					<td>부산시 연제구 거제대로 286</td>
				</tr>
				<tr>
					<td>(주)신광레포츠</td>
					<td>-</td>
					<td>부산시 사하구 장평로 31-1</td>
				</tr>
				<tr>
					<td>인창실버웰</td>
					<td>-</td>
					<td>부산시 사하구 다대로 83번길 132</td>
				</tr>
				<tr>
					<td>(주)대원플라텍</td>
					<td>-</td>
					<td>경남 김해시 주촌면 골든루트로 186-16</td>
				</tr>
				<tr>
					<td>(주)블루인더스</td>
					<td>-</td>
					<td>-</td>
				</tr>
				<tr>
					<td>지에스(주)</td>
					<td>-</td>
					<td>경남 김해시 진영읍 본산1로 56번길 77</td>
				</tr>
				<tr>
					<td>일신기독병원</td>
					<td>보건 및 사회복지사업</td>
					<td>부산시 동구 정공단로 27</td>
				</tr>
				<tr>
					<td>S&T AMT(주) 정관</td>
					<td>자동차부품제조</td>
					<td>부산시 기장군 정관읍</td>
				</tr>
				<tr>
					<td>창녕군청</td>
					<td>국가 및 지방자치단체의 사업</td>
					<td>경남 창녕군 창녕읍 군청길1</td>
				</tr>
				<tr>
					<td>한국과학영재학교</td>
					<td>-</td>
					<td>부산시 부산진구 백양관문로 105-47</td>
				</tr>
				<tr>
					<td>용원개발(주)</td>
					<td>-</td>
					<td>경남 창원시 진해구 가주로 133</td>
				</tr>
				<tr>
					<td>에스씨케이</td>
					<td>-</td>
					<td>서울시 송파구 송파대로 201</td>
				</tr>
				<tr>
					<td>국제인쇄(주)</td>
					<td>기타 인쇄업</td>
					<td>부산시 사상구 새벽로 167번길 129</td>
				</tr>
				<tr>
					<td>(주)원진엠앤티</td>
					<td>-</td>
					<td>부산시 강서구 미음국제4로 23</td>
				</tr>
				<tr>
					<td>정근안과병원</td>
					<td>-</td>
					<td>부산시 부산진구 가야대로 767</td>
				</tr>
				<tr>
					<td>21세기 라파병원</td>
					<td>-</td>
					<td>부산시 부산진구 중앙대로 777</td>
				</tr>
				<tr>
					<td>한영모터스(주)</td>
					<td>-</td>
					<td>부산시 남구 황령대로 378</td>
				</tr>
				<tr>
					<td>(주)재덕</td>
					<td>-</td>
					<td>부산시 중구 해관로 65, 15층 1503호</td>
				</tr>
				<tr>
					<td>삼주물산(주)</td>
					<td>-</td>
					<td>부산시 부산진구 신천대로 291</td>
				</tr>
				<tr>
					<td>(주)크린텍</td>
					<td>-</td>
					<td>부산시 강서구 녹산산업중로 52-9</td>
				</tr>
				</tbody>
			</table>
		</div>
	);

	tab2 = (
		<table className="basic-table sub-table full-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
			<colgroup>
				<col width="50%;"/>
				<col width="50%;"/>
			</colgroup>
			<thead>
			<tr>
				<th colSpan={2}>사업자명</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>신해테크</td>
				<td>애수달산업사</td>
			</tr>
			<tr>
				<td>(주)케이프</td>
				<td>(주)디엔테크</td>
			</tr>
			<tr>
				<td>홍스틸(주)</td>
				<td>제철세라믹</td>
			</tr>
			<tr>
				<td>경종스틸</td>
				<td>동영기업</td>
			</tr>
			<tr>
				<td>한창제지</td>
				<td>HS 아쿠아피드</td>
			</tr>
			<tr>
				<td>강진건설</td>
				<td>르노삼성자동차</td>
			</tr>
			<tr>
				<td>고려제강</td>
				<td>AJ동양매닉스</td>
			</tr>
			<tr>
				<td>영빈건설</td>
				<td>태극기초건설</td>
			</tr>
			<tr>
				<td>명성제강</td>
				<td>코오롱글로벌</td>
			</tr>
			<tr>
				<td>포스코건설</td>
				<td>성창보드</td>
			</tr>
			<tr>
				<td>신성메탈</td>
				<td>덕원종합건설</td>
			</tr>
			<tr>
				<td>덕산갈바텍</td>
				<td>토지종합건설</td>
			</tr>
			<tr>
				<td>엠테크코리아</td>
				<td>강림인슈</td>
			</tr>
			</tbody>
		</table>
	);

	tab3 = (
		<table className="basic-table sub-table full-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
			<colgroup>
				<col width="50%;"/>
				<col width="50%;"/>
			</colgroup>
			<thead>
			<tr>
				<th colSpan={2}>사업자명</th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>(주)한창제지</td>
				<td>(주)모토닉</td>
			</tr>
			<tr>
				<td>(주)대성사</td>
				<td>-</td>
			</tr>
			</tbody>
		</table>
	);

	renderTab = () => {
		if (!this.state.selected_tab) return null;
		return this.state.selected_tab;
	}

	renderTabButton = (title, content) => (
		<li className={this.state.selected_tab === content && 'on'} onClick={() => this.onClickTab(content)}>{title}</li>
	);

	render() {
		return (
			<div id="wrap">

				{/* header */}
				<HeaderComponent/>

				<div id="top-menu">
					<div className="section-in">
						<Fade bottom><h1 className="top-title">전국 어디서나 만날 수 있는<br /><strong>스마트안전 서비스</strong></h1></Fade>
						<div className="location">
							<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
							<strong>스마트안전소개</strong>
							<strong>고객사</strong>
						</div>
					</div>
				</div>

				<div id="sub-container" className="notice-con">
					<div className="section-in">

						<ul className="tabs">
							{this.renderTabButton('안전관리업무위탁', this.tab1)}
							{this.renderTabButton('중대재해자문', this.tab2)}
							{this.renderTabButton('안전컨설팅', this.tab3)}
						</ul>

						{this.renderTab()}

					</div>
				</div>

				{/* footer */}
				<FooterComponent/>


			</div>
		)
	}
}

export default Screen;
