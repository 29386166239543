import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
/** CSS */
import '../../../../css/common.css';
import AnimatedNumber from "animated-number-react";
import ListLoadingComponent from "../../../../components/admin/common/ListLoadingComponent";
import BusinessTypeAddModal from "../../../../modals/admin/BusinessTypeAddModal";
import BusinessTypeListItemComponent from "../../../../components/admin/business/BusinessTypeListItemComponent";
import {BusinessType} from "../../../../models/BusinessType";

/** CSS */

class Screen extends React.PureComponent {

	isLockedFetch = false;

	state = {
		isFetching: true,
		query: '',
		items: [],
		count: 0,

		visible_add_modal: false,
	};

	componentDidMount() {
		this.fetch();
		BusinessType.fetchCount().then((count) => this.setState({count}));
	}

	fetch = () => {
		if (this.isLockedFetch) return;

		this.isLockedFetch = true;
		try {
			this.setState({isFetching: true}, () => {
				BusinessType.fetchBusinessTypes({query: this.state.query, ob: 'id', od: 'DESC', join_company_count: true}).then((business_types) => {
					this.setState({items: business_types, isFetching: false}, () => {
						this.isLockedFetch = false;
					});
				});
			});
		} catch (e) {
			this.isLockedFetch = false;
		}
	};

	onChangeQuery = (e) => {
		this.setState({query: e.currentTarget.value}, () => {
			this.fetch();
		});
	};

	onClickAdd = () => {
		this.setState({visible_add_modal: true});
	};

	onCloseModal = () => {
		this.setState({visible_add_modal: null}, () => {
			this.fetch();
		});
	};

	renderItems = () => {
		const items = this.state.items;

		return items.map((item, index) => (
			<BusinessTypeListItemComponent key={index} business_type={item} onClickEdit={this.onClickEdit}/>
		));
	}

	render() {
		return (
			<ContainerComponent>
				<div id="ad-contents">
					<h1 className="contents-title">업종 관리</h1>
					<div className="contents-in">
						<div className="schfrm">
							<div className="left">
								총 <strong><AnimatedNumber
								value={this.state.count}
								formatValue={(value) => value.toFixed(0)}/>건</strong>
							</div>
							<div className="right">
								<div className="search-box">
									<input type="text" id="ad-search" placeholder="검색어를 입력해주세요" className="search" value={this.state.query} onChange={this.onChangeQuery}/>
									<div className="search-btn"/>
								</div>
								<button className="enroll" onClick={this.onClickAdd}>업종 등록</button>
							</div>
						</div>
						<div className="table-wrap">
							<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
								<colgroup>
									<col width="40%;"/>
									<col width="20%;"/>
									<col width="15%;"/>
									<col width="15%;"/>
									<col width="10%;"/>
								</colgroup>
								<thead>
								<tr>
									<th scope="col">업종</th>
									<th scope="col">이용권 산정기준</th>
									<th scope="col">업체 수</th>
									<th scope="col">생성일</th>
									<th scope="col" className="editTit">보기</th>
								</tr>
								</thead>


								<tbody>
								{this.renderItems()}
								</tbody>

								<ListLoadingComponent visible={this.state.isFetching}/>
							</table>
						</div>

						{/* 업종 등록 클릭시 modal 노출  */}
						{this.state.visible_add_modal &&
						<BusinessTypeAddModal onClose={this.onCloseModal}/>}

					</div>
				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;