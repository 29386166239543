import React from 'react';
import PropTypes from "prop-types";
import {api} from "../../../api/api";
import {Report} from "../../../models/Report";

export class ResponseComponent extends React.PureComponent {

	static propTypes = {
		report: PropTypes.instanceOf(Report).isRequired,
	};

	state = {
		text: '',
	};

	onClickSubmit = () => {
		if (this.isLockedSubmit) return;
		this.isLockedSubmit = true;

		const release = (state, callback) => {
			this.setState({...state}, () => {
				this.isLockedSubmit = false;
				typeof callback === 'function' && callback();
			});
		};

		const text = this.state.text;
		if (!text || !text.trim()) {
			alert('내용을 입력해주세요');
			return release();
		}

		api.post(`/v1/admin/report/${this.props.report.uuid}/response`, {text}).then((r) => {
			if (!r.ok) {
				alert('작성할 수 없습니다');
				return release();
			}

			alert('작성 완료');
			return release({text: ''});
		}).catch((e) => {
			console.error(e);
			return release();
		});

		release();
	};

	onChange = (e) => {
		this.setState({text: e.target.value});
	};

	render() {
		return (
			<div className="ad qna-comment">
				<strong>답변작성</strong>
				<div className="write-comment">
					<textarea className="textbox" placeholder="내용을 입력해주세요" onChange={this.onChange} value={this.state.text}/>
					<button onClick={this.onClickSubmit}>등록</button>
				</div>
			</div>
		);
	}
}