import {Report} from "./Report";

export class ReportResponse {
	uuid: string;
	id: number;

	created_at: string;

	report_uuid: string;
	text: string;

	// JOIN
	report: Report;

	constructor(report: Report) {
		Object.assign(this, report);

		if (report.report) this.report = new Report(report.report);
	}
}
