import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
import clock from '../../../../resources/admin/icon/clock.svg';
import phone from '../../../../resources/admin/icon/phone.svg';
/** img */
/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';
import {Link} from "react-router-dom";
import {Report} from "../../../../models/Report";
import moment from "moment";
import {ResponseComponent} from "../../../../components/admin/report/ResponseComponent";
import {ReportContentComponent} from "../../../../components/admin/report/content/ReportContentComponent";
import {STATUS_CONFIRMED, STATUS_DONE, STATUS_NORMAL} from "../../../../constants/report";
import {api} from "../../../../api/api";
import close12px from "../../../../resources/admin/icon/close_12px.svg";
import {ReportContentResponse} from "../../../../models/ReportContentResponse";

/** CSS */

class Screen extends React.Component {

	state = {
		report: new Report({}),
		status: null,
	};

	get uuid() {
		return this.props.match?.params?.uuid;
	}

	componentDidMount() {
		Report.fetch(this.uuid, {join_user: true, join_report_contents: true, join_response: true}).then((report) => {
			console.log('report.contents', report?.contents);
			this.setState({report, status: report.status});
		});
	}

	onChangeStatus = (status) => {
		this.setState({status}, () => {

		});
	};

	onClickSubmit = () => { this.submit() };

	submit = () => {
		if (this.isLockedSubmit) return;
		this.isLockedSubmit = true;

		const release = (state, callback) => {
			this.setState({...state}, () => {
				this.isLockedSubmit = false;
				typeof callback === 'function' && callback();
			});
		};

		const status = this.state.status;
		if (!status) {
			return release({}, () => {
				alert('점검결과를 선택해주세요');
			});
		}

		if (status === this.state.report.status) {
			return release({}, () => {
				alert('점검결과가 이전과 동일합니다.');
			});
		}

		api.put(`/v1/admin/report/${this.uuid}`, {status}).then((r) => {
			if (!r.ok) return release({}, () => {
				alert('수정실패');
			});

			this.props.history.push(`/admin/report/${this.uuid}`);
		})};

	deleteResponse = (response: ReportContentResponse) => {
		if (window.confirm('삭제하시겠습니까?')) {
			api.delete(`/v1/admin/report/response/${response.uuid}`).then((r) => {
				if (!r.ok) return alert('삭제실패');
				alert('삭제성공');

				this.setState((p) => {
					const report = p.report;
					report.responses = report?.responses?.filter(e => e.uuid !== response.uuid);
					return { report };
				});
			}).catch(e => {
				console.error(e);
				alert('삭제오류');
			});
		}
	};

	render() {
		return (
			<ContainerComponent>
				<div>
					<div id="ad-contents" className="write">
						<h1 className="contents-title">{this.state.report?.user?.company?.business_type?.name || '-'}</h1>
						<div className="write-info">
							<span>{this.state.report?.user?.company?.name || '-'}</span>
							<span><img src={clock} width="14px" alt="날짜" /> {this.state.report?.created_at ? moment.unix(this.state.report?.created_at).format('YYYY-MM-DD') : '-'}</span>
							<span><img src={phone} width="15px" alt="연락처" /> {this.state.report?.user?.company?.contact_number || '-'}</span>
						</div>

						<p className="user-write">{this.state.report?.text}</p>

						<div className="ad qna-comment">
							{this.state.report?.responses?.map(response => (
								<>
									<strong>답변</strong>
									{response?.text?.split('\n').map((line) => (
										<span>{line}<br/></span>
									))}
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8, marginBottom: 8 }} onClick={() => this.deleteResponse(response)}>
										<img src={close12px} alt={'삭제'}/>
										<span style={{ marginLeft: 4 }}>삭제</span>
									</div>
								</>
							))}
						</div>

						<ResponseComponent report={this.state.report || new Report({uuid: this.uuid})}/>

						{/* 단 */}
						{this.state.report?.contents?.map((e, index) => (
							<ReportContentComponent key={e.uuid} content={e} enableInput allowDeleteResponse/>
						))}
						{/* 단 */}

						<div className='complete'>
							<div className='left'>검토결과</div>
							<div className="radio">
								<input type="radio" id="r3" name="field" onChange={e => e.currentTarget.checked && this.onChangeStatus(STATUS_NORMAL)} checked={this.state.status === STATUS_NORMAL}/>
								<label htmlFor="r3">대기</label>
							</div>

							<div className="radio">
								<input type="radio" id="r1" name="field" onChange={e => e.currentTarget.checked && this.onChangeStatus(STATUS_CONFIRMED)} checked={this.state.status === STATUS_CONFIRMED}/>
								<label htmlFor="r1">검토필요</label>
							</div>

							<div className="radio">
								<input type="radio" id="r2" name="field" onChange={e => e.currentTarget.checked && this.onChangeStatus(STATUS_DONE)} checked={this.state.status === STATUS_DONE}/>
								<label htmlFor="r2">완료</label>
							</div>

							<button id="modal-btn-ok" className="board-btn btn-edit report-btn" onClick={this.onClickSubmit}>저장</button>
						</div>
						<div className="btn-wrap">
							<Link to={`/admin/report/${this.uuid}`}><button id="modal-btn-cancel" className="board-btn btn-cancel" onClick={this.onClickCancel}>취소</button></Link>
						</div>
					</div>

				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;
