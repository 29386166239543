import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';

/** img */
import home from "../../resources/homepage/Home.svg";
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {

	componentDid() {
		window.onscroll = function() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-nav-wrap").className = "sv-nav-fixed";
			} else {
				document.getElementById("sv-nav-wrap").className = "sv-nav";
			}
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-con-wrap").className = "sv-contents-fixed sv-contents";
			} else {
				document.getElementById("sv-con-wrap").className = "sv-contents";
			}
		};
	}

	render() {
		return (
			<div id="wrap" className="sv-long-in">

					{/* header */}
					<HeaderComponent/>

					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title"><strong>위험성평가 컨설팅</strong></h1>
							<div className="location sv-location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<Link to={'/service'}><strong>제공서비스</strong></Link>
								<Link to={'/service?tab=safety'}><strong>안전관리 전문기관</strong></Link>
								<strong>위험성평가 컨설팅</strong>
							</div>
						</div>
					</div>
					<div id="sub-container">
						<div className="section-in sv-in">

							<div className="sv-nav" id="sv-nav-wrap">
								<ul>
									<li className="on"><NavHashLink smooth to={'/service/8ab7a5d8-7c62-4567-b33d-04ffa630dcb6/#sv1'} activeClassName="on">1. 위험성평가 컨설팅</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/8ab7a5d8-7c62-4567-b33d-04ffa630dcb6/#sv2'} activeClassName="on">2. 위반 시 법적 제재 사항</NavHashLink></li>
								</ul>
							</div>

							<div className="sv-contents" id="sv-con-wrap">
								{/* 위험성평가 컨설팅 */}
								<div id="sv1">
									<h6>1. 위험성평가 컨설팅</h6>
									<p>
										<strong>개요</strong>
										사업주는 건설물, 기계·기구·설비, 원재료, 가스, 증기, 분진, 근로자의 작업행동 또는 그 밖의 업무로 인한 유해·위험 요인을 찾아내어 부상 및 질병으로 이어질 수 있는 위험성의 크기가 허용 가능한 범위인지를 평가하여야 하고, 그 결과에 따라 관계법령에 의한 조치와 더불어 근로자에 대한 위험 또는 건강장해를 방지하기 위하여 필요한 경우 추가적인 조치를 하여야 하며, 위험성평가를 실시한 경우에는 평가의 결과와 조치사항을 기록·보존하도록 되어 있습니다.[산업안전보건법 제36조]
									</p>
									<p>
										<strong>안전관리업무 위탁대상</strong>
										상시근로자 50명 이상 사업장(건설업 제외)으로 안전관리자 법적 선임의무가 있는 사업장 및 안전관리업무를 위탁하고자 하는 사업장이 대상에 해당합니다.(산업안전보건법 시행령 별표3)
									</p>
									<p>
										<strong>위험성평가 실시 시기</strong>
										• 위험성평가는 최초평가 및 수시평가, 정기평가로 구분하여 실시<br />
										&nbsp;&nbsp;-  최초평가 및 정기평가는 전체 작업을 대상으로 실시<br />
										• 정기평가는 최초평가 후 매년 정기적으로 실시<br />
										• 수시평가 실시 사유<br />
										&nbsp;&nbsp;-  사업장 건설물의 설치·이전·변경 또는 해체<br />
										&nbsp;&nbsp;-  기계·기구, 설비, 원재료 등의 신규 도입 또는 변경<br />
										&nbsp;&nbsp;-  건설물, 기계·기구, 설비 등의 정비 또는 보수<br />
										&nbsp;&nbsp;-  작업방법 또는 작업절차의 신규 도입 또는 변경<br />
										&nbsp;&nbsp;-  중대산업사고 또는 산업재해(휴업 이상의 요양을 요하는 경우에 한정한다) 발생<br />
										&nbsp;&nbsp;-  그 밖에 사업주가 필요하다고 판단한 경우
									</p>
									<p>
										<strong>업무내용</strong>
										• 위험성평가를 할 수 있도록 위험성평가의 실시 방법·절차 지원<br />
										• 소규모 사업장의 위험성평가 인정 신청방법 및 심사방법 지원<br />
										• 위험성평가를 실시할 수 있도록 하기 위한 교육·컨설팅 지원
									</p>
									<p>
										<strong>용역대가</strong>
										•「엔지니어링산업 진흥법」제31조제2항의 규정에 따라 다음 사항을 고려하여 견적금액을 산출<br />
										&nbsp;&nbsp;-  사업장 인원(건설현장의 규모)<br />
										&nbsp;&nbsp;-  기계·기구 및 설비현황<br />
										&nbsp;&nbsp;-  상시 근로자수 100인 미만 사업장의 경우 인정·비인정 신청 여부(인정제도 참여여부)<br />
										&nbsp;&nbsp;-  원청과 협력업체간 안전보건 공생협력프로그램 실시 여부<br />
									</p>
								</div>

								{/* 안전관리기술지도 */}
								<div id="sv2">
									<h6>2. 위반 시 법적 제재 사항</h6>
									<p>
										<strong style={{paddingBottom:'10px'}}>위반 시 법적 제재 사항</strong>
										<strong className="green">산업안전보건법 시행령 별표 35(과태료의 부과기준)</strong>
										<div className="respon-table">
										<table className="sv-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<thead>
											<tr>
												<th rowSpan={2}>위반행위</th>
												<th rowSpan={2}>세부내용</th>
												<th colSpan={3}>과태료 금액(만원)</th>
											</tr>
											<tr>
												<th>1차</th>
												<th>2차</th>
												<th>3차</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>법 제15조 제1항을 위반하여 사업장을 실질적으로 총괄하여 관리하는 사람으로 하여금 업무를 총괄하여 관리하도록 하지 않은 경우</td>
												<td>법 제175조 제5항 제1호</td>
												<td>300</td>
												<td>400</td>
												<td>500</td>
											</tr>
											<tr>
												<td>법 제16조 제1항을 위반하여 관리감독자에게 직무와 관련된 산업 안전 및 보건에 관한 업무를 수행하도록 하지 않은 경우</td>
												<td>법 제175조 제5항 제1호</td>
												<td>300</td>
												<td>400</td>
												<td>500</td>
											</tr>
											<tr>
												<td>법 제17조 제1항을 위반하여 안전관리자의 업무를 수행하도록 하지 않은 경우</td>
												<td>법 제175조 제5항 제1호</td>
												<td>300</td>
												<td>400</td>
												<td>500</td>
											</tr>
											</tbody>
										</table>
										</div>
									</p>
								</div>

								<div className="sv-subBox">
									현업에서의 풍부한 경험 및 노하우를 바탕으로 산업재해로 인한 근로자의 귀중한 생명 보호와
									공공기관 안전담당자, 기업 내 사업주, 안전관리자의 안전관리 근로생애까지 관리해드리겠습니다.
								</div>

								<div className="sv-goback">
									<Link to={'/service'}>
										<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
									</Link>
								</div>
							</div>

						</div>
					</div>

					{/* footer */}
					<FooterComponent/>

				</div>
		)
	}
}

export default Screen;
