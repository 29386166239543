import React from 'react';
import PropTypes from 'prop-types';
import close from "../../resources/admin/icon/close.svg";
import {api} from "../../api/api";

export default class SafetyTestAddModal extends React.PureComponent {

    static propTypes = {

    };

    state = {
        name: '',
        description: '',
    };

    reset = () => {
        this.setState({name: '', description: ''});
    };

    close = () => {
        typeof this.props.onClose === 'function' && this.props.onClose();
    };

    onClickSubmit = () => {
        if (this.isLockedSubmit) return;
        this.isLockedSubmit = true;

        const release = (message) => {
            alert(message);
            this.isLockedSubmit = false;
        };

        const name = this.state.name;
        if (!name.trim()) return release('이름을 입력해주세요.');

        const description = this.state.description;
        if (!description.trim()) return release('대상범위를 입력해주세요.');

        api.post(`/v1/admin/business-type/${this.props.businessType.uuid}/safety-test`, {name, description}).then((r) => {
            if (!r.ok) return release('등록할 수 없습니다.');

            release('등록 완료');
            this.close();
        }).catch(() => {

        }).finally(() => {
            this.isLockedSubmit = false;
        });
    };

    render() {
        return (
            <div id="basic-modal">
                <div className="modal info-modal">
                    <div id="modal-close" onClick={this.close}><img src={close} width="16px;" alt="닫기" /></div>
                    <h1>대상품명 등록</h1>

                    <div className="modal-in">
                        <ul>
                            <li>
                                <div>대상품명</div>
                                <input
                                    type="text"
                                    id="busniess-name"
                                    placeholder="대상품명을 입력해주세요"
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    value={this.state.name}/>
                            </li>
                            <li>
                                <div>안전검사 대상범위</div>
                                <textarea
                                    placeholder="안전검사 대상범위를 입력하세요."
                                    className="business-textarea"
                                    onChange={(e) => this.setState({description: e.target.value})}
                                    value={this.state.description}/>
                            </li>
                        </ul>
                    </div>

                    <div className="btn-wrap">
                        <button id="modal-btn-cancel" className="modal-btn btn-cancel" onClick={this.close}>취소</button>
                        <button id="modal-btn-ok" className="modal-btn btn-edit" onClick={this.onClickSubmit}>등록</button>
                    </div>
                </div>
            </div>
        )
    }
}

SafetyTestAddModal.propTypes = {
    onClose: PropTypes.func,
}
