import {api} from "../api/api";
import {CommunityAttachment} from "./CommunityAttachment";
import {User} from "./User";
import {action, makeObservable, observable} from "mobx";

export class Community {
  uuid: string;
  id: number;

  created_at: string;
  updated_at: string;

  user_uuid: string;

  title: string;
  content: string;
  thumbnail_url: string;
  alert_level: string;

  // JOIN
  user: User;
  @observable attachments: CommunityAttachment[];

  constructor(community: Community) {
    Object.assign(this, community);
    if (community.attachments && Array.isArray(community.attachments)) this.attachments = community.attachments.map(e => new CommunityAttachment(e));

    makeObservable(this);
  }

  static fetch = (uuid: string, params: Object = {}): Promise<Community> => new Promise((resolve, reject) => {
    return api.get(`/v1/admin/community/${uuid}`, params).then((response) => {
        if (!response.ok) return reject(response);
        if (!response.data.posts) return reject(response);
        return resolve(new Community(response.data.posts));
      }).catch((e) => {
        return reject(e);
      });
  });
  
  static fetchCount = (): Promise<number> => new Promise((resolve, reject) => {
    return api.get('/v1/admin/community/count', {}).then((response) => {
      if (!response.ok) return reject(response);
      const count = parseInt(response.data.count);
      return resolve(count);
    }).catch((e) => {
      console.log(e);
      return reject(e);
    });
  });
  
  static fetchCommunities = (params = {query: undefined, offset: undefined, limit: undefined, od: undefined, ob: undefined}): Promise<Community[]> => new Promise((resolve, reject) => {
    return api.get('/v1/admin/community', params).then((response) => {
      if (!response.ok) return reject(response);
      const posts: Community[] = (response.data.posts || []).map((e) => new Community(e));
      return resolve(posts);
    }).catch((e) => {
      return reject(e);
    });
  });
  
  @action
  deleteAttachment(attachment: CommunityAttachment) {
    this.attachments = this.attachments.filter(e => e.uuid !== attachment.uuid);
  }
}