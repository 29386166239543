export const termsOfService = (
	<>
		<strong>제 1장 총칙</strong><br /><br /><br />

		<strong>제 1 조 약관의 승낙</strong><br />
		주식회사스마트안전에 오신 것을 환영합니다.<br />
		주식회사스마트안전(이하 "회사"또는 “스마트안전”)는 귀하(‘이하 “회원”)가 본 약정(이하 "본 약관") 내용에 동의하는 것을 조건으로 당사는 본 서비스를 제공할 것이며, 당사가 제공하는 모바일 안전관리 서비스(이하 ‘서비스‘)행위( 모바일 앱을 활용한 제반 안전관리 및 지도 ) 및 귀하의 본 서비스 사용 행위는 본 약관에 우선적으로 적용될 것 입니다.<br />
		또한 당사가 발급하는 문서에 대해서는 어떠한 위변조도 없으며 발급한 서류는 안전하게 폐기 처분함을 약속드립니다.<br /><br /><br />

		<strong>제 2 조 목적</strong><br />
		이 약관은 회사가 제공하는 서비스의 이용조건 및 절차, 기타 필요한 사항을 규정함을 목적으로 합니다.<br /><br /><br />

		<strong>제 3 조 약관의 효력과 변경</strong><br />
		(1) 이 약관은 이용자에게 공지사항을 통해 공시하거나 전자우편 기타의 방법으로 회원에게 통지함으로써 효력을 발생합니다.<br />
		(2) 회사는 약관을 변경할 수 있으며, 변경된 약관은 1항과 같은 방법으로 효력을 발생합니다.<br /><br /><br />

		<strong>제 4 조 약관 외 준칙</strong><br />
		이 약관에 명시되지 않은 사항은 행정사법, 행정사법 시행령, 행정사법 시행규칙, 전기통신기본법, 전기통신사업법, 기타 관련법령에 규정되어 있는 경우 그 규정에 따릅니다.<br /><br /><br />

		<strong>제 5 조 용어의 정의</strong><br />
		이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
		(1) 회원 : 회사와 서비스 이용에 관한 계약을 체결한 자<br />
		(2) 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합<br />
		(3) 게시물 : 회원이 주기적으로 작성하는 안전일지인 세이프노트 및 기타 게시물<br />
		(4) 비밀번호 : 회원이 통신상의 자신의 비밀을 보호하기 위해 정한 문자와 숫자의 조합<br />
		(5) 전자우편(E-Mail) : 인터넷을 통한 우편<br />
		(6) 해지 : 회사 또는 회원이 서비스 이용 이후 그 이용계약을 종료시키는 의사표시<br /><br /><br />


		<strong>제2장 회원가입과 서비스 이용</strong><br /><br />

		<strong>제 1 조 서비스 이용계약의 신청</strong><br />
		(1) 이용신청은 서비스이용 신청자가 본 서비스에 대한 이용신청의 제출과 회원약관의 동의로 성립됩니다.<br />
		(2) 회원에 가입하여 서비스를 이용하고자 하는 희망자는 회사에서 요청하는 개인 신상정보를 제공해야 합니다.<br />
		(3) 회원 가입 시에 요구되는 귀하에 대한 정보는 사실로서 명확하고 정확하여야 합니다. 또한, 귀하는 이미 제공된 귀하의 정보가 지속적으로 명확하고 정확하도록 유지, 갱신하여야 합니다.<br /><br /><br />

		<strong>제 2 조 서비스 이용계약의 성립</strong><br />
		(1) 이용계약은 제2장 1조의 서비스이용 신청자의 ‘이용신청’에 대하여 회사가 승낙을 함으로써 성립합니다.<br />
		(2) 이용자의 이용신청에 대하여 회사가 이를 승낙하는 경우, 회사는 회원 ID와 기타 회사가 필요하다고 인정하는 내용을 이용자에게 통지합니다.<br />
		(3) 회사는 다음 각 호에 해당하는 이용계약 신청자에 대하여서는 이를 승낙하지 않을 수 있습니다.<br />
		&nbsp;&nbsp;가. 다른 사람의 명의를 사용하여 신청한 경우<br />
		&nbsp;&nbsp;나. 본인의 실명으로 신청하지 않았거나 내용을 허위로 기재한 경우<br />
		&nbsp;&nbsp;다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우<br />
		&nbsp;&nbsp;라. 기타 회사에서 정한 소정의 이용신청 요건을 충족하지 못하는 경우<br />
		(4) 제1조에 따른 신청에 있어 회사는 회원의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.<br />
		(5) 회사는 산업안전보건법 및 관계 법령에 따른 업무진행의 문제가 있는 경우 승낙을 유보할 수 있습니다.<br />
		(6) 제 3항에 의거하여 이용신청의 승낙을 유보하거나 승낙하지 아니하는 경우, 회사는 이를 이용신청자에게 즉시 알려야 합니다.<br />다만, 회사의 귀책사유 없이 이용신청자에게 통지할 수 없는 경우는 예외로 합니다.<br /><br /><br />

		<strong>제 3 조 서비스 이용 및 제한</strong><br />
		(1) 회사의 업무시간은 공휴일을 제외하며, 오전 9시~오후 6시까지의 업무시간을 기본으로 하나, 온라인을 이용한 서비스 접수는 연중무휴, 1일 24시간을 원칙으로 합니다.<br />
		(2) 본조 (1)항의 이외에는 시스템 정기점검, 기타 고의적인 업무방해, 폭언, 욕설, 비방 등을 통해 타인의 명예를 실추시키거나 불필요한 마찰 및 지속적인 불평과 불만을 통한 위화감 조성으로 인해 당사업무를 방해하거나 타 회원의 업무에 지장을 주는 일부 사용자에게는 사전 경고 없이 서비스 사용을 제한할 수 있습니다.<br /><br /><br />

		<strong>제 4 조 정보의 제공 및 광고의 게재</strong><br />
		(1) 회사는 서비스를 운용함에 있어서 각종 정보를 서비스에 게재하는 방법 등으로 회원에게 제공할 수 있습니다.<br />
		(2) 회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있습니다.<br /><br /><br />


		<strong>제3장 의무</strong><br /><br />

		<strong>제 1 조 회사의 의무</strong><br />
		(1) 회사는 특별한 사정이 없는 한 회원이 신청한 서비스 제공 개시일에 서비스를 이용할 수 있도록 합니다.<br />
		(2) 회사는 제2장 제3조에서 정한 경우를 제외하고 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록 최선의 노력을 다하여야 합니다.<br />
		(3) 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보취급방침을 공시하고 준수합니다. <br />
		(4) 회사는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로 유지·보수하고, 장애가 발생하는 경우 지체 없이 이를 수리·복구할 수 있도록 최선의 노력을 다하여야 합니다.<br />
		(5) 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며, 즉시 처리가 곤란한 경우 그 사유와 처리일정을 서비스 또는 전자우편을 통하여 동 회원에게 통지하여야 합니다.<br /><br /><br />

		<strong>제 2 조 회원의 의무</strong><br />
		(1) ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.<br />
		(2) 회원은 서비스의 일부로 보내지는 전자우편 및 광고 정보를 받는 것에 동의합니다.<br />
		(3) 자신의 ID가 부정하게 사용된 경우, 회원은 반드시 회사에 그 사실을 통보해야 합니다.<br />
		(4) 회원은 회사의 사전 승낙 없이 서비스를 이용하여 어떠한 부가적, 추가적 영리행위를 할 수 없습니다.<br />
		(5) 회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판·방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.<br />
		(6) 회원은 이 약관 및 관계 법령에서 규정한 사항을 준수하여야 합니다.<br />
		(7) 회원은 서비스 이용과 관련하여 다음 각 항의 행위를 하여서는 안됩니다.<br />
		&nbsp;&nbsp;- 다른 회원의 아이디(ID), 신분증, 기타 허위 사실 및 부정 행위<br />
		&nbsp;&nbsp;- 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br />
		&nbsp;&nbsp;- 선량한 풍속, 기타 사회질서를 해하는 행위<br />
		&nbsp;&nbsp;- 타인의 명예를 훼손하거나 모욕하는 행위<br />
		&nbsp;&nbsp;- 타인의 지적재산권 등의 권리를 침해하는 행위<br />
		&nbsp;&nbsp;- 해킹 행위 또는 컴퓨터바이러스의 유포행위<br />
		&nbsp;&nbsp;- 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위<br />
		&nbsp;&nbsp;- 서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위<br />
		&nbsp;&nbsp;- 기타 관계법령에 위배되는 행위<br /><br /><br />

		<strong>제 3 조 게시물의 관리</strong><br />
		(1) 회원의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야 합니다.<br />
		(2) 회사는 전항에 다른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대한 임시조치 등을 취할 수 있습니다.<br />
		(3) 본 조에 다른 세부절차는 정보통신망법 및 저작권법이 규정한 범위 내에서 회사가 정한 게시중단요청서비스에 다릅니다.<br /><br /><br />



		<strong>제 4 장 계약해지 및 서비스 이용 제한 </strong><br /><br />

		<strong>제 1 조 계약 해지 및 서비스 이용제한</strong><br />
		(1) 회원이 이용계약을 해지 하고자 하는 때에는 언제든지 자유롭게 해지할 수 있습니다.<br />
		(2) 가입해지 여부는 기존의 ID,비밀번호로 로그인이 되지 않으면 해지된 것입니다.<br />
		(3) 회사는 회원이 제3장 2조 7항에 해당하는 약관의 내용을 위반하고, 소정의 기간 이내에 이를 해소하지 아니하는 경우 사전 통지 없이 서비스 이용 계약을 해지하거나 또는 기간을 정하여 중지할 수 있습니다.<br />
		(4) 회사는 제 3항에 의하여 해지된 회원이 다시 이용신청을 하는 경우 일정기간 그 승낙을 제한 할 수 있습니다.<br /><br /><br />


		<strong>제 5 장 회원 게시물 관리</strong><br /><br />


		<strong>제 1 조 회원의 게시물 권한 이전  </strong><br />

		이력관리제는 회원이 제출한 자료를 법정의무 내용에 적합한 형태로 편집되거나 수정되어져 저장됨이 원칙이므로 자료의 반환은 거부될 수 있으나,
		사안에 따라 USB나 인쇄 ,복사와 같은 방법으로 이전 가능할 것이며, 이 때 별도의 비용이 발생될 수 있습니다.
		<br /><br /><br />

		<strong>제 2 조 게시물의 삭제</strong><br />
		회사는 스마트안전 서비스 회원이 게시하거나 등록한 내용물이 다음 사항에 해당된다고 판단되는 경우에 사전 통지 없이 삭제할 수 있습니다.<br />
		(1) 타인을 비방하거나 중상모략으로 개인 및 단체의 명예를 손상시키는 내용인 경우<br />
		(2) 공공질서 및 미풍양속에 위반되는 내용인 경우<br />
		(3) 범죄적 행위에 부합된다고 인정되는 내용인 경우<br />
		(4) 타인의 저작권 등 기타의 권리를 침해하는 내용인 경우<br />
		(5) 기타 관계 법령이나 회사에서 정한 규정에 위배되는 내용인 경우<br /><br /><br />


		<strong>제 6 장 면책 및 손해배상</strong><br /><br />

		<strong>제 1 조 손해배상</strong><br />
		회사는 제공되는 서비스와 관련하여 회원에게 손해가 발생할 경우 회사의 중대한 과실에 의한 경우를 제외하고는 이에 대하여 책임을 부담하지 아니합니다. 이때, 배상의 범위는 제공한 서비스 비용을 초과하지 않습니다.<br /><br /><br />

		<strong>제 2 조 면책 및 배상</strong><br />
		(1) 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 않으며, 회원은 자기의 책임 아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사 선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 회원에게 있습니다.<br />
		(2) 회원 간 또는 회원과 제3자간에 서비스를 매개로 하여 물품거래 등과 관련하여 어떠한 책임도 부담하지 않습니다.<br />
		(3) 회원이 이 약관의 규정을 위반함으로 인하여 회사가 회원 또는 제3자에 대하여 책임을 부담하게 되고, 이로써 회사에게 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 회사에 부담하여야 할 손해와 관련 이를 배상하며, 동 손해로부터 회사를 면책시켜야 합니다.<br />
		(4) 회원이 위/변조한 공문서, 사문서를 회사에 제공하여 업무진행을 하고자 할 경우 회사는 이를 '공공의 이익에 반하는 행위'로 보고 즉시 고소, 고발할 수 있으며, 이로 인해 발생하는 '광의의 유무형적 손해'와 관련해서는 손해배상을 청구할 수 있으며 회원은 이에 이의를 제기하지 않기로 동의합니다.<br /><br /><br />

		<strong>제 3 조 분쟁의 해결</strong><br />
		(1) 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.<br />
		(2) 제1항의 규정에도 불구하고, 동 분쟁으로 인하여 소송이 제기될 경우 동 소송은 회사의 본사소재지를 관할하는 법원의 관할로 합니다.<br /><br /><br />


		<strong>부 칙</strong><br /><br />

		<strong>제 1 조 (시행일) 이 약관은 본 회사의 개업일(2021. 5. 17. )부터 시행합니다.</strong>
	</>
);