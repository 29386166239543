import React from 'react';
import HeaderComponent from "./HeaderComponent";
import SideNavigationComponent from "./SideNavigationComponent";

class ContainerComponent extends React.Component {
	render() {
		return (
			<div id="container" style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
				<HeaderComponent/>

				<div style={{flex: 1, display: 'flex', flexDirection: 'row', height: '100%', overflow: 'hidden'}}>
					<SideNavigationComponent/>

					<div style={{flex: 1, backgroundColor: '#fff', overflow: 'scroll'}}>
						{this.props.children}
					</div>
				</div>
			</div>
		);
	}
}

export default ContainerComponent;