import {User} from "./User";
import {api} from "../api/api";
import {CATEGORY_CONSTRUCTION, CATEGORY_MANUFACTURE} from "../constants/agent";
import {action, makeObservable, observable, runInAction} from "mobx";

export class Agent {
	user_uuid: string;
	id: number;
	created_at: string;
	updated_at: string;
	name: string;
	address: string;
	contact_number: string;
	category: string;

	user: User;

	// Extra
	@observable company_count: number;

	constructor(agent: Agent) {
		Object.assign(this, agent);
		if (agent.user) this.user = new User(agent.user);
		makeObservable(this);
	}

	static fetchAgents = (params: Object = {}): Promise<Agent[]> => new Promise((resolve, reject) => {
		return api.get('/v1/admin/agent', params).then((response) => {
			if (!response.ok) return reject(response);
			const agents: Agent[] = (response.data.agents || []).map((e) => new Agent(e));
			return resolve(agents);
		}).catch((e) => {
			return reject(e);
		});
	});

	static fetchCount = (): Promise<number> => new Promise((resolve, reject) => {
		return api.get('/v1/admin/agent/count', {}).then((response) => {
			if (!response.ok) return reject(response);
			const count = parseInt(response.data.count);
			return resolve(count);
		}).catch((e) => {
			console.log(e);
			return reject(e);
		});
	});

	static categoryAlias(category): string {
		switch (category) {
			case CATEGORY_MANUFACTURE: return '제조업';
			case CATEGORY_CONSTRUCTION: return '건설업';
			default: return null;
		}
	}

	static fetchCompanyCount = (user_uuid: string): Promise<number> => new Promise((resolve, reject) => {
		return api.get(`/v1/admin/user/${user_uuid}/company/count`, {}).then((response) => {
			if (!response.ok) return reject(response);
			const count = parseInt(response.data.count);
			return resolve(count);
		}).catch((e) => {
			console.log(e);
			return reject(e);
		});
	});

	@action
	fetchCompanyCount(): Promise<number> {
		return new Promise((resolve, reject) => {
			Agent.fetchCompanyCount(this.user_uuid).then((count) => {
				runInAction(() => {
					this.company_count = count;
				});
				return resolve(count);
			}).catch(reject);
		});
	}

	categoryAlias(): string {
		return Agent.categoryAlias(this.category);
	}
}
