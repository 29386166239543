import React from "react";
import PermissionRoute from "./PermissionRoute";
import {AnimatedSwitch} from "react-router-transition";
import ServiceAScreen from "../screens/service/ServiceAScreen";
import ServiceBScreen from "../screens/service/ServiceBScreen";
import ServiceCScreen from "../screens/service/ServiceCScreen";
import ServiceDScreen from "../screens/service/ServiceDScreen";
import ServiceEScreen from "../screens/service/ServiceEScreen";
import ServiceFScreen from "../screens/service/ServiceFScreen";
import ServiceGScreen from "../screens/service/ServiceGScreen";
import ServiceHScreen from "../screens/service/ServiceHScreen";
import ServiceIScreen from "../screens/service/ServiceIScreen";
import ServiceJScreen from "../screens/service/ServiceJScreen";
import ServiceScreen from "../screens/service/ServiceScreen";

export default ({match}) => (
	<AnimatedSwitch
		atEnter={{ opacity: 0 }}
		atLeave={{ opacity: 0 }}
		atActive={{ opacity: 1 }}
		className="switch-wrapper">
		<PermissionRoute title={'스마트안전 | 서비스'} exact path={`${match.url}`} component={ServiceScreen} authorized unauthorized/>

		<PermissionRoute title={'스마트안전 | 서비스 | 안전관리대행수탁'} path={`${match.url}/75674628-fcfd-4385-8e68-e08745359215`} component={ServiceAScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 위험성평가'} path={`${match.url}/8ab7a5d8-7c62-4567-b33d-04ffa630dcb6`} component={ServiceBScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 근골격계 부담작업 유해원인 조사'} path={`${match.url}/291fc464-38a9-4dce-9e41-d5917ad15eb6`} component={ServiceCScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 제조업 유해위험방지 계획서'} path={`${match.url}/0de13afb-c7e0-4098-ad02-c380f1129726`} component={ServiceDScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 중대재해컨설팅'} path={`${match.url}/dcde013c-42be-4734-ac8e-9ae01e3a58ac`} component={ServiceEScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 건설업 자율안전보건 컨설팅'} path={`${match.url}/f6086a8f-0ef4-4968-a32e-9faa4c9e5266`} component={ServiceFScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 건설업 유해위험방지계획서 작성 컨설팅'} path={`${match.url}/d96470a6-1bfa-4041-8e2f-e567cdcb3ca4`} component={ServiceGScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 안전관리계획서'} path={`${match.url}/ff9b8080-12f7-4762-a740-46bffe288011`} component={ServiceHScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 재해예방기술지도'} path={`${match.url}/051bb2b2-c531-450c-a7fb-fd0a03d793dd`} component={ServiceIScreen} authorized unauthorized/>
		<PermissionRoute title={'스마트안전 | 서비스 | 중대재해컨설팅'} path={`${match.url}/47c1000e-0856-4647-83f2-1609f1f3f5fe`} component={ServiceJScreen} authorized unauthorized/>
	</AnimatedSwitch>
);
