import {Agent} from "./Agent";
import {Company} from "./Company";
import {TYPE_AGENT, TYPE_NORMAL, TYPE_SUPERUSER} from "../constants/user_type";

export class User {
	uuid: string;
	id: number;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	type: string;
	username: string;

	company: Company;
	agent: Agent;

	constructor(user: User) {
		Object.assign(this, user);
		if (user.agent) this.agent = new Agent(user.agent);
		if (user.company) this.company = new Company(user.company);
	}

	get typeAlias() {
		switch (this.type) {
			case TYPE_SUPERUSER: return '최고 관리자';
			case TYPE_AGENT: return '안전 담당자';
			case TYPE_NORMAL: return '안전 담당자';
			default: return null;
		}
	}
}
