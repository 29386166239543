import React from 'react';
import goTop from "../../resources/homepage/top.svg"
import {Link} from "react-router-dom";

export default class ScrollToTopComponent extends React.PureComponent {

	onClick = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	componentDidMount() {
		window.onscroll = function() {
			if (document.body.scrollTop > 90 || document.documentElement.scrollTop > 90) {
				document.getElementById("show").className = "go-top";
			} else {
				document.getElementById("show").className = "go-top-hide";
			}

			if (document.body.scrollTop > 90 || document.documentElement.scrollTop > 90) {
				document.getElementById("down").className = "down-btn";
			} else {
				document.getElementById("down").className = "down-btn-hide";
			}
		};
	}

	render() {
		return (
			<div>
			 <div id="show" className="go-top-hide" onClick={this.onClick}><img src={goTop} width="100%" alt="홈페이지 상단으로 가기" /></div>
			 <Link to={''} id="down" class="down-btn-hide">APP<br />다운로드</Link>
			</div>
		)
	}
}
