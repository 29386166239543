import React from 'react';
import Lottie from "react-lottie";
import PropTypes from 'prop-types';

class ListLoadingComponent extends React.PureComponent {
	render() {
		if (!this.props.visible) return null;

		return (
			<div className="ad-loading">
				<Lottie
					width={200}
					height={150}
					options={this.props.options || {
						loop: true,
						autoplay: true,
						animationData: require('../../../animations/51-preloader.json'),
					}}
				/>
				<p style={{marginTop: 8}}>데이터를 가져오는 중...</p>
			</div>
		);
	}
}

ListLoadingComponent.defaultProps = {
	visible: true,
};

ListLoadingComponent.propTypes = {
	visible: PropTypes.bool,
	options: PropTypes.object,
};

export default ListLoadingComponent;