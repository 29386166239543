export const privacyPolicy = (
	<p>
		주식회사스마트안전(이하“스마트안전”)은 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하여 정보주체의 개인정보보호 및 권익을 보호하고 개인정보와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 ｢개인정보보호법｣ 제30조 제1항 및 같은 법 ｢시행령｣ 제31조 제1항의 규정에 따라 홈페이지에 ｢개인정보 처리방침｣을 정하여 운영하고 있습니다.<br />또한, 스마트안전이 처리하는 개인정보는 개인정보의 처리 목적에 필요한 최소한의 정보임을 밝힙니다. 이 방침은 별도의 설명이 없는 한 공단에서 운용하는 모든 웹 사이트에 적용됨을 알려드립니다.<br /><br /><br />

		<strong>제1조 개인정보의 수집 및 이용목적</strong><br />
		스마트안전은 수집된 이용자의 개인정보를 다음의 목적을 위해 이용하고 있습니다.<br />
		가. 산업안전보건법 및 중대재해처벌등에 따른 법률 등에 따른 안전관리를 위한 사업체 기본 정보파악<br />
		나. 문의사항 또는 불만처리 등 민원처리, 고지사항 전달, 분쟁 조정을 위한 기록 보존<br />
		다. 개인 식별, 불량이용자의 부정이용 방지와 비인가 사용 방지, 가입 의사 확인, 가입 및 가입회수 제한, 추후 법정 대리인 동의여부 확인<br />
		라. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산, 콘텐츠 및 이용자 맞춤형 서비스 제공<br /><br /><br />

		<strong>제2조 수집하는 개인 정보 항목</strong><br />
		스마트안전은 원활한 고객 상담, 기타 각종 서비스 등 서비스 제공을 위한 필수정보를 수집하고 있습니다.<br />
		가. 개인정보 항목(입력 항목) : 이름, 연락처(일반전화 및 휴대전화), 이메일, 사업장 정보(상호명, 주소, 연락처, 직위, 업종, 근로자수 등) <br />
		나. 수집방법: 홈페이지 신청 및 고객센터를 통한 전화 및 온라인 상담<br /><br /><br />

		<strong>제3조 개인정보의 처리 및 보유기간</strong><br />
		이용자의 개인정보를 수집 및 이용목적, 이용기간에만 제한적으로 이용하고 있으며, 동의를 철회하는 경우, 보유 및 이용기간이 종료한 경우 등에 해당할 시 개인정보를 지체 없이 파기합니다.<br />
		이 경우 개인정보는 어떠한 용도로도 열람 또는 이용할 수 없도록 처리됩니다. 다만 관계법령에 의해 보존해야 하는 정보는 아래와 같이 법령이 정한 기간 동안 보관됩니다.<br />

		가. 표시/광고에 관한 기록 : 6개월(전자상거래 등에서의 소비자보호에 관한 법률)<br />
		나. 계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)<br />
		다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)<br />
		라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)<br />
		기타, 이용자의 개별적인 동의가 있는 경우에는 개별 동의에 따른 기간까지 보관합니다.<br /><br /><br />

		<strong>제4조 개인정보의 제3자 제공 및 공유</strong><br />
		스마트안전은 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br />
		가. 이용자들이 사전에 동의한 경우<br />
		나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사 기관의 요구가 있는 경우<br />
		다. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br />
		라. 법원의 재판업무 수행을 위하여 필요한 경우<br />
		마. 형 및 감호, 보호처분의 집행을 위하여 필요한 경우<br />
		이외 보다 나은 서비스의 제공을 위하여 이용자의 개인정보를 공유할 필요가 있는 경우에는 제공받는 자, 제공 목적 및 제공할 정보의 내용 등을 상세히 이용자에게 개별 고지하여 동의를 구하도록 하겠습니다. <br /><br /><br />

		<strong>제5조 개인정보처리의 위탁에 관한 사항</strong><br />
		스마트안전은 서비스 향상을 위해서 이용자의 개인정보가 필요한 경우 동의 등 법률상의 요건을 구비하여 외부에 수집·취급·관리 등을 위탁하여 처리할 수 있으며, 제 3자에게 제공할 수 있습니다.<br />
		스마트안전은 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.<br />
		또한 공유하는 정보는 당해 목적을 달성하기 위하여 필요한 최소한의 정보에 국한됩니다.<br />
		스마트안전은 이용자의 개인정보를 당사가 제공하는 인터넷 서비스 외의 용도로 사용하거나 이용자의 동의 없이 제3자에게 제공하지 않으며, 필요에 의하여 제3자에게 제공할 경우에는 이용자에게 알리고 별도의 동의를 받습니다.<br />다만, 다음 각호의 경우에는 예외로 합니다.<br />

		가. 당사의 이용약관을 위배하거나 서비스를 이용하여 타인에게 피해를 주거나 미풍양속을 해치는 위법행위를 한 사람 등에게 법적인 조치를 취하기 위해 개인정보를 공개해야 한다고 판단되는 경우<br />
		나. 법령의 규정에 의하거나, 수사상의 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br />
		다. 통계 작성, 학술연구, 시장 조사, 정보 제공 및 공지 안내 메일 발송의 경우로서 특정 개인을 식별할 수 없는 형태로 제공되는 경우<br /><br /><br />

		<strong>제6조 정보주체와 법정대리인의 권리･의무 및 그 행사방법에 관한 사항</strong><br />
		가. 정보주체는 스마트안전에 대해 다음과 같은 권리를 행사할 수 있으며, 만 14세 미만 아동의 법정대리인은 해당 아동의 개인정보에 대한 열람, 정정‧삭제, 처리정지를 요구할 수 있습니다.<br />
		나. 개인정보 열람요구<br />
		스마트안전에서 보유하고 있는 개인정보파일은 ｢개인정보보호법｣ 제35조에 따라 본인의 개인정보에 대한 열람을 요구할 수 있습니다.<br />
		다만, 개인정보 열람 요구는 ｢개인정보보호법｣ 제35조 제5항에 의하여 다음과 같이 제한될 수 있습니다.<br />
		&nbsp;&nbsp;1. 법률에 따라 열람이 금지되거나 제한되는 경우<br />
		&nbsp;&nbsp;2. 다른 사람의 생명‧신체를 해할 우려가 있거나, 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우<br />

		다. 개인정보 정정‧삭제 요구<br />
		스마트안전에서 보유하고 있는 개인정보파일은 ｢개인정보보호법｣ 제36조에 따라 정정‧삭제를 요구할 수 있습니다.<br />
		다만, 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br />

		라. 개인정보 처리정지 요구<br />
		스마트안전에서 보유하고 있는 개인정보파일은 ｢개인정보보호법｣ 제37조에 따라 처리정지를 요구할 수 있습니다.<br />
		다만, 개인정보 처리정지 요구 시 ｢개인정보보호법｣ 제37조 제2항에 의하여 다음의 경우 처리정지 요구가 거절될 수 있습니다.<br />

		&nbsp;&nbsp;1. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우 다른 사람의 생명‧신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우<br />
		&nbsp;&nbsp;2. 스마트안전이 개인정보를 처리하지 아니하면 다른 법률에서 정하는 소관업무를 수행할 수 없는 경우<br />

		&nbsp;&nbsp;3. 개인정보를 처리하지 아니하면 정보주체와 약정한 서비스를 제공하지 못하는 등 계약의 이행이 곤란한 경우로서 정보주체가 그 계약의 해지의사를 명확하게 밝히지 아니한 경우<br />

		마. 개인정보 열람 등 요구방법<br />

		&nbsp;&nbsp;1. 제1항에 따른 권리 행사는 ｢개인정보보호법｣ 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, FAX 등을 통하여 하실 수 있으며, 스마트안전은 신청자의 신분을 확인 후 지체 없이 조치하겠습니다.<br />

		&nbsp;&nbsp;2. 정보주체 본인의 열람/증명 접수 처리 시에는 고객의 신분을 나타내는 주민등록증, 여권, 운전면허증 등의 신분증명서를 제출받아 본인 여부를 확인합니다.<br />

		&nbsp;&nbsp;3. 정보주체 대리인이 방문하여 열람/증명을 요구하는 경우에는 대리관계를 나타내는 위임장 및 명의 고객의 인감증명서와 대리인의 신분증명서 등의 증표를 제시받아 진정한 대리인인지 여부를 확인합니다.<br />

		&nbsp;&nbsp;4. 개인정보의 전부 또는 일부에 대하여 열람/증명 또는 정정을 거절할 이유가 있는 경우에는 고객에게 이를 통지하고 그 이유를 설명합니다.<br />

		바. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br />

		사. 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 제1항에 대한 권리 행사를 하는 경우 ｢개인정보보호법｣ 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br />
		&nbsp;&nbsp;* [개인정보보호법 시행규칙 별지 제8호] 개인정보 열람 요구서<br />
		&nbsp;&nbsp;* [개인정보보호법 시행규칙 별지 제11호] 위임장<br /><br /><br />

		<strong>제7조 개인정보 자동수집 장치의 설치･운영 및 거부에 관한 사항</strong><br />

		가. 스마트안전은 이용자에게 개인형 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.<br />

		나. 쿠키는 웹사이트(홈페이지)를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br />

		&nbsp;&nbsp;1. 쿠키의 사용 목적 : 자주 찾는 서비스를 설정할 수 있도록 하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br />

		&nbsp;&nbsp;2. 쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.<br />

		&nbsp;&nbsp;3. 쿠키 저장을 거부할 경우 개인형 서비스 이용에 어려움이 발생할 수 있습니다.<br />

		다. 웹사이트(홈페이지) 서비스 이용과정에서 아래의 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.<br />

		&nbsp;&nbsp;- 서비스 이용기록, 방문기록, 불양 이용기록, IP주소 등<br /><br /><br />

		<strong>제8조 개인정보의 파기절차 및 파기방법</strong><br />
		가. 파기절차<br />
		&nbsp;&nbsp;- 불필요한 개인정보 및 개인정보파일은 개인정보파기 계획을 수립하여 파기합니다.<br />

		&nbsp;&nbsp;- 파기 사유가 발생한 개인정보(또는 개인정보파일)를 선정하고, 개인정보보호책임자의 승인을 받아 개인정보(또는 개인정보파일)를 파기합니다.<br />

		&nbsp;&nbsp;- 개인정보는 목적 달성 후 즉시 또는 별도 공간에 옮겨져 내부방침 및 기타 관련법령에 따라 일정기간 보유한 후 파기되며, 별도의 공간으로 옮겨진 개인정보는 벌률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.<br />

		&nbsp;&nbsp;&nbsp;&nbsp;·개인정보의 파기보유기간이 경과하거나 처리목적 달성한 개인정보는 종료일로부터 지체 없이 파기합니다.<br />

		&nbsp;&nbsp;&nbsp;&nbsp;·개인정보파일의 파기개인정보파일의 보유기간이 만료되었거나 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보파일이 불필요하게 되었을 때에는 지체 없이 그 개인정보파일을 파기합니다.<br />

		나. 파기방법<br />
		&nbsp;&nbsp;- 전자적 파일 형태로 기록‧저장된 개인정보는 기록을 재생할 수 없도록 로우레벨포맷(Low Level Formt) 등의 방법을 이용하여 파기합니다.<br />

		&nbsp;&nbsp;- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br /><br /><br />

		<strong>제9조 개인정보의 안전성 확보조치에 관한 사항</strong><br />
		가. 개인정보 취급직원의 최소화 및 교육<br />
		개인정보를 취급하는 직원은 반드시 필요한 인원에 한하여 지정·관리하고 있으며 취급직원을 대상으로 안전한 관리를 위한 교육을 실시하고 있습니다.<br />

		나. 개인정보에 대한 접근 제한<br />
		개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의 부여·변경·말소를 통하여 개인정보에 대한 접근통제를 위한 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br />

		다. 접속기록의 보관<br />
		개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관·관리하고 있습니다.<br />

		라. 개인정보의 암호화<br />
		개인정보는 암호화 등을 통해 안전하게 저장 및 관리되고 있습니다.<br />또한, 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br />

		마. 보안프로그램 설치 및 주기적 점검·갱신<br />
		해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적으로 갱신·점검하고 있습니다.<br /><br /><br />

		<strong>제10조. 정보주체의 권익침해에 대한 구제방법</strong><br />
		가. 정보주체는 아래의 기관에 개인정보 침해에 대한 피해구제, 상담 등을 문의할 수 있습니다.<br />

		나. 아래의 기관은 스마트안전과 별개의 기관으로서, 스마트안전의 자체적인 개인정보 불만처리, 피해 구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.<br />

		&nbsp;&nbsp;▶ 개인정보 침해신고센터<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 소관업무: 개인정보 침해사실 신고, 상담<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 인터넷 신고: privacy.kisa.or.kr<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 전자우편 신고: privacyclean@kisa.or.kr<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 전화 신고: 118(ARS 내선 2번)<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 팩스 신고: 061-8720-2619<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 방문/우편 신고: (58324)전라남도 나주시 진홍길 9(빛가람동 301-2) 3층 한국인터넷 진흥원 개인정보침해신고센터<br /><br />

		&nbsp;&nbsp;▶ 개인정보 분쟁조정위원회<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 소관업무: 개인정보 분쟁조정 및 집단 분쟁조정 신청, 상담<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 온라인 신청: www.kopico.go.kr<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 방문/우편 신고: (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층 개인정보분쟁조정위원회(☎1833-6972)<br /><br />

		&nbsp;&nbsp;▶ 대검찰청 과학수사부 사이버수사과<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 소관업무: 각종 사이버범죄 수사/수사지원 전담<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 인터넷 신고: cybercid@spo.go.kr<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 전화: (국번없이) 1301, 02-3480-3570<br /><br />

		&nbsp;&nbsp;▶ 경찰청 사이버안전국<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 소관업무: 개인정보 침해 관련 사이버 범죄 신고, 상담<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 인터넷 신고: cyberbureau.police.go.kr<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 전화: 182 (경찰민원 콜센터)<br /><br /><br />

		<strong>제11조 개인정보의 열람청구를 접수･처리하는 부서</strong><br />
		개인정보보호책임자의 성명 또는 개인정보 보호업무 및 관련 고충사항을 처리하는 부서의 명칭과 연락처<br />

		가. 스마트안전은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 두고 있습니다.<br />

		나. 개인정보보호 책임자 및 담당자<br />
		&nbsp;&nbsp;부서/성명/ 연락처 : 개인정보보호책임자/ 최영윤/<br />
		&nbsp;&nbsp;Tel) 051-714-2898<br />
		&nbsp;&nbsp;Fax) 051-714-2290<br />
		&nbsp;&nbsp;Email) smart-safety.daum.net<br /><br /><br />

		<strong>제12조 개인정보 처리방침의 변경</strong><br />
		&nbsp;&nbsp;① 이 개인정보처리방침은 2021년 5월 17일부터 시행합니다.<br />
		&nbsp;&nbsp;② 이전의 개인정보처리방침은 아래에서 확인할 수 있습니다.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;- 이전 개인정보 처리방침 보기<br />
	</p>
);