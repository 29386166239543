import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
import clock from "../../../../resources/admin/icon/clock.svg";
import phone from "../../../../resources/admin/icon/phone.svg";
/** img */
/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';
import {Link} from "react-router-dom";
import {Question} from "../../../../models/Question";
import moment from "moment";
import {Answer} from "../../../../models/Answer";
import {AnswerComponent} from "../../../../components/admin/qna/AnswerComponent";
import {api} from "../../../../api/api";

/** CSS */

class Screen extends React.PureComponent {

	state = {
		question: new Question(),
	};

	get uuid() {
		return this.props.match?.params?.uuid;
	}

	componentDidMount() {
		this.fetch();
	}

	fetch = () => {
		if (this.isLockedFetch) return;

		this.isLockedFetch = true;
		try {
			Question.fetch(this.uuid, {join_user: true, join_attachments: true, join_answers: true}).then((question) => {
				this.setState({isFetching: true}, () => {
					this.setState({isFetching: false, question}, () => {
						this.isLockedFetch = false;
					});
				});
			})

		} catch (e) {
			this.isLockedFetch = false;
		}
	};

	onAnswerPublished = () => {
		this.fetch();
	};

	onClickDelete = () => {
		if (window.confirm('정말 삭제하시겠습니까?')) {
			api.delete(`/v1/admin/question/${this.uuid}`).then((r) => {
				if (!r.ok) return alert('삭제실패');
				alert('삭제성공');
				this.props.history.push('/admin/question');
			}).catch(e => {
				alert('삭제실패');
			});
		}
	};

	renderAnswers = () => {
		const question = this.state.question;
		if (!question || !question.uuid) return null;

		const answers: Answer[] = question.answers;
		return answers.map((answer, index) => (
			<li key={answer.uuid}>
				<div className="answer-top">
					<strong>답변내용</strong>
					<span className="b-line"/>
					<small>{answer?.created_at ? moment.unix(answer.created_at).format('YYYY-MM-DD hh:mm:ss') : '-'}</small>
				</div>

				<p>{answer.text}</p>
			</li>
		));
	};

	render() {
		const question: Question = this.state.question;

		return (
			<ContainerComponent>
				{(question && question.uuid) &&
				<div>
					<div id="ad-contents" className="write">
						<h1 className="contents-title"><strong className="green">사용자.</strong>{`${question?.user?.username}님의 문의사항입니다`}</h1>
						<div className="write-info">
							<span>{question?.user?.company?.name}</span>
							<span><img src={clock} width="14px" alt="날짜" /> {question.created_at ? moment.unix(question.created_at).format('YYYY-MM-DD') : '-'}</span>
							<span><img src={phone} width="15px" alt="연락처" /> {question.user?.company?.contact_number || '-'}</span>
						</div>

						<p className="user-write">{question.text}</p>
						<ul className="answer">
							{this.renderAnswers()}
						</ul>

						{question.attachments.map((e) => {
							if (e?.mime?.indexOf('heic') === -1) {
								if (e?.mime?.startsWith('image')) {
									return (
										<img key={e.uuid} style={{ width: '30%', height: 'auto'}} src={e.url} alt={'첨부 이미지'}/>
									)
								}
							}

							return (
								<a href={e.url} target='_blank' rel={'noreferrer'}>
									<div style={{display: 'inline-block', width: '30%', height: 'auto', backgroundColor: '#DDD'}}>
										{e.name}
									</div>
								</a>
							)
						})}

						<AnswerComponent question={question} onPublished={this.onAnswerPublished}/>

						<div className="btn-wrap">
							<button className="view-btn second" onClick={this.onClickDelete}>삭제</button>
							<Link to={'/admin/question'}><button className="right view-btn qna-btn second">목록</button></Link>
						</div>
					</div>
				</div>}
			</ContainerComponent>
		);
	}
}

export default Screen;
