import React from 'react';
import {Link} from 'react-router-dom';
import {inject} from "mobx-react";
import {TYPE_SUPERUSER} from "../../../constants/user_type";
import link from '../../../resources/admin/icon/link.svg';

class SideNavigationComponent extends React.PureComponent {

	get isSuperuser(): boolean {
		return this.props.sessionStore?.user?.type === TYPE_SUPERUSER;
	}

	render() {
		const isSuperuser = this.isSuperuser;
		return (
			<div id="ad-menu">
				<ul>
					<Link to={'/admin'}>
						<li>대시보드</li>
					</Link>

					{isSuperuser &&
					<Link to={'/admin/agent'}>
						<li>안전 담당자 목록</li>
					</Link>}

					<Link to={'/admin/company'}>
						<li>업체 목록</li>
					</Link>

					{isSuperuser &&
					<Link to={'/admin/business-type'}>
						<li>업종 관리</li>
					</Link>}

					{this.props.sessionStore?.user?.type === TYPE_SUPERUSER &&
					<a href={'https://admin.iamport.kr'} target={'_blank'}>
						<li>매출 통계 <img src={link} width="15px" alt="매출통계 바로가기" style={{marginTop:"-4px"}}/></li>
					</a>}

					<Link to={'/admin/report'}>
						<li>보고서 목록</li>
					</Link>

					<li>
						<span>고객지원</span>
						<Link to={'/admin/notice'}>공지사항</Link><br/>
						<Link to={'/admin/question'}>1:1 문의내역</Link>
					</li>

					<li>
						<span>커뮤니티</span>
						<Link to={'/admin/community'}>안전하는사람들(사랑방)</Link>
					</li>
				</ul>
			</div>
		);
	}
}

export default inject('sessionStore')(SideNavigationComponent);