import {BusinessType} from "./BusinessType";

export class SafetyTest {

	uuid: string;
	id: number;
	created_at: string;
	updated_at: string;
	business_type_uuid: string;
	name: string;
	description: string;

	// JOIN
	business_type: BusinessType;

	constructor(safetyTest: SafetyTest) {
		Object.assign(this, safetyTest);
	}
}
