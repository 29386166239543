import React from 'react';
import {Link} from "react-router-dom";
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";

/** img */
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {
	render() {
		return (
			<div id="wrap">

				{/* header */}
				<HeaderComponent/>

				<div className="sv-goback">
					<Link to={'/service'}>
						<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
					</Link>
				</div>

				<FooterComponent/>
			</div> 
		)
	}
}

export default Screen;
