import React, {createRef} from 'react';

/** img */
import logo from '../../../resources/admin/logo/ad_logo.svg';

/** CSS */
import '../../../css/common.css';
import '../../../css/admin/form.css';
import '../../../css/admin/login.css';
import '../../../css/admin/modal.css';
import {inject} from "mobx-react";
import {api} from "../../../api/api";

class SignInScreen extends React.PureComponent {

	ref_username = createRef();
	ref_password = createRef();

	state = {
		username: '',
		password: '',
	};

	componentDidMount() {
		try {
			api.delete('/v1/auth/session').then((r) => {
				// if (!r.ok) return alert('로그아웃 실패');

				api.deleteHeader('Authorization');

				try {
					typeof this.props.sessionStore?.reset === 'function' && this.props.sessionStore?.reset();
				} catch (e) {}

				try {
					sessionStorage.removeItem('Authorization');
					localStorage.removeItem('Authorization');
				} catch (e) { console.warn(e) }

				this.props.history.push('/v1/admin/sign-in');
			});
		} catch (e) {
			console.error(e);
			alert('오류발생');
			this.props.history.push('/');
		}
	}

	render() {
		return (
			<div>
				{/* 로그인 기본화면 */}
				<div id="admin-login">
					<div className="ad-loginIn">
						<h6>사업장 안전관리 솔루션</h6>
						<div className="ad logo">
							<img src={logo} width="280px" alt="스마트안전 로고"/>
						</div>
						<div className="input-wrap">
							<input ref={this.ref_username} type="text" id="ad-ID" placeholder="고유번호를 입력해주세요" value={this.state.username} onChange={this.onChangeUsername}/>
							<input ref={this.ref_password} type="password" id="ad-PW" placeholder="비밀번호를 입력해주세요" value={this.state.password} onChange={this.onChangePassword}/>
						</div>
						<span>계정분실문의 : 051-714-2898</span>
						<button onClick={this.onClickSubmit}>로그인</button>
					</div>
				</div>

				<div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>

				</div>
			</div>
		);
	}
}

export default inject(['sessionStore'])(SignInScreen);
