import React from 'react';
import PropTypes from 'prop-types';
import close from "../../resources/admin/icon/close.svg";
import {api} from "../../api/api";
import {ENDPOINTS} from "../../api/endpoints";

export default class BusinessTypeAddModal extends React.PureComponent {

    state = {
        name: '',
        ticket_type: null,
    };

    close = () => {
        typeof this.props.onClose === 'function' && this.props.onClose();
    };

    onClickSubmit = () => {
        if (this.isLockedSubmit) return;
        this.isLockedSubmit = true;

        const release = (message) => {
            alert(message);
            this.isLockedSubmit = false;
        };

        const name = this.state.name;
        if (!name.trim()) return release('이름을 입력해주세요.');

        const ticket_type = this.state.ticket_type;
        if (ticket_type == null) return release('이용권 유형을 선택하세요');

        api.post(ENDPOINTS.BUSINESS_TYPE_ADD, {name, ticket_type}).then((r) => {
            if (!r.ok) return release('등록할 수 없습니다.');

            release('등록 완료');
            this.close();
        }).catch(() => {

        }).finally(() => {
            this.isLockedSubmit = false;
        });
    };

    onChangeName = (e) => {
        this.setState({name: e.target.value});
    };

    onChangeRadio = (name, e) => {
        if (e.target.value) {
            this.setState({ticket_type: name});
        }
    };

    render() {
        return (
            <div id="basic-modal">
                <div className="modal info-modal">
                    <div id="modal-close" onClick={this.close}><img src={close} width="16px;" alt="닫기" /></div>
                    <h1>업종 등록</h1>

                    <div className="modal-in">
                        <ul>
                            <li>
                                <div>업종명</div>
                                <input
                                    type="text"
                                    id="busniess-name"
                                    placeholder="업종명을 입력해주세요"
                                    onChange={this.onChangeName}
                                    value={this.state.name}/>
                            </li>
                            <li>
                                <div>분야</div>
                                <div className="radio">
                                    <input type="radio" id="r1" name="field" onChange={(e) => this.onChangeRadio('employees', e)} value={this.state.ticket_type === 'employees'}/>
                                    <label htmlFor="r1">근로자 수</label>
                                </div>
                                <div className="radio">
                                    <input type="radio" id="r2" name="field" onChange={(e) => this.onChangeRadio('sales', e)} value={this.state.ticket_type === 'sales'}/>
                                    <label htmlFor="r2">공사 금액</label>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="btn-wrap">
                        <button id="modal-btn-cancel" className="modal-btn btn-cancel" onClick={this.close}>취소</button>
                        <button id="modal-btn-ok" className="modal-btn btn-edit" onClick={this.onClickSubmit}>등록</button>
                    </div>
                </div>
            </div>
        )
    }
}

BusinessTypeAddModal.propTypes = {
    onClose: PropTypes.func,
}
