import React, {createRef} from 'react';

/** img */
import logo from '../../../resources/admin/logo/ad_logo.svg';
import close from '../../../resources/admin/icon/close.svg';

/** CSS */
import '../../../css/common.css';
import '../../../css/admin/form.css';
import '../../../css/admin/login.css';
import '../../../css/admin/modal.css';
import {inject} from "mobx-react";
import {api} from "../../../api/api";
import {User} from "../../../models/User";
import {TYPE_AGENT, TYPE_SUPERUSER} from "../../../constants/user_type";
import Lottie from "react-lottie";

class SignInScreen extends React.PureComponent {

	ref_username = createRef();
	ref_password = createRef();

	state = {
		username: '',
		password: '',

		/* Modal */
		error: null,
	};

	dismissErrorModal = () => {
		this.setState({error: null});
	};

	onClickSubmit = () => {
		if (this.isLockedSubmit) return;
		this.isLockedSubmit = true;

		const release = (state, callback) => {
			this.setState({isLoading: false, ...state}, () => {
				this.isLockedSubmit = false;
				typeof callback === 'function' && callback();
			})
		};

		this.setState({isLoading: true}, () => {
			const username = this.state.username;
			const password = this.state.password;

			if (!username) return release({error: '계정을 입력해주세요.'});
			if (!password) return release('비밀번호를 입력해주세요');

			this.props.sessionStore.signIn(username, password, true).then(() => {
				api.get('/v1/user/me', {}).then((r) => {
					const user = new User(r.data.user);
					if (![TYPE_SUPERUSER, TYPE_AGENT].includes(user.type)) {
						console.log('Not allowed to access this route', user.type);
						alert('Not allowed to access this route');
						this.props.history.push('/');
						return;
					}

					try {
						this.props.sessionStore.setUser(user);
					} catch (e) { console.error(e) }

					this.props.history.push('/admin');
				});
			}).catch((e) => {
				switch (e) {
					case 'TOKEN_NOT_EXISTS': {
						release({error: '로그인 실패(T404)'});
						break;
					}
					default: {
						release({error: '로그인 실패'});
						break;
					}
				}
			});
		});
	};

	onClickModalClose = () => {
		this.dismissErrorModal();
	};

	onClickModalPositive = () => {
		this.dismissErrorModal();
	};

	onChangeUsername = (event) => {
		this.setState({
			username: event.target.value.replace(' ', ''),
		});
	};

	onChangePassword = (event) => {
		this.setState({
			password: event.target.value,
		});
	};

	render() {
		return (
			<div>
				{/* 로그인 기본화면 */}
				<div id="admin-login">
					<div className="ad-loginIn">
						<h6>사업장 안전관리 솔루션</h6>
						<div className="ad logo">
							<img src={logo} width="280px" alt="스마트안전 로고"/>
						</div>
						<div className="input-wrap">
							<input ref={this.ref_username} type="text" id="ad-ID" placeholder="고유번호를 입력해주세요" value={this.state.username} onChange={this.onChangeUsername} onKeyPress={e => e.key === 'Enter' && this.ref_password?.current?.focus()}/>
							<input ref={this.ref_password} type="password" id="ad-PW" placeholder="비밀번호를 입력해주세요" value={this.state.password} onChange={this.onChangePassword} onKeyPress={e => e.key === 'Enter' && this.onClickSubmit()}/>
						</div>
						<span>계정분실문의 : 051-714-2898</span>
						<button onClick={this.onClickSubmit}>로그인</button>
					</div>
				</div>

				{/* 로그인 고유번호,비밀번호 오류 modal  */}
				{this.state.error &&
				<div id="basic-modal">
					<div className="modal fail-modal">
						<div id="modal-close" onClick={this.onClickModalClose}>
							<img src={close} width="16px;" alt="닫기" />
						</div>
						<span>{this.state.error}</span>
						<div>
							<button id="modal-btn-ok" className="modal-btn btn-ok" onClick={this.onClickModalPositive}>확인</button>
						</div>
					</div>
				</div>}

				{this.state.isLoading &&
				<div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(0,0,0,0.85)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
					<Lottie
						width={200}
						height={150}
						options={this.props.options || {
							loop: true,
							autoplay: true,
							animationData: require('../../../animations/51-preloader.json'),
						}}/>

					<p style={{fontSize: 18, fontWeight: 'bold', color: '#FFF'}}>로그인하는 중 ...</p>
				</div>}
			</div>
		);
	}
}

export default inject(['sessionStore'])(SignInScreen);
