import {Notice} from "./Notice";

export class NoticeAttachment {
  uuid: string;
  id: number;

  created_at: string;

  notice_uuid: string;

  name: string;
  url: string;
  mime: string;

  // JOIN
  notice: Notice;

  constructor(attachment: NoticeAttachment) {
    Object.assign(this, attachment);
  }
}
