import {api} from "../api/api";
import {NoticeAttachment} from "./NoticeAttachment";
import {User} from "./User";
import {action, makeObservable, observable} from "mobx";

export class Notice {
  uuid: string;
  id: number;

  created_at: string;
  updated_at: string;

  user_uuid: string;

  title: string;
  content: string;
  thumbnail_url: string;
  alert_level: string;

  // JOIN
  user: User;
  @observable attachments: NoticeAttachment[];

  constructor(notice: Notice) {
    Object.assign(this, notice);
    if (notice.attachments && Array.isArray(notice.attachments)) this.attachments = notice.attachments.map(e => new NoticeAttachment(e));

    makeObservable(this);
  }

  static fetch = (uuid: string, params: Object = {}): Promise<Notice> => new Promise((resolve, reject) => {
    return api.get(`/v1/notice/${uuid}`, params).then((response) => {
      if (!response.ok) return reject(response);
      if (!response.data.notice) return reject(response);
      return resolve(new Notice(response.data.notice));
    }).catch((e) => {
      return reject(e);
    });
  });

  static fetchCount = (): Promise<number> => new Promise((resolve, reject) => {
    return api.get('/v1/notice/count', {}).then((response) => {
      if (!response.ok) return reject(response);
      const count = parseInt(response.data.count);
      return resolve(count);
    }).catch((e) => {
      console.log(e);
      return reject(e);
    });
  });

  static fetchNotices = (params = {query: undefined, offset: undefined, limit: undefined, od: undefined, ob: undefined}): Promise<Notice[]> => new Promise((resolve, reject) => {
    return api.get('/v1/notice', params).then((response) => {
      if (!response.ok) return reject(response);
      const notices: Notice[] = (response.data.notices || []).map((e) => new Notice(e));
      return resolve(notices);
    }).catch((e) => {
      return reject(e);
    });
  });

  @action
  deleteAttachment(attachment: NoticeAttachment) {
    this.attachments = this.attachments.filter(e => e.uuid !== attachment.uuid);
  }
}
