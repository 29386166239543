import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

/** img */
/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';
import {Link} from "react-router-dom";
import {Notice} from "../../../../models/Notice";
import {api} from "../../../../api/api";
import {Editor} from "react-draft-wysiwyg";
import {stateToHTML} from "draft-js-export-html";
import {ContentState, EditorState} from "draft-js";
import htmlToDraft from 'html-to-draftjs';
import parse from 'html-react-parser';
import {NoticeAttachment} from "../../../../models/NoticeAttachment";
import {observer} from 'mobx-react';

class Screen extends React.PureComponent {

	state = {
		notice: new Notice({}),
		is_modifying: false,
		title: '',
		editorState: EditorState.createEmpty(),
	};

	get uuid() {
		return this.props.match?.params?.uuid;
	}

	componentDidMount() {
		this.fetch();
	}

	fetch = () => {
		if (this.isLockedFetch) return;

		this.isLockedFetch = true;
		this.setState({isFetching: true}, () => {
			try {
				Notice.fetch(this.uuid, {join_attachments: true, join_user: true}).then((notice) => {
					const blocksFromHtml = htmlToDraft(notice?.content);
					const { contentBlocks, entityMap } = blocksFromHtml;
					const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
					const editorState = EditorState.createWithContent(contentState);

					this.setState({
						isFetching: false,
						notice,
						title: notice?.title,
						editorState
					}, () => {
						this.isLockedFetch = false;
					});
				});

			} catch (e) {
				this.isLockedFetch = false;
			}
		});
	};

	onClickToggle = () => {
		this.setState((p) => ({ is_modifying: !p.is_modifying }));
	};

	onClickSave = () => {
		const {title} = this.state;
		const content = stateToHTML(this.state.editorState.getCurrentContent(), {inlineStyles: []});
		console.log('content', content);
		api.put(`/v1/admin/notice/${this.uuid}`, {title, content}).then((r) => {
			if (!r.ok) return alert('저장실패');
			alert('저장성공');
			this.fetch();
			this.onClickToggle();
		}).catch(e => {
			return alert('저장실패');
		});
	};

	onClickDelete = () => {
		if (window.confirm('정말 삭제하시겠습니까?')) {
			api.delete(`/v1/admin/notice/${this.uuid}`).then((r) => {
				if (!r.ok) return alert('삭제실패');
				alert('삭제성공');
				this.props.history.push('/admin/notice');
			}).catch(e => {
				alert('삭제실패');
			});
		}
	};

	onClickDeleteAttachment = (attachment: NoticeAttachment) => {
		api.delete(`/v1/admin/notice/attachment/${attachment.uuid}`, {}).then((r) => {
			if (!r.ok) {
				alert('첨부파일 삭제 실패');
				return;
			}

			alert('첨부파일 삭제 성공');
			this.state.notice.deleteAttachment(attachment);
		});
	};

	onEditorStateChange = (editorState) => {
		this.setState({editorState});
	};

	render() {
		const notice: Notice = this.state.notice;
		return (
			<ContainerComponent>
				<div>
					<div id="ad-contents" className="write">
						<h1 className="contents-title">공지사항</h1>
						<div className="notice-container">
							<div>
								<span>작성자</span>
								<div>{notice?.user?.username}</div>
							</div>
							<div>
								<span>제목</span>
								{this.state.is_modifying ?
									<input
										onChange={(e) => this.setState({title: e.currentTarget.value})}
										value={this.state.title} className="input-co"/>
									:
									<p style={{marginTop:"11px"}}>{notice.title}</p>}
							</div>

							<span className="line"></span>

							<div>
								<span style={{paddingTop:"0"}}>첨부파일</span>
								<div className="notice-attach" style={{paddingTop:"0"}}>
									{notice?.attachments?.map((attachment, index) => (
										<p>
											<a href={attachment.url} target='_blank'>{attachment.name}</a>
											{this.state.is_modifying &&
											<img style={{marginLeft: 4}} src={require('../../../../resources/admin/icon/remove_circle_outline_black_18dp.svg').default} onClick={() => this.onClickDeleteAttachment(attachment)}/>}
										</p>
									))}
								</div>
							</div>

							<span className="line"></span>

							<div className="notice-screen">
								<span style={{paddingTop:'0'}}>내용</span>
								{!this.state.is_modifying &&
								<div style={{paddingTop:"0px"}}>{notice?.content && parse(notice.content)}</div>}
							</div>
						</div>

						{this.state.is_modifying &&
						<Editor
							style={{height: 500}}
							editorState={this.state.editorState}
							wrapperClassName="demo-wrapper"
							editorClassName="demo-editor"
							onEditorStateChange={this.onEditorStateChange}
							toolbar={{
								options: ['inline', 'list', 'link', 'history'],
								inline: {
									options: ['bold', 'italic', 'underline', 'strikethrough'],
								},
								list: {
									options: ['unordered', 'ordered']
								},
								link: {
									options: ['link']
								}
							}}
						/>}

						<div className="viewbtn-wrap">
							<div className="left">
								<button className="view-btn" onClick={this.onClickToggle}>{this.state.is_modifying ? '취소' : '수정'}</button>
								{this.state.is_modifying ?
									<button className="view-btn" onClick={this.onClickSave}>저장</button>
								:
									<button className="view-btn" onClick={this.onClickDelete}>삭제</button>}
							</div>
							<Link to={'/admin/notice'}><button className="right view-btn">목록</button></Link>
						</div>
					</div>

				</div>
			</ContainerComponent>
		);
	}
}

export default observer(Screen);
