import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';

/** img */
import home from "../../resources/homepage/Home.svg";
import process3 from "../../resources/homepage/sv-process03.svg";
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {

	componentDid() {
		window.onscroll = function() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-nav-wrap").className = "sv-nav-fixed";
			} else {
				document.getElementById("sv-nav-wrap").className = "sv-nav";
			}
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-con-wrap").className = "sv-contents-fixed sv-contents";
			} else {
				document.getElementById("sv-con-wrap").className = "sv-contents";
			}
		};
	}

	render() {
		return (
			<div id="wrap" className="sv-long-in">

					{/* header */}
					<HeaderComponent/>

					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title"><strong>근골격계부담작업 유해요인조사</strong></h1>
							<div className="location sv-location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<Link to={'/service'}><strong>제공서비스</strong></Link>
								<Link to={'/service?tab=safety'}><strong>안전관리 전문기관</strong></Link>
								<strong>근골격계부담작업 유해요인조사</strong>
							</div>
						</div>
					</div>
					<div id="sub-container">
						<div className="section-in sv-in">

							<div className="sv-nav" id="sv-nav-wrap">
								<ul>
									<li className="on"><NavHashLink smooth to={'/service/291fc464-38a9-4dce-9e41-d5917ad15eb6/#sv1'} activeClassName="on">1. 근골격계부담작업 유해요인조사</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/291fc464-38a9-4dce-9e41-d5917ad15eb6/#sv2'} activeClassName="on">2. 위반 시 법적 제재 사항</NavHashLink></li>
								</ul>
							</div>

							<div className="sv-contents" id="sv-con-wrap">
								{/* 위험성평가 컨설팅 */}
								<div id="sv1">
									<h6>1. 근골격계부담작업 유해요인조사</h6>
									<p>
										<strong>개요</strong>
										단순반복작업 또는 인체에 과도한 부담을 주는 작업으로 인한 건강장해를 예방하기 위하여 근골격계 유해요인조사를 실시하여야 함.[산업안전보건법 제39조, 산업안전보건기준에 관한 규칙 제12장]
									</p>
									<p>
										<strong>안전관리업무 위탁대상</strong>
										상시근로자 50명 이상 사업장(건설업 제외)으로 안전관리자 법적 선임의무가 있는 사업장 및 안전관리업무를 위탁하고자 하는 사업장이 대상에 해당합니다.(산업안전보건법 시행령 별표3)
									</p>
									<p>
										<strong>근골격계 유해요인조사 실시 시기</strong>
										• 최초 평가<br />
										&nbsp;&nbsp;-  신설일부터 1년 이내 최초 유해요인조사 실시<br />
										• 정기 평가<br />
										&nbsp;&nbsp;-  유해요인조사 결과 근골격계부담작업을 하는 경우 매 3년마다 실시<br />
										• 수시 평가<br />
										&nbsp;&nbsp;-  법에 따른 임시건강진단 등에서 근골격계질환자가 발생하였거나 근로자가 근골격계질환으로 업무상 질병으로 인정받은 경우<br />
										&nbsp;&nbsp;-  근골격계부담작업에 해당하는 새로운 작업·설비를 도입한 경우<br />
										&nbsp;&nbsp;-  근골격계부담작업에 해당하는 업무의 양과 작업공정 등 작업환경을 변경한 경우
									</p>
									<p>
										<strong>근골격계부담작업 종류</strong>
										- 하루에 4시간 이상 집중적으로 자료입력 등을 위해 키보드 또는 마우스를 조작하는 작업<br />
										- 하루에 총 2시간 이상 목, 어깨, 팔꿈치, 손목 또는 손을 사용하여 같은 동작을 반복하는 작업<br />
										- 하루에 총 2시간 이상 머리 위에 손이 있거나, 팔꿈치가 어깨 위에 있거나, 팔꿈치를 몸통으로부터 들거나, 팔꿈치를 몸통 뒤쪽에 위치하도록 하는 상태에서 이루어지는 작업<br />
										- 지지되지 않은 상태이거나 임의로 자세를 바꿀 수 없는 조건에서, 하루에 총 2시간 이상 목이나 허리를 구부리거나 트는 상태에서 이루어지는 작업<br />
										- 하루에 총 2시간 이상 쪼그리고 앉거나 무릎을 굽힌 자세에서 이루어지는 작업<br />
										- 하루에 총 2시간 이상 지지되지 않은 상태에서 1kg 이상의 물건을 한손의 손가락으로 집어 옮기거나, 2kg 이상에 상응하는 힘을 가하여 한손의 손가락으로 물건을 쥐는 작업<br />
										- 하루에 총 2시간 이상 지지되지 않은 상태에서 4.5kg 이상의 물건을 한 손으로 들거나 동일한 힘으로 쥐는 작업<br />
										- 하루에 10회 이상 25kg 이상의 물체를 드는 작업<br />
										- 하루에 25회 이상 10kg 이상의 물체를 무릎 아래에서 들거나, 어깨 위에서 들거나, 팔을 뻗은 상태에서 드는 작업<br />
										- 하루에 총 2시간 이상, 분당 2회 이상 4.5kg 이상의 물체를 드는 작업<br />
										- 하루에 총 2시간 이상 시간당 10회 이상 손 또는 무릎을 사용하여 반복적으로 충격을 가하는 작업
									</p>
									<p>
										<strong>업무 내용</strong>
										• 근골격계 유해요인조사 실시계획서 작성<br />
										• 근골격계부담작업 및 유해요인조사 교육(필요 시)<br />
										• 근골격계 유해요인조사를 위한 사전조사<br />
										• 근골격계질환 증상집계 및 분석<br />
										• 공정/작업별 유해요인조사표 작성<br />
										• 작업환경 개선계획서 작성<br />
										• 기타 협의하여 결정된 사항
									</p>
								</div>

								{/* 위반 시 법적 제재 사항 */}
								<div id="sv2">
									<h6>2. 위반 시 법적 제재 사항</h6>
									<p>
										<strong style={{paddingBottom:'10px'}}>위반 시 법적 제재 사항</strong>
										<div className="respon-table">
										<table className="sv-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<thead>
											<tr>
												<th>위반행위</th>
												<th>세부내용</th>
												<th>범죄</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>단순 반복작업 또는 인체에 과도한 부담을 주는 작업에 의한 건강장해를 예방하기 위하여 필요한 조치를 하지 않은 경우</td>
												<td>제168조(벌칙)</td>
												<td>5년 이하의 징역 또는 5천만원 이하의 벌금</td>
											</tr>
											</tbody>
										</table>
										</div>
									</p>
									<p>
										<strong>업무절차</strong>
										재해분석을 통하여 재발 위험사업장 및 잠재적 위험 사업장을 집중관리 대상으로 Target 선정하여 기술, 재정, 교육, 안전검사등 가용수단을 총 동원한 패키지(Package) 지원방식으로 개선
										<img src={process3} width="100%" alt="유해요인 조사 절차" />
									</p>
								</div>

								<div className="sv-subBox">
									현업에서의 풍부한 경험 및 노하우를 바탕으로 산업재해로 인한 근로자의 귀중한 생명 보호와
									공공기관 안전담당자, 기업 내 사업주, 안전관리자의 안전관리 근로생애까지 관리해드리겠습니다.
								</div>

								<div className="sv-goback">
									<Link to={'/service'}>
										<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
									</Link>
								</div>
							</div>

						</div>
					</div>

					{/* footer */}
					<FooterComponent/>

				</div>
		)
	}
}

export default Screen;
