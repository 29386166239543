import React from 'react';
import adwait from "../../../resources/admin/icon/ad_wait.svg";
import report_status_confirmed from '../../../resources/admin/icon/complete.svg';
import ListLoadingComponent from "../common/ListLoadingComponent";
import {Report} from "../../../models/Report";
import moment from "moment";
import {DateUtils} from "../../../utils/DateUtils";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {STATUS_CONFIRMED, STATUS_DONE, STATUS_NORMAL} from "../../../constants/report";

class RecentReportsComponent extends React.Component {

	static propTypes = {
		history: PropTypes.any.isRequired,
	}

	state = {
		isFetching: true,
		items: [],
	};

	componentDidMount() {
		this.fetch(true);
	}

	fetch = (ignoreFetching: boolean) => {
		if (this.state.isFetching && !ignoreFetching) return;

		this.setState({isFetching: true}, () => {
			Report.fetchReports({ob: 'id', od: 'DESC', limit: 7}).then((reports) => {
				this.setState({
					isFetching: false,
					items: reports,
				});
			}).catch((e) => {
				this.setState({isFetching: false});
			});
		});
	};

	renderItems = () => this.state.items.map((report: Report, index) => {
		let status_source;

		switch (report.status) {
			case STATUS_NORMAL: {
				status_source = require('../../../resources/admin/icon/report/ic_status_normal.svg').default;
				break;
			}
			case STATUS_CONFIRMED: {
				status_source = require('../../../resources/admin/icon/report/ic_status_confirmed.svg').default;
				break;
			}
			case STATUS_DONE: {
				status_source = require('../../../resources/admin/icon/report/ic_status_done.svg').default;
				break;
			}
			default: {
				status_source = require('../../../resources/admin/icon/report/ic_status_normal.svg').default;
			}
		}

		return (
			<li key={report.uuid} onClick={() => this.props.history.push(`/admin/report/${report.uuid}`)}>
				<div><img src={status_source} width="48px" alt="대기상태 뱃지" /></div>
				<a className="text">{report.text.replace(/[\r\n]+/g, '')}</a>

				<span>{moment.unix(report.created_at).format('YYYY-MM-DD')} ({DateUtils.prettyDate(moment.unix(report.created_at))})</span>
			</li>
		);
	});

	render() {
		return (
			<div className="dl-bottom">
				<div className="da-subTit">
					<h5>최근 보고서</h5>
					<Link to={'/admin/report'}>더보기 +</Link>
				</div>
				<ul>
					{this.renderItems()}
					<ListLoadingComponent visible={this.state.isFetching}/>
				</ul>
			</div>
		);
	}
}


export default RecentReportsComponent;
