import React from 'react';
import HeaderComponent from "../components/common/HeaderComponent";
import FooterComponent from "../components/common/FooterComponent";

import '../css/common.css';
import '../css/pc-common.css';
import '../css/contents.css';

class Screen extends React.PureComponent {
	render() {
		return (
			<div>
				<div id="wrap">
					<HeaderComponent/>

					<h1>QnA(문의하기) 화면</h1>

					<FooterComponent/>
				</div>
			</div>
		)
	}
}

export default Screen;
