import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';
import {Link} from "react-router-dom";
import {Community} from "../../../../models/Community";
import moment from "moment";

class Screen extends React.PureComponent {

	state = {
		query: '',
		communities: [],
		count: 0,
	};

	componentDidMount() {
		this.fetchCommunities();
		this.fetchCount();
	}

	fetchCount = () => {
		Community.fetchCount().then((count) => {
			this.setState({ count });
		});
	};

	fetchCommunities = () => {
		Community.fetchCommunities({query: this.state.query, ob: 'id', od: 'DESC', join_user: true}).then((communities) => {
			this.setState({communities});
		});
	};

	onChangeQuery = (e) => {
		this.setState({query: e.currentTarget.value}, () => {
			this.fetchCommunities();
		});
	};

	renderCommunities = () => this.state.communities.map((community: Community) => {
		const communityLink = (child) => <Link to={`/admin/community/${community?.uuid}`}>{child}</Link>;

		return (
			<tr key={communityLink(community.uuid)}>
				<td>{communityLink(community?.title)}</td>
				{/*<td className="notice-content"><div className="long-txt">{noticeLink(parse(notice?.content))}</div></td>*/}
				<td>{communityLink(community?.user?.username)}</td>
				<td>{communityLink(community?.created_at && moment.unix(community.created_at).format('YYYY-MM-DD'))}</td>
			</tr>
		);
	})

	render() {
		return (
			<ContainerComponent>
				<div>
					<div id="ad-contents">
						<h1 className="contents-title">안전하는사람들(사랑방)</h1>
						<div className="contents-in">
							<div className="schfrm">
								<div className="left">총 <strong>{this.state.count}</strong>건</div>
								<div className="right">
									<div className="search-box">
										<input type="text" id="ad-search" placeholder="검색어를 입력해주세요" className="search" value={this.state.query} onChange={this.onChangeQuery}/>
										<div className="search-btn"></div>
									</div>
									<Link to={'/admin/community/write'}><button className="enroll">글쓰기</button></Link>
								</div>
							</div>
							<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
								<colgroup>
									<col width="76%;"/>
									<col width="12%;"/>
									<col width="12%;"/>
								</colgroup>
								<thead>
								<tr>
									<th scope="col">제목</th>
									<th scope="col">작성자</th>
									<th scope="col">작성일</th>
								</tr>
								</thead>

								{/* 게시물이 없는 경우 */}
								{this.state.communities.length > 0 ?
									<tbody>
									{this.renderCommunities()}
									</tbody>
									:
									<tbody className="no-contents">
									<tr>
										<td colSpan="6">등록된 글이 없습니다</td>
									</tr>
									</tbody>
								}
							</table>
						</div>
					</div>
				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;