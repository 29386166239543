import React from 'react';
import {Link} from "react-router-dom";

/** img */
import ftlogo from "../../resources/homepage/logo.svg";
import qr from "../../resources/homepage/qr2.svg";
import blog from "../../resources/homepage/blog.svg";

/** CSS */
import '../../css/common.css';
import '../../css/footer.css';
import ScrollToTopComponent from "./ScrollToTopComponent";

class FooterComponent extends React.PureComponent {

	render() {

		return (
			<div id="footer">
				<div className="footer-in">
					<div className="f-top-m">
						<div className="f-logo"><Link to={''}><img src={ftlogo} width="125px" alt="스마트안전 로고"/></Link></div>
						<a href={"https://blog.naver.com/bsipjc"} target={"_blank"}  className="blog mobile"><img src={blog} width="38px" alt="네이버 블로그"/></a>
					</div>
					<div className="f-utill">
						<ul className="f-gnb">
							<li><Link to={'/company'}>회사소개</Link></li>
							<li><Link to={'/service/smart-safety'}>스마트안전소개</Link></li>
							<li><Link to={'/history-mgmt-system'}>이력관리시스템</Link></li>
							<li><Link to={'/service'}>제공서비스</Link></li>
							<li><Link to={'/notice'}>공지사항</Link></li>
						</ul>
						<p className="f-info">
							부산광역시 사상구 모라로 22, 1316-2호(모라동, 부산벤처타워)<span className="f-block"></span>대표이사 : 최진칠<br />사업자번호
							: 811-81-02315
							<span className="f-block"></span>고객센터 1661-1844
						</p>
						<div className="utills"><Link to={'/policy/terms_of_service'}>이용약관</Link><span className="f-line"></span><Link to={'/policy/privacy'}>개인정보처리방침</Link></div>
						<small className="f-info">Copyright ⓒ 2021 (주)부산안전원 All Rights Reserved.</small>
					</div>
					<div className="f-app">
						<div className="app">
							<div className="qr-wrap"><img src={qr} width="68px" alt="앱 다운로드 qr코드"/>
							</div>
							<div className="app-down">
								<strong>APP 다운로드</strong>
								<div>스마트안전을<br />모바일 앱으로 만나보세요</div>
							</div>
						</div>
						<a href={"https://blog.naver.com/bsipjc"} target={"_blank"} className="blog"><img src={blog} width="38px" alt="네이버 블로그"/></a>
					</div>
					<div className="clear"></div>

					{/* 최상단 스크롤 버튼 */}
					<ScrollToTopComponent/>
				</div>
			</div>
		);
	}
}

export default FooterComponent;
