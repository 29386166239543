import {User} from "./User";
import {Question} from "./Question";

export class Answer {

  uuid: string;
  user_uuid: string;
  question_uuid: string;
  id: number;
  created_at: number;
  updated_at: number;
  text: string;

  // JOIN
  user: User;
  question: Question;

  constructor(answer: Answer) {
    Object.assign(this, answer);
    if (answer?.user) this.user = new User(answer.user);
    if (answer?.question) this.question = new Question(answer.question);
  }
}