import React from 'react';
import TicketListItemComponent from "./TicketListItemComponent";
import {UserTicket} from "../../../../../models/UserTicket";
import {api} from "../../../../../api/api";
import PropTypes from "prop-types";

class TicketListComponent extends React.Component {

  static propTypes = {
    user_uuid: PropTypes.string.isRequired,
  };

  state = {
    user_tickets: [],
  };

  componentDidMount() {
    this.fetch().then(() => {});
  }

  fetch = async() => {
    api.get(`/v1/admin/user/${this.props.user_uuid}/ticket`).then((res) => {
      if (!res.ok) {
        console.warn('요청실패', res);
        return;
      }

      console.log(res);
      const user_tickets: UserTicket[] = (res?.data?.user_tickets || []).map((e) => new UserTicket(e));
      this.setState({user_tickets});
    });
  };

  render() {
    return (
      <div className="ad-com-table" style={{minHeight: 300, display: 'block'}}>
        <h2 className="ad-infoTit">이용권 상세내역</h2>
        <table className="basic-table list-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
          <colgroup>
            <col width="5%;"/>
            <col width="15%;"/>
            <col width="15%;"/>
            <col width="15%;"/>
            <col width="15%;"/>
            <col width="15%;"/>
            <col width="15%;"/>
          </colgroup>
          <thead>
          <tr>
            <th>ID</th>
            <th>이용권명</th>
            <th>금액</th>
            <th>구매일</th>
            <th>만료일</th>
            <th className={"center"}>자동결제</th>
            <th className={"center"}>만료처리</th>
          </tr>
          </thead>

          <tbody>
          {this.state.user_tickets?.map((e: UserTicket) => (
            <TicketListItemComponent key={e.uuid} userTicket={e}/>
          ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default TicketListComponent;