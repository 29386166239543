import React from 'react';
import ContainerComponent from "../../../components/admin/common/ContainerComponent";
import AnimatedNumber from "animated-number-react";

import '../../../css/common.css';
import '../../../css/admin/form.css';
import '../../../css/admin/dashboard.css';
import RecentReportsComponent from "../../../components/admin/dashboard/RecentReportsComponent";
import StringUtils from "../../../utils/StringUtils";
import {Report} from "../../../models/Report";
import {Company} from "../../../models/Company";
import {Agent} from "../../../models/Agent";
import CompaniesComponent from "../../../components/admin/dashboard/CompaniesComponent";
import RecentNoticesComponent from "../../../components/admin/dashboard/RecentNoticesComponent";
import QuestionsComponent from "../../../components/admin/dashboard/QuestionsComponent";

/** CSS */

class DashboardScreen extends React.PureComponent {

	state = {
		reports: [],
		report_count: 0,
		company_count: 0,
		agent_count: 0,
	};

	componentDidMount() {
		Report.fetchCount().then((count) => {
			this.setState({ report_count: count });
		});

		Company.fetchCount().then((count) => {
			this.setState({ company_count: count });
		});

		Agent.fetchCount().then((count) => {
			this.setState({ agent_count: count });
		});
	}

	render() {

		return (
			<ContainerComponent>
				<div id="ad-contents">
					<h1 className="contents-title">대시보드</h1>
					<div className="dash-left">
						<div className="dl-top">
							<ul>
								<li>
									<h3>전체 보고서</h3>
									<h2>
										<AnimatedNumber
											value={this.state.report_count}
											formatValue={(value) => StringUtils.numberWithCommas(value.toFixed(0))}/>
										<small>건</small>
									</h2>
								</li>
								<li>
									<h3>전체 업체</h3>
									<h2>
										<AnimatedNumber
											value={this.state.company_count}
											formatValue={(value) => StringUtils.numberWithCommas(value.toFixed(0))}/>
										<small>건</small>
									</h2>
								</li>
								<li>
									<h3>안전담당자</h3>
									<h2>
										<AnimatedNumber
											value={this.state.agent_count}
											formatValue={(value) => StringUtils.numberWithCommas(value.toFixed(0))}/>
										<small>명</small>
									</h2>
								</li>
							</ul>
						</div>

						{/* 최근 보고서 */}
						<RecentReportsComponent history={this.props.history}/>
					</div>
					<div className="dash-right">
						<CompaniesComponent history={this.props.history}/>

						<div className="dr-bottom">
							<RecentNoticesComponent history={this.props.history}/>

							<QuestionsComponent history={this.props.history}/>
						</div>
					</div>
				</div>

			</ContainerComponent>
		);
	}
}

export default DashboardScreen;
