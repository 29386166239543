import React from 'react';
import {Link} from "react-router-dom";
import HeaderComponent from "../components/common/HeaderComponent";
import FooterComponent from "../components/common/FooterComponent";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

/** img */
import home from '../resources/homepage/Home.svg';
import vision from '../resources/homepage/vision.svg';
import mvision from '../resources/homepage/vision-m.svg';
import cs01 from '../resources/homepage/cs-01.svg';
import cs02 from '../resources/homepage/cs-02.svg';
import cs03 from '../resources/homepage/cs-03.svg';
import cs04 from '../resources/homepage/cs-04.svg';
import bg from '../resources/homepage/compay-bg.png';
/** img */

/** CSS */
import '../css/common.css';
import '../css/pc-common.css';
import '../css/contents.css';
/** CSS */


class Screen extends React.PureComponent {

	render() {
		return (
			<div id="wrap">

				{/* header */}
				<HeaderComponent/>

				<div id="top-menu">
					<div className="section-in">
						<Fade bottom><h1 className="top-title">안전과 노무를 한 곳에서!<br /><strong>(주)부산안전원</strong></h1></Fade>
						<div className="location">
							<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
							<strong>회사소개</strong>
						</div>
					</div>
				</div>

				<div id="sub-container">
					<div className="section-in hello" >

						<h3 className="sub-title">인사말</h3>
						<div className="c-txt">
							{/*<p><strong>“귀사의 참된 동반자가 되겠습니다.”</strong></p>*/}

							<p>부산안전원은 노동부로부터 안전관리 대행 및 재해예방 기술지도 기관으로 지정 받아 산업현장의 종합안전컨설팅 업무뿐만 아니라 노무와 기타 법률(노무사 · 변호사 대기)까지 종합적으로 수행하고 있습니다.</p>

							<p>근로자의 생명 및 권익을 보호하는 적법한 안전· 노무관리방안과 합리적인 기업경영 대안을 늘 강구하겠습니다.</p>

							<div className="ceo">부산안전원 대표이사 <em>최 진 칠</em></div>
						</div>
						<div className="c-img"><img src={bg} width='100%' alt='배경이미지' /> </div>
						<div className="clear"></div>

					</div>
					<div className="vision">
						<div className="section-in">
							<h3 className="sub-title">비전</h3>
							<Zoom delay={400} duration={1200}><div className="v-img"><img src={vision} width="100%" alt="안전+노무=부산안전원"/>
							</div></Zoom>
							<Zoom bottom delay={400} duration={1200}><div className="v-img mobile"><img src={mvision} width="100%" alt="안전+노무=부산안전원"/>
							</div></Zoom>
							<div className="v-txt">
								<strong><span className="red">안전관리</span>와 <span className="green">인사노무관리</span>를 한
									그릇에!</strong>
								<p>
									저희 부산안전원은 수십 년 간 고용노동부 근속 또는 안전관리자로 근무한 직원들이 주축이 되어 설립한 회사(기술사 및 노무사 자격 보 유)로 기업의 안전관리 및 인사노무관리가 한 번에 이뤄질 수 있도 록 한 그릇에 담았습니다.
								</p>
							</div>
						</div>
					</div>
					<div className="section-in business">
						<h3 className="sub-title">주요사업</h3>
						<div className="cs-service">
							<ul>
								<li className="cservice-1">
									<div className="sc-icon"><img src={cs01} width="90px"
																  alt="제조 기타 서비스업종 안전관리 지원"/></div>
									<div className="cs-txt">
										<strong>제조 기타 서비스 업종 안전관리 지원</strong>
										<p>
											- 50인이상 사업장 안전관리 대행(산업안전보건법 의무사항)<br />
											- 50인 미만 사업장 컨설팅<br />
											- 제조업 유해위험방지계획서, 위험성평가 컨설팅
										</p>
									</div>
								</li>
								<li className="cservice-2">
									<div className="sc-icon"><img src={cs02} width="90px"
																  alt="인사노무 컨설팅 및 교"/></div>
									<div className="cs-txt">
										<strong>인사노무 컨설팅 및 교육</strong>
										<p>
											- 중대재해 특별관리<br />
											- 직별,별 근로형태별 맞춤형 근로계약 설계<br />
											- 업종과 규모의 특성에 맞는 취업규칙 설계 및 사규 검토<br />
											- 최저임금, 통상임금, 평균임금, 포괄임금 등 임금체계 설계 및 검토<br />
											- 노사협의회, 단체교섭, 단체협약, 쟁의행위 등 집단적 노사관계 관리 지원<br />
											- 산업안전보건교육 안전사고에 대한 산재처리 등 사후관리, 성희롱예방교육 등 맞춤형교육 제공<br />
											- 모의 근로감독, 방문, 유선, 이메일, 의견서 등을 통한 상시적 소통 제공
										</p>
									</div>
								</li>
								<li className="cservice-3">
									<div className="sc-icon"><img src={cs03} width="90px" alt="각종 노동사건 대리 및 대관업무 지원"/></div>
									<div className="cs-txt">
										<strong>각종 노동사건 대리 및 대관업무 지원</strong>
										<p>
											- 부당해고, 부당징계, 부당노동행위 등 노동위원회 사건 대리<br />
											- 중대재해, 진정, 고소, 고발 등 고용노동청 민원업무 지원<br />
											- 요양, 휴업, 장해, 유족급여 청구 등 근로복지공단 민원업무 지원
										</p>
									</div>
								</li>
								<li className="cservice-4">
									<div className="sc-icon"><img src={cs04} width="90px" alt="건설안전 관리 및 지도"/></div>
									<div className="cs-txt">
										<strong>건설안전 관리 및 지도</strong>
										<p>
											- 건설재해예방기술지도(공사금액 3억 이상 120억 미만 건설공사)<br />
											- 건설업 안전관리계획서 및 유해위험방지계획서 작성<br />
											- 건설업안전컨설팅
										</p>
									</div>
								</li>
							</ul>
							<div class="customer"><Link to={'/customers'}>고객사</Link></div>
						</div>
					</div>
				</div>

				<FooterComponent/>
			</div>
		)
	}
}

export default Screen;
