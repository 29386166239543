import React from 'react';
import ContainerComponent from "../../../components/admin/common/ContainerComponent";

/** img */
import close from "../../../resources/admin/icon/close.svg";
/** img */
/** CSS */
import '../../../css/common.css';
import '../../../css/admin/form.css';
import '../../../css/admin/dashboard.css';
import AgentAddModal from "../../../modals/admin/AgentAddModal";
import AnimatedNumber from "animated-number-react";
import AgentListItemComponent from "../../../components/admin/agent/AgentListItemComponent";
import ListLoadingComponent from "../../../components/admin/common/ListLoadingComponent";
import {api} from "../../../api/api";
import {Agent} from "../../../models/Agent";

/** CSS */

class Screen extends React.PureComponent {

	state = {
		isFetching: true,
		query: '',
		items: [],
		items_total_count: 0,
		is_visible_add_modal: false,
		agent: null,
	};

	componentDidMount() {
		this.fetch();
		this.fetchTotalCount();
	}

	fetchTotalCount = () => {
		api.get('/v1/admin/agent/count').then((r) => {
			if (!r.ok) return;
			this.setState({items_total_count: parseInt(r.data.count)});
		})
	}

	fetch = () => {
		if (this.isLockedFetch) return;

		this.isLockedFetch = true;

		const fallback = () => {
			this.setState({isFetching: false}, () => {
				this.isLockedFetch = false;
			});
		};

		try {
			this.setState({isFetching: true}, () => {
				api.get('/v1/admin/agent', {query: this.state.query, join_user: true, ob: 'id', od: 'DESC'}).then((response) => {
					if (!response.ok) {
						return fallback();
					}

					const agents = (response.data.agents || []).map((e) => new Agent(e));
					this.setState({items: agents, isFetching: false}, () => this.isLockedFetch = false);
				});
			});
		} catch (e) {
			fallback();
		}
	}

	onChangeQuery = (e) => {
		this.setState({query: e.currentTarget.value}, () => {
			this.fetch();
		});
	};

	onClickAddAgent = () => {
		this.setState({is_visible_add_modal: true});
	};

	onCloseAddModal = () => {
		this.setState({is_visible_add_modal: false, agent: null}, () => {
			this.fetch();
		});
	};

	onClickEdit = (agent: Agent) => {
		this.setState({agent, is_visible_add_modal: true});
	};

	renderEmpty = () => !this.state.isFetching && this.state.items.length <= 0 && (
		<tbody className="no-contents">
		<tr>
			<td colSpan="9">등록된 글이 없습니다</td>
		</tr>
		</tbody>
	);

	renderItems = () => this.state.items.map((item, index) => (
		<AgentListItemComponent key={index} agent={item} onClickEdit={this.onClickEdit}/>
	));

	render() {
		return (
			<ContainerComponent>
				<div>
					<div id="ad-contents">
						<h1 className="contents-title">안전 담당자 목록</h1>
						<div className="contents-in">
							<div className="schfrm">
								<div className="left">
									총 <strong><AnimatedNumber
									value={this.state.items_total_count}
									formatValue={(value) => value.toFixed(0)}/>건</strong>
								</div>
								<div className="right">
									<div className="search-box">
										<input type="text" id="ad-search" placeholder="검색어를 입력해주세요" className="search" value={this.state.query} onChange={this.onChangeQuery}/>
											<div className="search-btn"></div>
									</div>
									<button className="enroll" onClick={this.onClickAddAgent}>담당자 등록</button>
								</div>
							</div>
							<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
								<colgroup>
									<col width="15%;"/>
									<col width="15%;"/>
									<col width="20%;"/>
									<col width="10%;"/>
									<col width="5%;"/>
									<col width="5%;"/>
								</colgroup>
								<thead>
								<tr>
									<th scope="col">아이디</th>
									<th scope="col">성명</th>
									<th scope="col">연락처</th>
									<th scope="col">분야</th>
									<th scope="col">담당업체</th>
									<th scope="col" className="editTit">설정</th>
								</tr>
								</thead>

								{/* 게시물이 없는 경우 */}
								{this.renderEmpty()}

								{/* 게시물이 있는 경우 */}
								<tbody>
									{this.renderItems()}
								</tbody>

								<ListLoadingComponent visible={this.state.isFetching}/>
							</table>
						</div>
					</div>

					{/* 담당자 등록 modal */}
					{this.state.is_visible_add_modal &&
					<AgentAddModal onClose={this.onCloseAddModal} agent={this.state.agent}/>}

					{/* 담당자 수정 modal */}
					<div id="basic-modal" style={{display:'none'}}>
						<div className="modal info-modal">
							<div id="modal-close"><img src={close} width="16px;" alt="닫기" /></div>
							<h1>안전 담당자 수정</h1>

							<div className="modal-in">
								<ul>
									<li>
										<div>고유번호</div>
										<input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled />
									</li>
									<li>
										<div>이름</div>
										<input type="text" id="ad-name" placeholder="기본 정보 노출" />
									</li>
									<li>
										<div>연락처</div>
										<input type="text" id="ad-phone" placeholder="기본 정보 노출" />
									</li>
									<li>
										<div>비밀번호</div>
										<input type="password" id="ad-PW" placeholder="비밀번호를 입력해주세요" />
									</li>
									<li>
										<div>비밀번호 확인</div>
										<input type="password" id="ad-PW-check" placeholder="비밀번호를 한번 더 입력해주세요" />
									</li>
									<li>
										<div>분야</div>
										<div className="radio">
											<input type="radio" id="r1" name="field" />
											<label htmlFor="r1">제조</label>
										</div>
										<div className="radio">
											<input type="radio" id="r2" name="field" />
											<label htmlFor="r2">건설</label>
										</div>
									</li>
								</ul>
							</div>

							<div className="btn-wrap">
								<button id="modal-btn-cancel" className="modal-btn btn-cancel">취소</button>
								<button id="modal-btn-ok" className="modal-btn btn-edit">수정</button>
							</div>
						</div>
					</div>

				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;
