import {api} from "../api/api";
import {ReportContentResponse} from "./ReportContentResponse";
import {Report} from "./Report";

export class ReportContent {
  uuid: string;
  id: number;

  created_at: string;

  report_uuid: string;
  status: string;
  text: string;

  attachment_url: string;
  attachment_mime: string;

  // JOIN
  report: Report;
  responses: ReportContentResponse[];

  constructor(reportContent: ReportContent) {
    Object.assign(this, reportContent);
  }

  fetchResponses = (params: Object = {query: undefined, offset: undefined, limit: undefined}): Promise<ReportContentResponse[]> => new Promise((resolve, reject) => {
    return api.get(`/v1/report/${this.report_uuid}/content/${this.uuid}/response`, params).then((response) => {
      if (!response.ok) return reject(response);
      const responses: ReportContentResponse[] = (response.data.responses || []).map((e) => new ReportContentResponse(e));
      return resolve(responses);
    }).catch((e) => {
      return reject(e);
    });
  });
}