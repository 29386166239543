import React from 'react';
import PropTypes from 'prop-types';
import {Agent} from "../../../models/Agent";
import {observer} from "mobx-react";
import AnimatedNumber from "animated-number-react";

class AgentListItemComponent extends React.PureComponent {

	static propTypes = {
		agent: PropTypes.instanceOf(Agent).isRequired,
		onClickEdit: PropTypes.func,
	};

	componentDidMount() {
		this.props.agent?.fetchCompanyCount().then(() => {});
	}

	onClickEdit = () => {
		typeof this.props.onClickEdit === 'function' && this.props.onClickEdit(this.props.agent);
	};

	render() {
		return (
			<tr>
				<td>{this.props.agent?.user.username}</td>
				<td>{this.props.agent?.name}</td>
				<td>{this.props.agent?.contact_number}</td>
				<td>{this.props.agent?.categoryAlias()}</td>
				<td><AnimatedNumber value={this.props.agent?.company_count} formatValue={(value) => value.toFixed(0)}/>개</td>
				<td className="edit"><button onClick={this.onClickEdit}>수정</button></td>
			</tr>
		)
	}
}

export default observer(AgentListItemComponent);
