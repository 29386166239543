import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink } from 'react-router-hash-link';

/** img */
import home from "../../resources/homepage/Home.svg";
import process4 from "../../resources/homepage/sv-process04.svg";
import process5 from "../../resources/homepage/sv-process05.svg";
import goback from "../../resources/homepage/leftarrow.svg";
/** img */

/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {

	componentDid() {
		window.onscroll = function() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-nav-wrap").className = "sv-nav-fixed";
			} else {
				document.getElementById("sv-nav-wrap").className = "sv-nav";
			}
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				document.getElementById("sv-con-wrap").className = "sv-contents-fixed sv-contents";
			} else {
				document.getElementById("sv-con-wrap").className = "sv-contents";
			}
		};
	}

	render() {
		return (
			<div id="wrap" className="sv-long-in">

					{/* header */}
					<HeaderComponent/>

					<div id="top-menu">
						<div className="section-in">
							<h1 className="top-title"><strong>제조업 유해·위험방지 계획서</strong></h1>
							<div className="location sv-location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<Link to={'/service'}><strong>제공서비스</strong></Link>
								<Link to={'/service?tab=safety'}><strong>안전관리 전문기관</strong></Link>
								<strong>제조업 유해·위험방지 계획서</strong>
							</div>
						</div>
					</div>
					<div id="sub-container">
						<div className="section-in sv-in">

							<div className="sv-nav" id="sv-nav-wrap">
								<ul>
									<li className="on"><NavHashLink smooth to={'/service/0de13afb-c7e0-4098-ad02-c380f1129726/#sv1'} activeClassName="on">1. 제조업 유해·위험방지 계획서</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/0de13afb-c7e0-4098-ad02-c380f1129726/#sv2'} activeClassName="on">2. 심사, 확인절차</NavHashLink></li>
									<li><NavHashLink smooth to={'/service/0de13afb-c7e0-4098-ad02-c380f1129726/#sv3'} activeClassName="on">3. 위반 시 법적 제재 사항</NavHashLink></li>
								</ul>
							</div>

							<div className="sv-contents" id="sv-con-wrap">
								{/* 제조업 유해·위험방지 계획서 */}
								<div id="sv1">
									<h6>1. 제조업 유해·위험방지 계획서</h6>
									<p>
										<strong>개요</strong>
										대통령령으로 정하는 사업의 종류 및 규모에 해당하는 사업으로서 해당 제품의 생산 공정과 직접적으로 관련된 건설물·기계·기구 및 설비 등 일체를 설치·이전하거나 그 주요 구조부분을 변경하는 경우 또는 유해하거나 위험한 작업 또는 장소에서 사용하거나 건강장해를 방지하기 위하여 사용하는 기계·기구 및 설비로서 대통령령으로 정하는 기계·기구 및 설비를 설치·이전하거나 그 주요 구조부분을 변경하는 경우에는 유해·위험방지계획서를 작성하여 고용노동부장관에게 제출토록 하고 있음.[산업안전보건법 제42조]
									</p>
									<p>
										<strong>대상 사업장</strong>
										제품생산 공정과 직접적으로 관련된 건설물·기계·기구 및 설비 등 일체를 설치·이전하거나 그 주요 구조부분을 변경하는 경우
										<div className="respon-table">
										<table className="sv-table sv-halftable" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<tr>
												<th rowSpan={2}>대상업종</th>
												<th>전기계약용량 300kw 이상</th>
												<td>
													- 금속가공제품(기계 및 가구는 제외한다) 제조업<br />
													- 비금속 광물제품 제조업<br />
													- 기타 기계 및 장비 제조업<br />
													- 자동차 및 트레일러 제조업<br />
													- 식료품 제조업<br />
													- 고무제품 및 플라스틱제품 제조업<br />
													-  목재 및 나무제품 제조업<br />
													-  기타 제품 제조업<br />
													- 1차 금속 제조업<br />
													- 가구 제조업<br />
													- 화학물질 및 화학제품 제조업<br />
													- 반도체 제조업<br />
													- 전자부품 제조업
												</td>
											</tr>
											<tr>
												<th>업종 불문</th>
												<td>
													- 금속이나 그 밖의 광물의 용해로(용량 3톤 이상)<br />
													- 화학설비<br />
													- 건조설비(연료의 최대소비량 50kg/hr 이상 또는 최대소비전력 50kw 이상)<br />
													- 가스집합 용접장치(인화성가스 집합량 1,000kg 이상)<br />
													- 제조등금지물질 또는 허가대상물질 관련 설비<br />
													- 분진작업 관련 설비
												</td>
											</tr>
										</table>
										</div>
									</p>
									<p>
										<strong>업무내용</strong>
										• 공정 및 장치설계의 적합성 분석 및 계획서 작성<br />
										• 기계 및 구조설계, 용접, 재료 및 부식에 대한 적합성 분석 및 계획서 작성<br />
										• 전기설비 및 방폭전기설비에 대한 적합성 분석 및 계획서 작성<br />
										• 비상조치 및 소방설치 계획에 대한 적합성 분석 및 계획서 작성<br />
										• 안전성 분석(위험성 평가), 산업보건위생에 관한 사항 작성 등<br />
										• 작업공정별 유해위험방지계획 및 작업환경 조성계획서 작성 등
									</p>
									<p>
										<strong>용역 대가</strong>
										「엔지니어링산업 진흥법」제31조제2항의 규정에 따라 전기 계약용량 및 업종, 기계·기구 설비 종류를 고려하여 견적금액을 산출함.
									</p>
									<p>
										<strong>제출 시기</strong>
										• 해당 작업 시작 15일 전까지 유해·위험 방지계획서 2부를 작성하여 공단에 제출※ “작업시작 전”이란 건설물·기계·기구 및 설비 등 설치·이전·변경을 시작하는 시점으로 주요 설비 설치공사 시작 시점을 의미함.<br />
										• 「건설물」에 해당하는 경우 : “착공 전”<br />
										• 「기계·기구 및 설비」에 해당하는 경우 : “해당 기계·기구 및 설비 설치 전”<br />
										※ “착공” 이란 유해·위험방지계획서 작성 대상 시설물 또는 구조물의 공사를 시작하는 것을 말함.
									</p>
								</div>

								{/* 심사, 확인절차 */}
								<div id="sv2">
									<h6>2. 심사, 확인절차</h6>
									<p>
										<strong>① 제출시기 및 방법</strong>
										- 제출시기 : 작업시작 15일전까지<br />
										- 계획서 제출처 및 수수료 입금<br />
										- 제출처 : 사업장 소재 관할 안전보건공단 지역본부·지도원<br />
										- 입금계좌 : 계획서 심사 신청 시 가상계좌번호 안내
									</p>
									<p>
										<strong>② 심사 절차</strong>
										<img src={process4} width="100%" alt="심사 절차"  style={{marginTop:'0'}} />
										※ 제출된 유해·위험방지계획서의 적합성 및 타당성 검토하여 접수일로부터 15일 이내 심사결과 교부
									</p>
									<p>
										<strong>③ 확인 절차</strong>
										<img src={process5} width="100%" alt="확인 절차"  style={{marginTop:'0'}} />
										※ 사전에 확인일정을 통보하고 해당일에 사업장을 방문하여 계획서 이행여부 확인 후 5일 이내 결과 교부
									</p>
									<p>
										<strong>④ 심사 수수료</strong>
										<div className="respon-table">
										<table className="sv-table w-long" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<thead>
											<tr>
												<th colSpan={2}>심사대상</th>
												<th rowSpan={2}>수수료(원)</th>
											</tr>
											<tr>
												<th>종류</th>
												<th>규모</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td rowSpan={3} style={{width:'50%'}}>
													1. 금속가공제품(기계 및 가구를 제외한다)<br />
													- 제조업, 비금속 광물제품 제조업, 기타 기계 및 장비 제조업, 자동차 및 트레일러 제조업, 식료품 제조업, 고무제품 및 플라스틱 제품 제조업, 목재 및 나무제품 제조업, 기타 제품 제조업, 1차 금속 제조업, 가구 제조업, 화학 물질 및 화학 제품 제조업 (의약품 제외), 반도체제조업, 전자부품 제조업에서 일관 설비를 설치·이전하는 경우
												</td>
												<td>
													가. 전기계약용량이 500kW 미만
												</td>
												<td style={{textAlign:'right'}}>
													84,000
												</td>
											</tr>
											<tr>
												<td>
													나. 전기계역용량이 2,000kW 미만
												</td>
												<td style={{textAlign:'right'}}>
													123,000
												</td>
											</tr>
											<tr>
												<td>
													다. 전기계약용량이 2,000kW 이상
												</td>
												<td style={{textAlign:'right'}}>
													183,000
												</td>
											</tr>
											<tr>
												<td>
													2. 상기 사업장에서 설비의 일부를 증설·이전·변경 하는 경우
												</td>
												<td>
													-
												</td>
												<td style={{textAlign:'right'}}>
													67,000
												</td>
											</tr>
											<tr>
												<td rowSpan={3}>
													3. 금속이나 그 밖의 광물의 용해로, 화학설비, 건조 설비, 가스 집합용접장치, 허가대상·관리대상 유해물질 및 분진작업 관련 설비를 설치·이전 하는 경우
												</td>
												<td>
													가. 3기 미만
												</td>
												<td style={{textAlign:'right'}}>
													44,000
												</td>
											</tr>
											<tr>
												<td>
													나. 6기 미만
												</td>
												<td style={{textAlign:'right'}}>
													58,000
												</td>
											</tr>
											<tr>
												<td>
													다. 6기 이상
												</td>
												<td style={{textAlign:'right'}}>
													67,000
												</td>
											</tr>
											<tr>
												<td>
													4. 상기 설비의 일부를 변경하는 경우
												</td>
												<td>
													-
												</td>
												<td style={{textAlign:'right'}}>
													36,000
												</td>
											</tr>
											</tbody>
										</table>
										</div>
									</p>
									<p>
										<strong>소요비용</strong>
										산출기준 : 엔지니어링 기술진흥법 제 10조에 의한 엔지니어링 사업대가의 기준 적용
									</p>
								</div>

								{/* 위반 시 법적 제재 사항 */}
								<div id="sv3">
									<h6>3. 위반 시 법적 제재 사항</h6>
									<p>
										<strong style={{paddingBottom:'10px'}}>위반 시 법적 제재 사항</strong>
										<div className="respon-table">
										<table className="sv-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
											<thead>
											<tr>
												<th rowSpan={2}>위반행위</th>
												<th rowSpan={2}>세부내용</th>
												<th colSpan={3}>과태료 금액 (만원)</th>
											</tr>
											<tr>
												<th>1차</th>
												<th>2차</th>
												<th>3차</th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>
													법 제 42조 1항을 위반하여 유해위험방지계획서 또는 자체 심사결과서를 작성하여 제출하지 않은 경우<br />
													1. 대통령령으로 정하는 사업의 종류 및 규모에 해당하는 사업<br />
													2. 대통령령으로 정하는 기계·기구 및 설비에 해당하는 사업
												</td>
												<td>법 제 175조 제 4항 제 3호</td>
												<td>1,000</td>
												<td>1,000</td>
												<td>1,000</td>
											</tr>
											<tr>
												<td>
													법 제 43조 1항을 위반하여 고용노동부 장관의 확인을 받지 않은 경우
												</td>
												<td>법 제 175조 제 6항 제 5호</td>
												<td>30</td>
												<td>150</td>
												<td>300</td>
											</tr>
											</tbody>
										</table>
										</div>
									</p>
								</div>

								<div className="sv-subBox">
									현업에서의 풍부한 경험 및 노하우를 바탕으로 산업재해로 인한 근로자의 귀중한 생명 보호와
									공공기관 안전담당자, 기업 내 사업주, 안전관리자의 안전관리 근로생애까지 관리해드리겠습니다.
								</div>

								<div className="sv-goback">
									<Link to={'/service'}>
										<img src={goback} width="20px" alt="목록으로 가기" style={{display:'inline-block'}} /> <h1 style={{fontSize: 18}}>목록으로 가기</h1>
									</Link>
								</div>
							</div>

						</div>
					</div>

					<FooterComponent/>

				</div>
		)
	}
}

export default Screen;
