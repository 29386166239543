import React, {createRef} from 'react';
import PropTypes from 'prop-types';

/** img */
import dlogo from '../../../resources/admin/logo/dash_logo.svg';
/** img */
/** CSS */
import '../../../css/common.css';
import '../../../css/admin/form.css';
import '../../../css/admin/login.css';
import '../../../css/admin/modal.css';
import {Link} from "react-router-dom";
import {inject} from "mobx-react";
import ProfileEditModal from "../../../modals/admin/ProfileEditModal";

/** CSS */

class HeaderComponent extends React.Component {

	static propTypes = {
		containerStyle: PropTypes.object,
	};

	refProfileEditModal = createRef();

	state = {
		visible_profile_modal: false,
	};

	showProfileEdit = () => {
		this.refProfileEditModal?.current?.show();
	};

	render() {
		return (
			<div>
				<div id="ad-top">
					<div className="top-left"><Link to="/admin"><img src={dlogo} width="166px" alt="스마트안전 관리자" /></Link></div>
					<div className="top-right">
						<span><p onClick={this.showProfileEdit}>정보변경</p></span>
						<span><Link to={'/admin/sign-out'}>로그아웃</Link></span>
					</div>

				</div>
				<div className="clear"></div>

				<ProfileEditModal ref={this.refProfileEditModal}/>
			</div>
		);
	}
}

export default inject(['sessionStore'])(HeaderComponent);
