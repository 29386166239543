import React from 'react';
import ListLoadingComponent from "../common/ListLoadingComponent";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {Question} from "../../../models/Question";
import QuestionListItemComponent from "./QuestionListItemComponent";

class QuestionsComponent extends React.Component {

	static propTypes = {
		history: PropTypes.any.isRequired,
	}

	state = {
		isFetching: true,
		items: [],
	};

	componentDidMount() {
		this.fetch(true);
	}

	fetch = (ignoreFetching: boolean) => {
		if (this.state.isFetching && !ignoreFetching) return;

		this.setState({isFetching: true}, () => {
			Question.fetchQuestions({ob: 'id', od: 'DESC', limit: 7}).then((questons) => {
				this.setState({
					isFetching: false,
					items: questons,
				});
			}).catch((e) => {
				console.error(e);
				this.setState({isFetching: false});
			});
		});
	};

	renderItems = () => this.state.items.map((question: Question, index) => {
		return (
			<QuestionListItemComponent key={question.uuid} question={question} history={this.props.history}/>
		);
	});

	render() {
		return (
			<div className="right">
				<div className="da-subTit">
					<h5>고객센터</h5>
					<Link to={'/admin/question'}>더보기 +</Link>
				</div>
				<ul>
					{this.renderItems()}
					{/*<li>질문있습니다 <img src={newbadge} width="14px" alt="새로운 소식" /></li>*/}
					{/*<li>질문있습니다질문있습니다</li>*/}
					{/*<li>질문있습니다질문있습니다질문있습니다</li>*/}
					{/*<li>질문있습니다질문있습니다질문있습니다질문있습니다질문있습니다질문있습니다</li>*/}
					<ListLoadingComponent visible={this.state.isFetching}/>
				</ul>
			</div>
		);
	}
}


export default QuestionsComponent;
