import {api} from "../api/api";
import {User} from "./User";
import {Answer} from "./Answer";
import {action, makeObservable, observable, runInAction} from "mobx";
import {QuestionAttachment} from "./QuestionAttachment";

export class Question {

  uuid: string;
  id: number;
  created_at: string;
  user_uuid: string;
  text: string;

  // JOIN
  user: User;
  attachments: QuestionAttachment[];
  answers: Answer[];

  // Extra
  @observable answer_count: number;

  constructor(question: Question) {
    Object.assign(this, question);
    if (question?.user) this.user = new User(question.user);
    if (question?.answers) this.answers = question.answers.map(e => new Answer(e));
    makeObservable(this);
  }

  get statusAlias() {
    if (this.answer_count === undefined || this.answer_count === null) return '데이터 없음';
    if (!this.answer_count) return '답변대기 중';
    return '답변완료';
  }

  get statusColor() {
    if (this.answer_count === undefined || this.answer_count === null) return '#DDD';
    if (!this.answer_count) return '#D32F2F';
    return '#4CAF50';
  }

  static fetch = (uuid: string, params: Object = {}): Promise<Question> => new Promise((resolve, reject) => {
    return api.get(`/v1/admin/question/${uuid}`, params).then((response) => {
      if (!response.ok) return reject(response);
      if (!response.data.question) return reject(response);
      return resolve(new Question(response.data.question));
    }).catch((e) => {
      return reject(e);
    });
  });

  static fetchQuestions = (params: Object = {query: undefined, offset: undefined, limit: undefined, join_report_contents: undefined}): Promise<Question[]> => new Promise((resolve, reject) => {
    return api.get('/v1/admin/question', params).then((response) => {
      if (!response.ok) return reject(response);
      const questions: Question[] = (response.data.questions || []).map((e) => new Question(e));
      return resolve(questions);
    }).catch((e) => {
      return reject(e);
    });
  });

  static fetchCount = (): Promise<number> => new Promise((resolve, reject) => {
    return api.get('/v1/admin/question/count', {}).then((response) => {
      if (!response.ok) return reject(response);
      const count = parseInt(response.data.count);
      return resolve(count);
    }).catch((e) => {
      console.log(e);
      return reject(e);
    });
  });

  static fetchAnswerCount = (uuid: string): Promise<number> => new Promise((resolve, reject) => {
    return api.get(`/v1/question/${uuid}/answer/count`, {}).then((response) => {
      if (!response.ok) return reject(response);
      const count = parseInt(response.data.count);
      return resolve(count);
    }).catch(reject);
  });

  @action
  fetchAnswerCount(): Promise<number> {
    if (this.isLockedFetchAnswerCount) return;
    this.isLockedFetchAnswerCount = true;

    return new Promise((resolve, reject) => {
      Question.fetchAnswerCount(this.uuid).then((count) => {
        runInAction(() => {
          this.answer_count = count;
        });
        this.isLockedFetchAnswerCount = false;
        resolve(count);
      }).catch(e => {
        this.isLockedFetchAnswerCount = false;
        reject();
      });
    });
  }
}