import React from 'react';
import PropTypes from 'prop-types';
import close from "../../resources/admin/icon/close.svg";
import {RandomUtils} from "../../utils/RandomUtils";
import {MAX_LENGTH as PASSWORD_MAX_LENGTH} from "../../constants/password";
import {api} from "../../api/api";
import {CATEGORY_CONSTRUCTION, CATEGORY_MANUFACTURE} from "../../constants/agent";
import {Agent} from "../../models/Agent";

class Modal extends React.PureComponent {

	static propTypes = {
		agent: PropTypes.instanceOf(Agent),
	};

	state = {
		username: '',
		name: '',
		contact_number: '',
		password: '',
		password_confirm: '',
		category: null,
	};

	constructor(props) {
		super(props);

		const agent = props.agent;
		if (agent) {
			this.state.username = agent?.user?.username;
			this.state.name = agent?.name;
			this.state.category = agent?.category;
			this.state.contact_number = agent?.contact_number;
		}

		if (!this.isModifying) {
			this.state.password = RandomUtils.randomStrings(6);
		}
	}

	get isModifying(): boolean {
		return !!this.props.agent;
	}

	close = () => {
		typeof this.props.onClose === 'function' && this.props.onClose();
	};

	onClickClose = () => {
		this.close();
	}

	onClickSubmit = () => {
		if (this.isLockedSubmit) return;
		this.isLockedSubmit = true;

		const release = (state, callback) => {
			this.setState({isFetching: false}, () => {
				this.isLockedSubmit = false;

				typeof callback === 'function' && callback();
			});
		};

		const agent: Agent = this.props.agent;
		const {username, password, password_confirm, name, contact_number, category} = this.state;

		// 공통 검사 항목
		if (!username) return release({}, () => alert('아이디를 입력하세요'));
		if (!name) return release({}, () => alert('이름을 입력하세요'));
		if (!contact_number) return release({}, () => alert('연락처를 입력하세요'));
		if (!category) return release({}, () => alert('분야를 선택하세요'));

		if (this.isModifying) {
			// 수정모드일 때 검사할 항목
			const is_change_password = password || password_confirm;

			// 비밀번호를 변경하는 것
			if (is_change_password) {
				if (!password) return release({}, () => alert('비밀번호를 입력해주세요'));
				if (!password_confirm) return release({}, () => alert('비밀번호를 다시 입력해주세요'));
			}
		} else {
			// 등록모드일 때 검사할 항목
			if (!password) return release({}, () => alert('비밀번호를 입력하세요'));
		}

		this.setState({isFetching: true}, () => {
			try {
				if (this.isModifying) {
					api.put(`/v1/admin/agent/${agent.user_uuid}`, {username, password, name, contact_number, category}).then((r) => {
						if (!r.ok) return release({}, () => alert('저장 실패'));
						alert('저장 성공');
						this.close();
					}).catch(e => {
						console.error(e);
						release({}, () => { alert('저장 오류') });
					});
				} else {
					api.post('/v1/admin/agent', {username, password, name, contact_number, category}).then((r) => {
						if (!r.ok) {
							if (r.data && r.data.code) {
								switch (r.data.code) {
									case 'ALREADY_EXISTS':
										alert('아이디가 사용중입니다.');
										break;
									default:
										alert('알 수 없는 오류');
								}
							}

							return release({}, () => { alert('생성 실패(알 수 없는 오류)') });
						}
						alert('생성 성공');
						this.close();
					}).catch((e) => {
						release();
					});
				}
			} catch (e) {
				release();
			}
		});
	}

	onChangeUsername = (event) => {
		this.setState({
			username: event.target.value.replace(/((?![\d_a-zA-Z]).)/g, '').replace(/^[\d]+/g, ''),
		});
	};

	onChangeName = (event) => {
		this.setState({
			name: event.target.value,
		});
	};

	onChangeContactNumber = (event) => {
		this.setState({
			contact_number: event.target.value.replace(/((?![\d-]).)/g, ''),
		});
	};

	onChangePassword = (event) => {
		this.setState({
			password: event.target.value,
		});
	};

	onChangePasswordConfirm = (event) => {
		this.setState({
			password_confirm: event.target.value,
		});
	};

	onChangeCategory = (event) => {
		this.setState({category: event.target.value});
	};

	render() {
		return (
			<div id="basic-modal">
				<div className="modal info-modal">
					<div id="modal-close" onClick={this.onClickClose}><img src={close} width="16px;" alt="닫기" /></div>
					<h1>안전 담당자 {this.isModifying ? '수정' : '등록'}</h1>

					<div className="modal-in">
						<ul>
							{this.isModifying &&
							<li>
								<div>고유번호</div>
								<input type="text" id="ad-name" placeholder="계정을 입력해주세요" disabled value={this.props.agent.user_uuid}/>
							</li>}

							<li>
								<div>아이디</div>
								<input type="text" id="ad-name" placeholder="계정을 입력해주세요" value={this.state.username} onChange={this.onChangeUsername} maxLength={PASSWORD_MAX_LENGTH} disabled={this.isModifying}/>
							</li>
							<li>
								<div>이름</div>
								<input type="text" id="ad-name" placeholder="이름을 입력해주세요" value={this.state.name} onChange={this.onChangeName} maxLength={PASSWORD_MAX_LENGTH}/>
							</li>
							<li>
								<div>연락처</div>
								<input type="text" id="ad-phone" placeholder="연락처를 입력해주세요" value={this.state.contact_number} onChange={this.onChangeContactNumber} maxLength={PASSWORD_MAX_LENGTH}/>
							</li>

							<li>
								<div>비밀번호</div>
								<input
									type={this.isModifying ? 'password' : 'text'}
									id="ad-PW"
									placeholder="비밀번호를 입력해주세요"
									value={this.state.password}
									onChange={this.onChangePassword}
									maxLength={PASSWORD_MAX_LENGTH}/>
							</li>

							{this.isModifying &&
							<li>
								<div>비밀번호 확인</div>
								<input
									type={this.isModifying ? 'password' : 'text'}
									id="ad-PW"
									placeholder="비밀번호를 다시 입력해주세요"
									value={this.state.password_confirm}
									onChange={this.onChangePasswordConfirm}
									maxLength={PASSWORD_MAX_LENGTH}/>
							</li>}

							<li>
								<div>분야</div>
								<div className="radio">
									<input type="radio" id="r1" name="category" onChange={this.onChangeCategory} value={CATEGORY_MANUFACTURE} checked={this.state.category === CATEGORY_MANUFACTURE}/>
									<label htmlFor="r1">제조</label>
								</div>

								<div className="radio">
									<input type="radio" id="r2" name="category" onChange={this.onChangeCategory} value={CATEGORY_CONSTRUCTION} checked={this.state.category === CATEGORY_CONSTRUCTION}/>
									<label htmlFor="r2">건설</label>
								</div>
							</li>
						</ul>
					</div>

					<div className="btn-wrap">
						<button id="modal-btn-cancel" className="modal-btn btn-cancel" onClick={this.onClickClose}>취소</button>
						<button id="modal-btn-ok" className="modal-btn btn-edit" onClick={this.onClickSubmit}>{this.isModifying ? '수정' : '확인'}</button>
					</div>
				</div>
			</div>
		)
	}
}

Modal.propTypes = {
	onClose: PropTypes.func,
}

export default Modal;
