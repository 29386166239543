import React from 'react';
import close from "../../resources/admin/icon/close.svg";
import moment from "moment";
import {inject} from "mobx-react";
import {api} from "../../api/api";
import {MIN_LENGTH} from "../../constants/password";

class ProfileEditModal extends React.Component {

  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      password: '',
      new_password: '',
      new_password_confirm: '',
    };
  }

  show = () => {
    this.setState({visible: true});
  };

  close = () => {
    this.setState({visible: false});
  };

  submit = () => {
    if (this.isLockedSave) return;
    this.isLockedSave = true;

    const {password, new_password, new_password_confirm} = this.state;
    const change_password = password || new_password || new_password_confirm;

    if (change_password) {
      if (!password) {
        this.isLockedSave = false;
        return alert('현재 비밀번호를 입력해주세요');
      }
      if (!new_password) {
        this.isLockedSave = false;
        return alert('새로운 비밀번호를 입력해주세요');
      }
      if (!new_password_confirm) {
        this.isLockedSave = false;
        return alert('새로운 비밀번호를 다시 입력해주세요');
      }

      if (new_password.length < MIN_LENGTH) {
        return alert('')
      }

      // 새 비밀번호 & 새 비밀번호 확인이 동일한지 확인
      if (new_password !== new_password_confirm) {
        this.isLockedSave = false;
        return alert('동일한 새로운 비밀번호를 한번더 입력해주세요');
      }
    }

    api.put('/v1/auth/password', {password, new_password}).then((r) => {
      if (!r.ok) {
        if (r.status === 401) {
          alert('현재 비밀번호와 일치하지 않습니다.');
        } else {
          alert('비밀번호 변경실패');
        }
        this.isLockedSave = false;
        return;
      }

      alert('비밀번호가 변경되었습니다.');
      this.isLockedSave = false;
      this.close();
    }).catch(e => {
      console.error(e);
      alert('비밀번호 변경오류');
    });
  };

  render() {
    if (!this.state.visible) return null;

    const user = this.props.sessionStore?.user;
    return (
      <div id="basic-modal" style={{zIndex: 10}}>
        <div className="modal info-modal">
          <div id="modal-close" onClick={this.close}><img src={close} width="16px;" alt="닫기" /></div>
          <h1>회원 정보 변경</h1>
          <div className="modal-in">
            <ul>
              <li>
                <div>고유번호</div>
                <input type="text" id="ad-ID" placeholder="기본 정보 노출/수정불가" disabled value={user?.uuid}/>
              </li>
              <li>
                <div>아이디</div>
                <input type="text" id="ad-name" placeholder="기본 정보 노출/수정불가" disabled value={user?.username}/>
              </li>
              <li>
                <div>생성일</div>
                <input type="text" id="ad-name" placeholder="기본 정보 노출/수정불가" disabled value={user?.created_at ? moment.unix(user?.created_at).format('YYYY-MM-DD hh:mm:ss') : '-'}/>
              </li>
              <li>
                <div>연락처</div>
                <input type="password" id="ad-phone" placeholder="-" disabled value={user?.agent?.contact_number}/>
              </li>

              <li>
                <div>현재 비밀번호</div>
                <input
                  type="password"
                  id="ad-PW"
                  placeholder="현재 비밀번호를 입력해주세요"
                  onChange={e => this.setState({password: e.currentTarget.value})}/>
              </li>

              {this.state.password &&
              <li>
                <div>새로운 비밀번호</div>
                <input
                  type="password"
                  id="ad-PW"
                  placeholder="변경하실 비밀번호를 입력해주세요"
                  onChange={e => this.setState({new_password: e.currentTarget.value})}/>
              </li>}

              {this.state.new_password &&
              <li>
                <div>새로운 비밀번호 확인</div>
                <input
                  type="password"
                  id="ad-PW-check"
                  placeholder="비밀번호를 한번 더 입력해주세요"
                  onChange={e => this.setState({new_password_confirm: e.currentTarget.value})}/>
              </li>}

              <li>
                <div>유형</div>
                <input type="text" id="ad-rank" disabled value={user?.typeAlias}/>
              </li>
            </ul>
          </div>

          <div className="btn-wrap">
            <button id="modal-btn-cancel" className="modal-btn btn-cancel" onClick={this.close}>취소</button>
            <button id="modal-btn-ok" className="modal-btn btn-edit" onClick={this.submit}>수정</button>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('sessionStore')(ProfileEditModal);