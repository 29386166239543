import React from 'react';
import PropTypes from "prop-types";
import StringUtils from "../../../../../utils/StringUtils";
import {DateUtils} from "../../../../../utils/DateUtils";
import moment from "moment";
import {UserTicket} from "../../../../../models/UserTicket";
import {api} from "../../../../../api/api";

class TicketListItemComponent extends React.Component {

  static propTypes = {
    userTicket: PropTypes.instanceOf(UserTicket).isRequired,
  };

  onClickExpire = () => {
    const userTicket: UserTicket = this.props.userTicket;

    if (userTicket?.isExpired) return alert('이미 만료된 회원권입니다.');
    if (userTicket?.is_basic) return alert('기본 회원권은 만료 처리할 수 없습니다.');

    if (!window.confirm(`해당 사용자의 ${userTicket?.ticket?.name}(ID: ${userTicket?.id}, 생성일 ${moment.unix(userTicket?.created_at).format('YYYY-MM-DD')})을 만료처리 하시겠습니까?`)) return;

    api.put(`/v1/admin/user/${userTicket?.user_uuid}/ticket/${userTicket?.uuid}/expire`).then((res) => {
      if (!res.ok) {
        console.warn(res);
        alert('처리실패');
        return;
      }

      window.location.reload();
    }).catch((e) => {
      alert('처리실패(ERROR)');
    })
  };

  render() {
    const userTicket: UserTicket = this.props.userTicket;

    const commonStyle = {};
    const expireButtonClasses = ['edit', 'center'];
    if (userTicket?.isExpired) {
      Object.assign(commonStyle, { color: 'red', textDecorationLine: 'line-through' })
      expireButtonClasses.push('disabled');
    } else if (userTicket?.is_basic) {
      expireButtonClasses.push('disabled');
    } else {
      expireButtonClasses.push('activate');
    }

    let billingText;
    const billingTextClasses = [];
    if (userTicket?.next_purchase_at) {
      billingText = 'Y';
    } else {
      billingText = 'N';
      billingTextClasses.push('red');
    }

    return (
      <tr>
        <td style={commonStyle}>{userTicket?.id}</td>
        <td style={commonStyle}>{userTicket?.ticket?.name}</td>
        <td style={commonStyle}>{(!isNaN(userTicket?.ticket?.price) && !userTicket?.is_basic) ? `${StringUtils.numberWithCommas(userTicket?.ticket?.price)}원` : '-'}</td>
        <td style={commonStyle}>{userTicket?.created_at ? moment.unix(userTicket?.created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
        <td style={commonStyle}>{userTicket?.expired_at ? moment.unix(userTicket?.expired_at).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
        <td className={"center"}><span className={billingTextClasses.join(' ')}>{billingText}</span></td>
        <td className={expireButtonClasses.join(' ')}><button onClick={this.onClickExpire}>만료하기</button></td>
      </tr>
    )
  }
}

export default TicketListItemComponent;