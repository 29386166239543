import React from "react";
import PropTypes from "prop-types";
import {Question} from "../../../models/Question";
import {Link} from "react-router-dom";
import moment from "moment";
import {observer} from "mobx-react";

class QuestionListItemComponent extends React.Component {
	static propTypes = {
		question: PropTypes.instanceOf(Question).isRequired
	};

	componentDidMount() {
		this.fetchAnswerCount();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		this.fetchAnswerCount();
	}

	fetchAnswerCount = () => {
		if (!this.props.question?.answer_count && isNaN(this.props.question?.answer_count)) {
			this.props.question.fetchAnswerCount().then(() => {});
		}
	};

	render() {
		const question = this.props.question;
		// console.log('QuestionListItemComponent:render()', question.uuid, question.answer_count);

		let status = question.statusAlias;
		if (question.answer_count > 0) {
			status += `(${question.answer_count})`;
		}

		const companyLink = (child) => <Link to={`/admin/company/${question.user.company.user_uuid}`}>{child}</Link>;
		const questionLink = (child, style) => <Link style={style} to={`/admin/question/${question.uuid}`}>{child}</Link>;
		return (
			<tr>
				<td>{companyLink(question?.user?.company?.name)}</td>
				<td><div className='long-txt'>{questionLink(question?.text)}</div></td>
				<td>{questionLink(question?.created_at ? moment.unix(question.created_at).format('YYYY-MM-DD') : '')}</td>
				<td>{questionLink(status, {color: question.statusColor})}</td>
				<td className="edit write">{questionLink(<button>작성</button>)}</td>
			</tr>
		)
	}
}

export default observer(QuestionListItemComponent);