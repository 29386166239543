import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

import {Editor} from "react-draft-wysiwyg";
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

/** CSS */
import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';
import {inject} from "mobx-react";
import {api} from "../../../../api/api";
import {Community} from "../../../../models/Community";
import {Link} from "react-router-dom";

/** CSS */

class Screen extends React.Component {

	state = {
		title: '',
		content: '',
		files: [],
		editorState: EditorState.createEmpty(),
	};

	onFilesChange = (e) => {
		console.log('e.target.files', e.target.files);
		this.setState({ files: e.target.files });
	};

	onClickSubmit = () => this.submit();

	submit = () => {
		if (this.isLockedSubmit) return;
		this.isLockedSubmit = true;

		const release = (state, callback) => {
			this.setState({...state}, () => {
				this.isLockedSubmit = false;
				typeof callback === 'function' && callback();
			});
		};

		const {title, files} = this.state;
		const content = stateToHTML(this.state.editorState.getCurrentContent());
		console.log('content', content);

		if (!title || !title.trim()) return release({}, () => alert('제목을 입력해주세요'));
		if (!content || !content.trim()) return release({}, () => alert('내용을 입력해주세요'));

		const formData = new FormData();

		formData.append('title', title);
		formData.append('content', content);

		files && files.forEach((file: File, index) => {
			formData.append('attachments[]', file, file.name);
		});

		api.post('/v1/admin/community', formData, {timeout: 300000}).then((r) => {
			if (!r.ok) return release({}, () => alert('작성 실패'));
			return release({}, () => {
				alert('작성 완료');
				const community: Community = r?.data?.hasOwnProperty('notice') ? new Community(r.data.notice) : null;
				this.props.history.push(community ? `/admin/community/${community.uuid}` : '/admin/community');
			});
		}).catch(e => {
			console.error(e);
			return release({}, () => alert('작성 실패'));
		});
	}

	onEditorStateChange = (editorState) => {
		this.setState({editorState});
	};

	render() {
		const { editorState } = this.state;

		return (
			<ContainerComponent>
				<div>
					<div id="ad-contents" className="write">
						<h1 className="contents-title">안전하는사람들(사랑방)</h1>
						<div className="notice-container">
							<div>
								<span>제목</span>
								<input type="text" id="ad-ID" placeholder="제목을 입력하세요" value={this.state.title} onChange={e => this.setState({title: e.currentTarget.value})}/>
							</div>

							{/*<span className="line"></span>*/}

							{/*<div>*/}
							{/*	<span>첨부파일</span>*/}
							{/*	<input type='file' multiple onChange={this.onFilesChange} maxLength={10} style={{paddingTop:"14px"}}/>*/}
							{/*</div>*/}
						</div>

						<span className="line"></span>

						<Editor
							style={{height: 500}}
							editorState={editorState}
							wrapperClassName="demo-wrapper"
							editorClassName="demo-editor"
							onEditorStateChange={this.onEditorStateChange}
							toolbar={{
								options: ['inline', 'list', 'link', 'history'],
								inline: {options: ['bold', 'italic', 'underline', 'strikethrough']},
								list: {options: ['unordered', 'ordered']},
								link: {options: ['link']}
							}}
						/>

						<div className="btn-wrap">
							<Link to={'/admin/community'}><button id="modal-btn-cancel" className="board-btn btn-cancel">취소</button></Link>
							<button id="modal-btn-ok" className="board-btn btn-edit" onClick={this.onClickSubmit}>완료</button>
						</div>
					</div>
				</div>
			</ContainerComponent>
		);
	}
}

export default inject('sessionStore')(Screen);