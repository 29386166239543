import React from 'react';
import {Link} from "react-router-dom";

/** img */
import sorry from "../resources/homepage/sorry.svg";
/** img */

/** CSS */
import '../css/common.css';
import '../css/contents.css';
/** CSS */

class Screen extends React.PureComponent {

	render() {
		return (
				
			<div className="notfound">
				<h1>404 <span><img src={sorry} width="100%" alt="슬픈 표정아이콘" /></span></h1>
				<p>죄송합니다. 현재 찾을 수 없는 페이지를 요청 하셨습니다.</p>
				<small>
					존재하지 않는 주소를 입력하셨거나, 요청하신 페이지의 주소가 변경, 삭제되어 찾을 수 없습니다.<br />
					궁금한 점이 있으시면 언제든 고객센터를 통해 문의해 주시기 바랍니다.<br />
					감사합니다.
				</small>
				<Link to={'/'} >메인으로 가기</Link>
			</div>

		)
	}
}

export default Screen;
