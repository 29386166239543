import {Community} from "./Community";

export class CommunityAttachment {
  uuid: string;
  id: number;

  created_at: string;

  post_uuid: string;

  name: string;
  url: string;
  mime: string;

  // JOIN
  community: Community;

  constructor(attachment: CommunityAttachment) {
    Object.assign(this, attachment);
  }
}