import React from 'react';
import ContainerComponent from "../../../../components/admin/common/ContainerComponent";

import '../../../../css/common.css';
import '../../../../css/admin/form.css';
import '../../../../css/admin/dashboard.css';
import {Link} from "react-router-dom";
import {Notice} from "../../../../models/Notice";
import moment from "moment";

class Screen extends React.PureComponent {

	state = {
		query: '',
		notices: [],
		count: 0,
	};

	componentDidMount() {
		this.fetchNotices();
		this.fetchCount();
	}

	fetchCount = () => {
		Notice.fetchCount().then((count) => {
			this.setState({ count });
		});
	};

	fetchNotices = () => {
		Notice.fetchNotices({query: this.state.query, ob: 'id', od: 'DESC'}).then((notices) => {
			console.log(notices);
			this.setState({notices});
		});
	};

	onChangeQuery = (e) => {
		this.setState({query: e.currentTarget.value}, () => {
			this.fetchNotices();
		});
	};

	renderNotices = () => this.state.notices.map((notice: Notice) => {
		const noticeLink = (child) => <Link to={`/admin/notice/${notice?.uuid}`}>{child}</Link>;

		return (
			<tr key={noticeLink(notice.uuid)}>
				<td>{noticeLink(notice?.title)}</td>
				{/*<td className="notice-content"><div className="long-txt">{noticeLink(parse(notice?.content))}</div></td>*/}
				<td>{noticeLink(notice?.created_at && moment.unix(notice.created_at).format('YYYY-MM-DD'))}</td>
				<td className="notice-go edit"><a href={`/notice/${notice.uuid}`} target={'_blank'}><button>보기</button></a></td>
			</tr>
		);
	})

	render() {
		return (
			<ContainerComponent>
				<div>
					<div id="ad-contents">
						<h1 className="contents-title">공지사항</h1>
						<div className="contents-in">
							<div className="schfrm">
								<div className="left">총 <strong>{this.state.count}</strong>건</div>
								<div className="right">
									<div className="search-box">
										<input type="text" id="ad-search" placeholder="검색어를 입력해주세요" className="search" value={this.state.query} onChange={this.onChangeQuery}/>
										<div className="search-btn"></div>
									</div>
									<Link to={'/admin/notice/write'}><button className="enroll">글쓰기</button></Link>
								</div>
							</div>
							<table className="basic-table" width="100%" border="0" cellSpacing="0" cellPadding="0">
								<colgroup>
									<col width="76%;"/>
									<col width="12%;"/>
									<col width="12%;"/>
								</colgroup>
								<thead>
								<tr>
									<th scope="col">제목</th>
									<th scope="col">작성일</th>
									<th scope="col" className="editTit">설정</th>
								</tr>
								</thead>

								{/* 게시물이 없는 경우 */}
								{this.state.notices.length > 0 ?
									<tbody>
									{this.renderNotices()}
									</tbody>
									:
									<tbody className="no-contents">
									<tr>
										<td colSpan="6">등록된 글이 없습니다</td>
									</tr>
									</tbody>
								}
							</table>
						</div>
					</div>

				</div>
			</ContainerComponent>
		);
	}
}

export default Screen;
