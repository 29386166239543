import React from 'react';
import HeaderComponent from "../../components/common/HeaderComponent";
import FooterComponent from "../../components/common/FooterComponent";
import Fade from "react-reveal/Fade";


/** img */
import {Link} from "react-router-dom";
import home from "../../resources/homepage/Home.svg";
/** img */
/** CSS */
import '../../css/common.css';
import '../../css/pc-common.css';
import '../../css/contents.css';

/** CSS */

export const TabKey = {
	Safety: 'safety',
	Disaster: 'disaster',
};

export default class Screen extends React.PureComponent {

	state = {
		tab_key: null,
		routes: {},
	};

	constructor(props) {
		super(props);

		this.routes = {
			[TabKey.Safety]: { title: '안전관리 전문기관', render: this.renderTab1 },
			[TabKey.Disaster]: { title: '건설재해예방 전문기관', render: this.renderTab2 },
		};
	}

	getTabKey = (fallback = TabKey.Safety) => {
		const search = this.props.location.search;
		const params = new URLSearchParams(search);
		const tab_key = params.get('tab');
		return ([TabKey.Safety, TabKey.Disaster].includes(tab_key)) ? tab_key : fallback;
	};

	onClickTab = (key) => {
		this.setState({tab_key: key}, () => {
			this.props.history.push({
				pathname: '/service',
				search: `?tab=${key}`,
			});
		});
	};

	renderTab1 = () => (
		<div className="sv-wrap">
			<div className="sv-list">
			<Link to={'/service/75674628-fcfd-4385-8e68-e08745359215'}>안전관리대행수탁</Link>
			<div className="sub-txt">
				안전관리자의 업무를 안전관리전문기관에 위탁(건설업 제외) 가능
			</div>
			<div className="btn-area">
				<Link to={'/service/75674628-fcfd-4385-8e68-e08745359215'}>
					자세히보기
				</Link>
			</div>
			</div>
			<div className="sv-list">
				<Link to={'/service/8ab7a5d8-7c62-4567-b33d-04ffa630dcb6'}>위험성평가</Link>
				<div className="sub-txt">
					위험성의 크기가 허용 가능한 범위인지 평가
				</div>
				<div className="btn-area">
					<Link to={'/service/8ab7a5d8-7c62-4567-b33d-04ffa630dcb6'}>
						자세히보기
					</Link>
				</div>
			</div>
			<div className="sv-list">
				<Link to={'/service/291fc464-38a9-4dce-9e41-d5917ad15eb6'}>근골격계부담작업 유해요인조사</Link>
				<div className="sub-txt">
					건강장해를 예방하기 위한 근골격계 유해요인조사 실시
				</div>
				<div className="btn-area">
					<Link to={'/service/291fc464-38a9-4dce-9e41-d5917ad15eb6'}>
						자세히보기
					</Link>
				</div>
			</div>
			<div className="sv-list">
				<Link to={'/service/0de13afb-c7e0-4098-ad02-c380f1129726'}>제조업 유해·위험방지 계획서</Link>
				<div className="sub-txt">
					주요 구조부분을 변경하는 경우 제조업 유해·위험방지 계획서 작성
				</div>
				<div className="btn-area">
					<Link to={'/service/0de13afb-c7e0-4098-ad02-c380f1129726'}>
						자세히보기
					</Link>
				</div>
			</div>
			<div className="sv-list ready">
				<Link to={'/service/dcde013c-42be-4734-ac8e-9ae01e3a58ac'} style={{cursor:'default'}}>중대재해컨설팅</Link>
				<div className="sub-txt">
					준비중입니다
				</div>
				{/* <div className="btn-area">
					<Link to={'/service/dcde013c-42be-4734-ac8e-9ae01e3a58ac'}>
						자세히보기
					</Link>
				</div> */}
			</div>
		</div>
	);

	renderTab2 = () => (
		<div className="sv-wrap">
			<div className="sv-list">
				<Link to={'/service/f6086a8f-0ef4-4968-a32e-9faa4c9e5266'}>건설업 자율안전보건 컨설팅</Link>
				<div className="sub-txt">
					외부 전문가를 활용한 안전점검 등 자체적으로 안전관리 실시
				</div>
				<div className="btn-area">
					<Link to={'/service/f6086a8f-0ef4-4968-a32e-9faa4c9e5266'}>
						자세히보기
					</Link>
				</div>
			</div>
			<div className="sv-list">
				<Link to={'/service/d96470a6-1bfa-4041-8e2f-e567cdcb3ca4'}>건설업 유해위험방지계획서 작성 컨설팅</Link>
				<div className="sub-txt">
					사전 안전성 심사를 통해 근원적인 안전성 확보 및 근로자의 안전·보건 확보
				</div>
				<div className="btn-area">
					<Link to={'/service/d96470a6-1bfa-4041-8e2f-e567cdcb3ca4'}>
						자세히보기
					</Link>
				</div>
			</div>
			<div className="sv-list">
				<Link to={'/service/ff9b8080-12f7-4762-a740-46bffe288011'}>안전관리계획서</Link>
				<div className="sub-txt">
					토목 및 건축을 포함한 건설공사의 시공시 체계적이고 효율적인 건설안전관리 정착
				</div>
				<div className="btn-area">
					<Link to={'/service/ff9b8080-12f7-4762-a740-46bffe288011'}>
						자세히보기
					</Link>
				</div>
			</div>
			<div className="sv-list">
				<Link to={'/service/051bb2b2-c531-450c-a7fb-fd0a03d793dd'}>건설재해예방 기술지도</Link>
				<div className="sub-txt">
					공사를 하는 자를 위한 산업재해 예방을 위한 [재해예방 전문지도기관]의 지도
				</div>
				<div className="btn-area">
					<Link to={'/service/051bb2b2-c531-450c-a7fb-fd0a03d793dd'}>
						자세히보기
					</Link>
				</div>
			</div>
			<div className="sv-list ready">
				<Link to={'/service/47c1000e-0856-4647-83f2-1609f1f3f5fe'} style={{cursor:'default'}}>중대재해컨설팅</Link>
				<div className="sub-txt">
					준비중입니다
				</div>
				{/* <div className="btn-area">
					<Link to={'/service/47c1000e-0856-4647-83f2-1609f1f3f5fe'}>
						자세히보기
					</Link>
				</div> */}
			</div>
		</div>
	);

	renderTab = () => {
		const tab_key = this.getTabKey();

		const route = this.routes[tab_key];
		return route.render();
	}

	renderTabButton = (title, key) => (
		<li className={this.getTabKey() === key && 'on'} onClick={() => this.onClickTab(key)}>{title}</li>
	);

	renderNavigationButton = () => {
		switch (this.getTabKey()) {
			case TabKey.Safety:
				return <strong>안전관리 전문기관</strong>
			case TabKey.Disaster:
				return <strong>건설재해예방 전문기관</strong>
		}
		return null;
	};

	render() {
		return (
			<div id="wrap">

					{/* header */}
					<HeaderComponent/>


					<div id="top-menu">
						<div className="section-in history-in pc-long">
							<Fade bottom><h1 className="top-title">믿고 맡길 수 있는<br /><strong>안전관리 전문가 구성</strong></h1></Fade>
							<div className="c-txt">
								<p>
									스마트안전은 고용노동부 출신 다수와 산업안전 전문분야 고(高)경력 특급 기술자 등으로 구성되어
									안전관리업무위탁, 안전지도컨설팅, 건설재해예방기술지도, 유해위험방지계획서 및 위험성평가 컨설팅 업무를 종합적으로 수행하는 고용노동부 지정 안전관리전문기관(주식회사 부산안전원)에 소속 된 안전전문가가 직접 관리 합니다.
								</p>
							</div>
							<div className="clear"></div>
							<div className="location">
								<Link to={'/'}><img src={home} width="20px" alt="홈"/></Link>
								<strong>제공서비스</strong>
								{this.renderNavigationButton()}
							</div>
						</div>
					</div>


					<div id="sub-container" className="other">
						<div className="section-in history-in">

							<ul className="sv-tabs tabs">
								{this.renderTabButton('안전관리 전문기관', TabKey.Safety)}
								{this.renderTabButton('건설재해예방 전문기관', TabKey.Disaster)}
							</ul>

							{this.renderTab()}

						</div>

					</div>

					{/* footer */}
					<FooterComponent/>

				</div>
		)
	}
}