import React from 'react';
import {api} from "../../../api/api";
import PropTypes from "prop-types";
import {Question} from "../../../models/Question";

export class AnswerComponent extends React.Component {

	static propTypes = {
		question: PropTypes.instanceOf(Question).isRequired,
		onPublished: PropTypes.func,
	};

	state = {
		text: '',
	};

	onChangeText = (e) => {
		this.setState({text: e.currentTarget.value});
	};

	onClickSubmit = () => {
		if (this.isLockedSubmit) return;
		this.isLockedSubmit = true;

		const release = (state, callback) => {
			this.setState({...state}, () => {
				this.isLockedSubmit = false;
				typeof callback === 'function' && callback();
			});
		};

		const {text} = this.state;
		if (!text || !text.trim()) return release({}, () => alert('내용을 입력해주세요'));

		api.post(`/v1/admin/question/${this.props.question.uuid}/answer`, {text}).then((r) => {
			if (!r.ok) return release({}, () => alert('답변작성 실패'));

			release({text: ''}, () => {
				alert('등록 완료');
				typeof this.props.onPublished === 'function' && this.props.onPublished();
			});
		}).catch(() => {
			release({}, () => alert('오류발생'));
		});
	};

	render() {
		return (
			<div className="qna-comment">
				<strong>답변작성</strong>
				<div className="write-comment">
					<textarea className="textbox" placeholder="내용을 입력해주세요" value={this.state.text} onChange={this.onChangeText}/>
					<button onClick={this.onClickSubmit}>등록</button>
				</div>
			</div>
		);
	}
}